import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";

@Component({
  selector: "fuse-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FuseNavigationComponent implements OnInit {
  @Input()
    layout = "vertical";

  @Input()
    navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
     * Constructor
     */
  constructor(
    private _fuseNavigationService: FuseNavigationService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
     * On init
     */
  ngOnInit(): void {
    // Load the navigation either from the input or from the service
    this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

    // Subscribe to the current navigation changes
    this._fuseNavigationService.onNavigationChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
  }
}
