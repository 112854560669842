import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DecimalPlacesDirective } from "./decimal-places.directive";
import { TndCurrencyPipe } from "./tnd-currency.pipe";
import { LimitedAccessDirective } from "./limited-access.directive";
import { SyncControlDirective } from "./sync-control.directive";
import { AccessControlDirective } from "./access-control.directive";
import { RoleAccessControlDirective } from "./role-access-control.directive";



@NgModule({
  declarations: [
    DecimalPlacesDirective,
    TndCurrencyPipe,
    LimitedAccessDirective,
    SyncControlDirective,
    AccessControlDirective,
    RoleAccessControlDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    DecimalPlacesDirective,
    LimitedAccessDirective,
    TndCurrencyPipe,
    SyncControlDirective,
    AccessControlDirective,
    RoleAccessControlDirective,
  ],
})
export class DirectivesModule { }
