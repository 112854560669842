import { Injectable, Injector } from "@angular/core";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(private injector: Injector) {}

  initializeApp(): Promise<any> {
    return new Promise((resolve) => {
      this.injector
        .get(AuthentificationService)
        .getUser()
        .subscribe((user) => {
          this.injector.get(GlobalService).setCurrentUser(user);
          {
            this.injector
              .get(GlobalService)
              .currentUser.toPromise()
              .then(() => {
                resolve(1);
              });
          }
        });
    });
  }
}
