import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PopupDialogQueueService {
  public queue: { component: any; data?: any; afterClose?: (result: any) => void; onError?: (error: any) => void }[] = [];
  private isDialogOpen = false;
  // to stop the queue from processing (opening the next dialogs)
  private pauseQueue = false;
  private dialogStateSubjects: { [key: string]: Subject<boolean> } = {}; // Store subjects for each dialog

  constructor(private dialog: MatDialog) {
  }

  // Subscribe to a specific dialog by ID
  subscribeToDialogState(id: string) {
    if (!this.dialogStateSubjects[id]) {
      this.dialogStateSubjects[id] = new Subject<boolean>(); // Create a subject for this dialog if it doesn't exist
    }
    return this.dialogStateSubjects[id].asObservable(); // Return the observable for the dialog state
  }

  // Update the state of a specific dialog by ID
  // tell the listeners if the dialog is open or closed
  setDialogState(id: string, isOpen: boolean) {
    if (!this.dialogStateSubjects[id]) {
      this.dialogStateSubjects[id] = new Subject<boolean>(); // Create a subject if it doesn't exist
    }
    this.dialogStateSubjects[id].next(isOpen); // Emit the new state
  }

  // Pause the queue
  setQueueState(state: boolean) {
    this.pauseQueue = state;
    if(!state) {
      this.processQueue(); // Process the queue if it's no longer paused
    }
  }

  unshiftDialog(component: any, data?: any, afterClose?: (result: any) => void, onError?: (error: any) => void) {
    this.queue.unshift({ component, data, afterClose, onError });
    this.processQueue();  // Process the dialog immediately
  }
  
  openDialog(component: any, data?: any, afterClose?: (result: any) => void, onError?: (error: any) => void) {
    this.queue.push({ component, data, afterClose, onError });
    this.processQueue();
  }
  
  private processQueue() {
    if (this.isDialogOpen || this.queue.length === 0 || this.pauseQueue) {
      return; // Prevent multiple dialogs from opening simultaneously
    }
    
    this.isDialogOpen = true;
    
    const { component, data, afterClose, onError } = this.queue.shift()!;

    // Emit that the dialog is open
    if (this.dialogStateSubjects[component.name]) {
      this.dialogStateSubjects[component.name].next(true);
    }

    const dialogRef = this.dialog.open(component, data)
    
      dialogRef.afterClosed().pipe(take(1)).subscribe({
        next: (result) => {
          // Success case
          this.isDialogOpen = false; // Mark dialog as closed
          if (this.dialogStateSubjects[component.name]) {
            this.dialogStateSubjects[component.name].next(false); // Emit that the dialog is closed
          }

          setTimeout(() => {
            if (afterClose) {
              afterClose(result); // Handle success result
            }
            this.processQueue(); // Continue with the next dialog in the queue
          });
        },
        error: (err): void => {
          // Error case
          this.isDialogOpen = false; // Mark dialog as closed
          if (this.dialogStateSubjects[component.name]) {
            this.dialogStateSubjects[component.name].next(false); // Emit that the dialog is closed
          }
          setTimeout(() => {
            if (onError) {
              onError(err); // Handle error
            }
            this.processQueue(); // Continue with the next dialog even if there was an error
          });
        }
    });
  }
}
