<div class="container">
    

    <top-nav-small></top-nav-small>

    <!-- Scrollable Content -->
    <div class="content">
        <ng-content class="content" select="[page-content]"></ng-content>
    </div>
    
    <!-- Bottom Navigation -->
    <mat-toolbar class="bottom-nav">
      <ng-container *ngIf="(navigation$ | async) as navigation">
        <ng-container *ngFor="let item of navigation; let i = index">
          <div fxLayout="column" fxLayoutGap="4" fxLayoutAlign="center center">
            <div class="icon-container" [ngClass]="{'active': item.title === 'Accueil'}">
              <mat-icon>{{ item.icon }}</mat-icon>
            </div>
            <span class="label-medium">{{ item.title }}</span>
          </div>

          <!-- Insert the button after the second iteration -->
          <ng-container *ngIf="i === 1">
            <button mat-fab class="add-btn">
              <mat-icon>add</mat-icon>
            </button>
          </ng-container>
        
        
        </ng-container>
      </ng-container>
      
    </mat-toolbar>
</div>