import { PrintService } from "./print.service";
import { GlobalService } from "app/global.service";
import { Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { FuseConfigService } from "@fuse/services/config.service";
import { navigation } from "app/navigation/navigation";
import { trigger, transition, style, animate } from "@angular/animations";
import { TopNotificationService } from "app/layout/components/top-notification/top-notification.service";
import { 
  FusePerfectScrollbarDirective,
} from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { LayoutService } from "app/layout/layout.service";
import { Router } from "@angular/router";
import { PopupDialogQueueService } from "app/popup-dialog-queue.service";
import { ViewportService } from "app/core/services/viewport.service";

@Component({
  selector: "vertical-layout-1",
  templateUrl: "./layout-1.component.html",
  styleUrls: ["./layout-1.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateY(-100%)" }),
        animate("300ms ease-in", style({ transform: "translateY(0%)" })),
      ]),
      transition(":leave", [
        animate("300ms ease-in", style({ transform: "translateY(-100%)" })),
      ]),
    ]),
  ],
  standalone: false,
})
export class VerticalLayout1Component implements OnInit, OnDestroy {

  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;

  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

  }

  innerWidth: number;

  fuseConfig: any;

  topNotConfig : any;

  navigation: any;

  notifactions: [];

  role: any;

  devicetype: any;

  printer: "par" | "aip" | "ppl" | "pp" | "p" = null;

  printerData;

  editingEvent = false;

  // Private
  private _unsubscribeAll: Subject<any>;


  /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
     
  constructor(
    private globalService: GlobalService,
    private _fuseConfigService: FuseConfigService,
    private _printService: PrintService,
    private _topNotService: TopNotificationService,
    private _layoutService: LayoutService,
    private _popupDialogService: PopupDialogQueueService,    
    private _viewPortService : ViewportService,
    private router:Router,
  ) {
    this.innerWidth = this._viewPortService.currentWidth;

    // Set the defaults
    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  setNotToDefault(): void {
    this._topNotService.setToDefault();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

    

  scrollTo(offset): void {
    this._fusePerfectScrollbar.scrollToY(offset, 500);
  }

  /**
     * On init
     */
  ngOnInit(): void {
    this.viewPortListener()
    this._popupDialogService.subscribeToDialogState("EventFormComponent")
      .pipe(
        takeUntil(this._unsubscribeAll),
      )
      .subscribe((isOpen) => {
        const isCalendar = this.router.url.indexOf("calendar") !== -1;
        if (!isCalendar) return;
        console.log("EV FLOW");
        if (isOpen) {
          this.editingEvent = true;
        } else {
          this.editingEvent = false;
        } 
      });

    this._topNotService.config.subscribe(config=>{
      this.topNotConfig = config;
    });


    this._layoutService.scrollTo.subscribe({
      next:(offset)=>this.scrollTo(offset),
    });

    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    this._printService._print
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.printer = "p";
        this.printerData = data;
        setTimeout(() => {
          window.print();
        }, 1000);
      });

    this._printService._printAgencyRecu
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.printer = "par";
        this.printerData = data;
        setTimeout(() => {
          window.print();
        }, 1000);
      });

    this._printService._printAgencyInvoice
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.printer = "aip";
        this.printerData = data;
        setTimeout(() => {
          window.print();
        }, 1000);
      });

    this._printService._printPaiement
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.printer = "pp";
        this.printerData = data;
        setTimeout(() => {
          window.print();
        }, 1000);
      });

    this._printService._PrintPlanning
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.printer = "ppl";
        this.printerData = data;
        setTimeout(() => {
          window.print();
        }, 1000);
      });


    this.devicetype = this.globalService.detectdevice();
    //console.log(this.devicetype)
  }

  logui(): void {
    //event.stopPropagation()
  }

  viewPortListener(){
    this._viewPortService.innerWidth$?.pipe(takeUntil(this._unsubscribeAll)).subscribe(innerWidth => {
      this.innerWidth = innerWidth;
    });
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}
