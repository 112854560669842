import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LocationService } from "./location.service";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";

@Component({
  selector: "app-set-location",
  templateUrl: "./set-location.component.html",
  styleUrls: ["./set-location.component.scss"],
  animations: [
    trigger("componentTransition", [
      state("step1", style({
        opacity: 1,
        transform: "translateX(0)",
      })),
      state("step2", style({
        opacity: 1,
        transform: "translateX(0)",
      })),
      transition("step1 => step2", [
        style({ opacity: 0, transform: "translateX(-100%)" }),
        animate("0.5s ease-in", style({ opacity: 1, transform: "translateX(0)" })),
      ]),
      transition("step2 => step1", [
        style({ opacity: 0, transform: "translateX(100%)" }),
        animate("0.5s ease-out", style({ opacity: 1, transform: "translateX(0)" })),
      ]),
    ]),
  ],
  standalone: false,
})
export class SetLocationComponent implements OnInit {
  @ViewChild(GoogleMap) googleMap!: GoogleMap;

  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  @ViewChild(MapMarker) mapMarker!: MapMarker;

  locationForm: FormGroup;

  isLoading = false;

  error: string = null;

  mapClickListener = null;

  errors = false;

  markerPosition: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

  //? Coordinates in Tunisia
  latitude = 36.8065;

  longitude = 10.1815;

  errorMessage;

  zoom = 7;

  disableSubmitButton = false;

  initialCenter: google.maps.LatLngLiteral = { lat: this.latitude, lng: this.longitude };

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: true,
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  currentState = 1;

  isXsScreen = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _matDialogRef: MatDialogRef<SetLocationComponent>,
    private fb: FormBuilder,
    private _locationService: LocationService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
      this.isXsScreen = result.matches;
    });
    this.markerPosition = { lat: this.latitude, lng: this.longitude };
    this.markerOptions = {
      ...this.markerOptions,
      position: this.markerPosition,
    };

    if (data?.location) {
      this.latitude = data.location.lat;
      this.longitude = data.location.lng;
      this.markerPosition = { lat: this.latitude, lng: this.longitude };
      this.initialCenter = { lat: this.latitude, lng: this.longitude };
      this.zoom = 12;
    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  // onSave(): void {
  //   if (this.locationForm.invalid) {
  //     return;
  //   }

  //   this.isLoading = true;
  //   this._locationService.saveAgencyLocation(this.locationForm.value).subscribe(
  //     (response) => {
  //       this.isLoading = false;
  //       this._matDialogRef.close(response.data); // Fermer le dialog et retourner les nouvelles données
  //     },
  //     (error) => {
  //       console.error("Error saving location:", error);
  //       this.error = "Une erreur est survenue lors de la sauvegarde de la localisation";
  //       this.isLoading = false;
  //     }
  //   );
  // }

  
  initForm(): void {
    const location = this.data?.location || {};
    
    this.locationForm = this.fb.group({
      address_fr: [location.address_fr || "", Validators.required],
      address_ar: [location.address_ar || "", Validators.required],
      lng: [location.lng || "", Validators.required],
      lat: [location.lat || "", Validators.required],
    });
    
    // If we have coordinates in the form, update the marker position
    if (location.lat && location.lng) {
      this.markerPosition = { lat: location.lat, lng: location.lng };
      this.markerOptions = {
        ...this.markerOptions,
        position: this.markerPosition,
      };
    }
  }

  goToNext(): void {
    if (this.currentState === 1) {
      this.currentState = 2;
    }
  }

  goToPrevious(): void {
    if (this.currentState === 2) {
      this.currentState = 1;
    }
  }

  mapReadyHandler(): void {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            this.getLocation();
          } else if (permissionStatus.state === "prompt") {
            // User hasn't made a decision yet, you can show a message or request permission
            this.getLocation();
          } else if (permissionStatus.state === "denied") {
            console.log("Location access denied");
          }
        });
    }
  }

  clickedMarker(): void {
    if (this.mapMarker) {
      this.infoWindow.open(this.mapMarker);
    }
  }

  markerDragEnd(event: google.maps.MapMouseEvent): void {
    if (event.latLng) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      this.locationForm.patchValue({
        lat: this.markerPosition.lat,
        lng: this.markerPosition.lng,
      });
    }
  }

  getPosition(): Promise<{ lng: number, lat: number }> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser."));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({ lng: position.coords.longitude, lat: position.coords.latitude });
          },
          (error) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                reject(
                  new Error(
                    `Veuillez activer les services de localisation dans les paramètres de votre navigateur et réessayer,
                    ou sélectionnez votre position manuellement.`,
                  ));
                break;
              case error.POSITION_UNAVAILABLE:
                reject(
                  new Error(
                    "Les informations de localisation sont indisponibles. Sélectionnez votre position manuellement.",
                  ));
                break;
              case error.TIMEOUT:
                reject(new Error("La demande de géolocalisation a expiré. Sélectionnez votre position manuellement."));
                break;
              default:
                reject(new Error("Une erreur inconnue s'est produite. Sélectionnez votre position manuellement."));
                break;
            }
          },
        );
      }
    });
  }

  getLocation(): void {
    this.getPosition().then(position => {
      this.latitude = position.lat;
      this.longitude = position.lng;
      this.markerPosition = {
        lat: position.lat,
        lng: position.lng,
      };
      this.markerOptions = {
        ...this.markerOptions,
        position: this.markerPosition,
      };
      this.initialCenter = { lat: position.lat, lng: position.lng };
      // Update form values
      this.locationForm.patchValue({
        lat: position.lat,
        lng: position.lng,
      });
      this.errors = false;
      this.zoom = 8;
    }).catch(err => {
      this.errors = true;
      this.errorMessage = err;
    });
  }

  onCancel(): void {
    this._matDialogRef.close();
  }

  onSubmit(): void {
    this.disableSubmitButton = true;
    this._locationService.setAgenceLocation(this.locationForm.value).subscribe({
      next: data => {
        this.disableSubmitButton = false;
        if (data.message === "success") {
          this._matDialogRef.close(true);
        }

        this._locationService.setCurrentLocation(this.locationForm.value);
      },
      error: (err) => {
        console.log(err);
        this.disableSubmitButton = false;
      },
    });
  }
}