import moment from "moment";


function roundToThree(num): any {
  return +(Math.round((Number(num + "e+3"))) + "e-3");
}


function getTotalNetHt(invoice): any {
  return roundToThree(invoice.total_ht - invoice.total_discount - invoice.total_asgcredit_used);
}

function getTVA(invoice): any {
  return roundToThree(getTotalNetHt(invoice) * 0.19);
}

function getTimbre(invoice): any {
  if (!invoice.finalized_at) return 0;
  if (getTotalNetHt(invoice) > 0) return roundToThree(invoice.stamp_fee);
  else return 0;
}

function getTotalTTC(invoice): any {
  return roundToThree(getTimbre(invoice) + getTVA(invoice) + getTotalNetHt(invoice));
}

function getRest(invoice): any {
  return roundToThree(getTotalTTC(invoice) - invoice.total_paid);
}

function momentToPostgresTime(time): any {
  return moment(time).format("YYYY-MM-DD HH:mm:ssZ");
}

function momentToPostgresTimeAtEight(time): any {
  return moment(time).add(7, "hour").format("YYYY-MM-DD HH:mm:ssZ");
}

export {
  getRest,
  getTotalTTC,
  getTotalNetHt,
  roundToThree,
  getTimbre,
  momentToPostgresTimeAtEight,
  momentToPostgresTime,
  getTVA };