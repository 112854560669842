import { AuthentificationService } from "./authentification.service";
import { Injectable, Injector } from "@angular/core";
import {
  HttpInterceptor,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { catchError, retry, tap } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private loaderService: FuseProgressBarService,
  ) {}

  intercept(req, next) :any {
    if (!req.url.includes("real-time") && !req.url.includes("candidatcode/questions/agent/follow-up")) {
      this.showLoader();
    } else if (location.origin.includes("localhost")) {
      if (req.url.includes("pushservice")) console.log("req", req);
      const dupReq = req.clone({ url: req.url.replace("https://app.autoecoleplus.tn/", environment.urlRealTimeLocal) });

      req = Object.assign(req, dupReq);
      if (req.url.includes("pushservice")) console.log("req", req);
    }
    const authService = this.injector.get(AuthentificationService);
    let tokenizedReq = req;
    if (req.url !== "https://api.ipify.org/?format=json" && 
        req.url.indexOf("sandbox") === -1 && req.url.indexOf("clarity") === -1 && req.url.indexOf("paymee") === -1) {
      tokenizedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${authService.getToken()}`,
        },
      });
    }
        
    return next.handle(tokenizedReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      }),
      retry(5),
      catchError((error) => {
        this.onEnd();
        if (error.status === 401 && error.error.message === "logout") {
          authService.logOut();
          console.log("HANDELEIGNG");
        }
        this.handleAuthError(error);
        return of(error);
      }),
    ) as any;
  }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw err;
    }
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw err;
  }
}