import { CandidatService } from "./../../plus/candidats/candidat/candidat.service";
import { CalendarService } from "./../../plus/calendar/calendar.service";
import { PrintService } from "./../../../layout/vertical/layout-1/print.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "app-history-popup",
  templateUrl: "./history-popup.component.html",
  styleUrls: ["./history-popup.component.scss"],
  standalone: false,
})
export class HistoryPopupComponent implements OnInit {
  result = {
    code: true,
    exam: true,
    conduit: true,
    service: true,
    paiement: true,
    service_online: true,
    fraisAdditionnel: true,
    remise: true,
  };

  constructor(
    public dialogRef: MatDialogRef<HistoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _printService: PrintService,
    private _calendarService: CalendarService,
    private _CandidatService: CandidatService,
  ) {
    this.dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClickPrint(): void {
    this._calendarService
      .getCandidatEvents(this.data.candidatId)
      .subscribe((data) => {
        const code = [];
        const conduit = [];
        const exam = [];
        const services = {
          inscription: 0,
          code: {
            nbHeures: 0,
            prixHeure: 0,
            total: 0,
          },
          conduit: {
            nbHeures: 0,
            prixHeure: 0,
            total: 0,
          },
          renouv: {
            nb: 0,
            prixUnite: 0,
            total: 0,
          },
          exam: {
            nb: 0,
            prixUnite: 0,
            total: 0,
          },
          servicePurchases: [],
          fraisAdditionnel: 0,
          total: {
            total: 0,
            remise: 0,
            "total TTC": 0,
            paye: 0,
            reste: 0,
          },
        };
        const fraisAdditionnel = [];
        const remise = [];
        (data as any[]).forEach((element) => {
          switch (element.type) {
            case "conduiteLess":
              conduit.push(element);
              break;
            case "codeEx":
              exam.push(element);
              services.renouv.nb += 1;
              break;
            case "codeLess":
              code.push(element);
              break;
            case "conduiteEx":
              exam.push(element);
              services.renouv.nb += 1;
              services.exam.nb += 1;
              services.exam.total += element.frai;
              break;
          }
        });
        services.renouv.nb =
                    services.renouv.nb > 0 ? services.renouv.nb - 1 : 0;
        if (services.exam.nb > 0)
          services.exam.prixUnite =
                        services.exam.total / services.exam.nb;

        this._CandidatService
          .getPayments(this.data.candidatId)
          .subscribe((paymenData) => {
            const paiement = [];
            (paymenData as any[]).forEach((element) => {
              paiement.push({
                date: element.date,
                montant: element.montant,
                type: element.type,
                paymentId: element.id,
              });
              services.total.paye += element.montant;
            });
            console.log("paiement ->", paymenData);

            this._CandidatService
              .getOneCandidat(this.data.candidatId)
              .subscribe((detailsData) => {
                                
                detailsData.costAdjustments.forEach((ca) => {
                  if (ca.montant > 0) {
                    // frais addtionnel
                    fraisAdditionnel.push({
                      label: ca.label,
                      montant: ca.montant,
                      description: ca.description,
                    });
                    services.fraisAdditionnel += ca.montant;
                  } else if (ca.montant < 0) {
                    // remise
                    remise.push({
                      label: ca.label,
                      montant: -ca.montant,
                    });
                    services.total.remise += -ca.montant;
                  }
                });
                                
                services.inscription = detailsData.fraisInscription;

                services.renouv.total =
                                    detailsData.totRenouvCond + detailsData.totFraisCode;
                if (services.renouv.nb > 0)
                  services.renouv.prixUnite =
                                        services.renouv.total /
                                        services.renouv.nb;

                services.code.total = detailsData.totCo;
                services.code.prixHeure = detailsData.priHeuCo;
                if (services.code.prixHeure > 0)
                  services.code.nbHeures =
                                        services.code.total /
                                        services.code.prixHeure;

                services.conduit.total = detailsData.totCon;
                services.conduit.prixHeure = detailsData.priHeuCon;
                if (services.conduit.prixHeure > 0)
                  services.conduit.nbHeures =
                                        services.conduit.total /
                                        services.conduit.prixHeure;

                services.total.total =
                                    services.inscription +
                                    services.code.total +
                                    services.conduit.total +
                                    services.exam.total +
                                    services.renouv.total +
                                    services.fraisAdditionnel;

                services.total["total TTC"] = services.total.total - services.total.remise;

                services.total.reste =
                                    services.total["total TTC"] - services.total.paye;
                this._CandidatService.getServicePurchasesCandidat(this.data.candidatId).subscribe(
                  (purchaseData) => {
                    const servicePurchase = purchaseData.map(sp => {
                      const payment = paiement.find(p => p.id === sp.paymentId);
                      console.log(payment);
                      if (payment) {
                        return {
                          date: payment.date,
                          type: sp.label,
                          montant: payment.montant,
                        };
                      }
                      return null;
                    }).filter(item => item !== null);
                    console.log(servicePurchase);
                    services.servicePurchases = servicePurchase;
                    this._CandidatService
                      .getCandidatPrintInfo(this.data.candidatId)
                      .subscribe((candidateData) => {
                        this.dialogRef.close({});
                        this._printService.print({
                          services: services,
                          info: candidateData,
                          paiement: paiement,
                          code: code,
                          conduit: conduit,
                          exam: exam,
                          fraisAdditionnel: fraisAdditionnel,
                          remise: remise,
                          show: this.result,
                        });
                      });
                  });
                                
              });
          });
      });
  }

  onYesClickExport(): void {
    this._calendarService
      .getCandidatEvents(this.data.candidatId)
      .subscribe((data) => {
        const code = [];
        const conduit = [];
        const exam = [];
        const services = {
          inscription: 0,
          code: {
            nbHeures: 0,
            prixHeure: 0,
            total: 0,
          },
          conduit: {
            nbHeures: 0,
            prixHeure: 0,
            total: 0,
          },
          renouv: {
            nb: 0,
            prixUnite: 0,
            total: 0,
          },
          exam: {
            nb: 0,
            prixUnite: 0,
            total: 0,
          },
          total: {
            total: 0,
            paye: 0,
            reste: 0,
          },
        };
        (data as any[]).forEach((element) => {
          switch (element.type) {
            case "conduiteLess":
              conduit.push(element);
              break;
            case "codeEx":
              exam.push(element);
              services.renouv.nb += 1;
              break;
            case "codeLess":
              code.push(element);
              break;
            case "conduiteEx":
              exam.push(element);
              services.renouv.nb += 1;
              services.exam.nb += 1;
              services.exam.total += element.frai;
              break;
          }
        });

        services.renouv.nb =
                    services.renouv.nb > 0 ? services.renouv.nb - 1 : 0;
        if (services.exam.nb > 0)
          services.exam.prixUnite =
                        services.exam.total / services.exam.nb;

        this._CandidatService
          .getPayments(this.data.candidatId)
          .subscribe((paymentData) => {
            const paiement = [];
            (paymentData as any[]).forEach((element) => {
              paiement.push({
                date: element.date,
                montant: element.montant,
                type: element.type,
              });
              services.total.paye += element.montant;
            });
            this._CandidatService
              .getOneCandidat(this.data.candidatId)
              .subscribe((resultData) => {
                services.inscription = resultData.fraisInscription;

                services.renouv.total =
                                    resultData.totRenouvCond + resultData.totFraisCode;
                if (services.renouv.nb > 0)
                  services.renouv.prixUnite =
                                        services.renouv.total /
                                        services.renouv.nb;

                services.code.total = resultData.totCo;
                services.code.prixHeure = resultData.priHeuCo;
                if (services.code.prixHeure > 0)
                  services.code.nbHeures =
                                        services.code.total /
                                        services.code.prixHeure;

                services.conduit.total = resultData.totCon;
                services.conduit.prixHeure = resultData.priHeuCon;
                if (services.conduit.prixHeure > 0)
                  services.conduit.nbHeures =
                                        services.conduit.total /
                                        services.conduit.prixHeure;

                services.total.total =
                                    services.inscription +
                                    services.code.total +
                                    services.conduit.total +
                                    services.exam.total +
                                    services.renouv.total;
                services.total.reste =
                                    services.total.total - services.total.paye;

                                    
                this._CandidatService
                  .getCandidatPrintInfo(this.data.candidatId)
                  .subscribe((candidateData) => {
                    this.dialogRef.close({});
                    this._printService.print({
                      services: services,
                      info: candidateData,
                      paiement: paiement,
                      code: code,
                      conduit: conduit,
                      exam: exam,
                      show: this.result,
                    });
                  });
              });
          });
      });
  }

  ngOnInit(): void { }
}
