import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  uri = environment.uriG + ""; //'http://localhost:3000' ;
  
    constructor(private http: HttpClient) { }
  
  
    getAppUpdateById(appUpdateId) :any {
      return this.http.get<any>(`${this.uri}/api/app-update/${appUpdateId}`);
    }
}
