<div class="no-notif-box" *ngIf="(this.notification?.state === 'empty')"> {{emptyNotificationMessage}}</div>

<div class="notif-box" *ngIf="!(this.notification?.state === 'empty')">
    <!-- [ngClass]="{'notif-box-opened' : notification.opened}" -->
    <div class="notif-box-inside">
        <div class="notif-content">
            <div class="notif-image">
                <img src="{{ urlImage }}" alt="Profil Image" class="notif-profil-image">
            </div>
            <div class="notif-content-inside">
                <div class="notification-title"  (click)="markasOpened(notification)">
                    <div class="notif-message">
                            <b> {{notification.title}} </b> 
                            <span [innerHTML]="notification.message">
                            </span>
                    </div>
                    <div class="notif-date">
                        {{getDate(notification)}}
                    </div>
                </div>
                <div class="notif-details" *ngIf="notification.body">
                    <div class="notif-details-inside"[ngClass]=" {
                        'notif-details-empty' : checkIfBodyEmpty(body)
                    }">
                        <ng-container *ngFor="let item of body | keyvalue">
                            <span *ngIf="item.value !== ''">
                                <b *ngIf="item.key !== 'single'">{{ item.key.charAt(0).toUpperCase() + item.key.slice(1) }} : </b> 
                                <span [innerHTML]="item.value"></span>
                                <br>
                            </span>
                        </ng-container>
                        
                        <div *ngIf="tagsToShow.length" class="notif-tag-section">
                                
                            <ng-container *ngFor="let tag of tagsToShow">

                                <div class="notif-tag"
                                        [matTooltip]= "tag.matTooltip"
                                        [class]="tag.tagClass"
                                        [ngClass]="{
                                            'none': tag.label == 'Une réponse',
                                            'seance-code': tag.label == 'séance code',
                                            'seance-conduite': tag.label == 'séance conduite',
                                            'seance-resyclage': tag.label == 'séance recyclage',
                                            'exam-code': tag.label == 'examen code' || tag.label == 'Code',
                                            'exam-conduite': tag.label == 'examen conduite' || tag.label == 'Conduite',
                                            'exam-circulation': tag.label == 'examen circulation' || tag.label == 'Circulation',
                                            'exam-manoeuvre': tag.label == 'examen manoeuvre' || tag.label == 'Manoeuvre',
                                            'depence-tag': tag.type == 'famille'
                                        }"
                                        *ngIf="tag.label != 'label'"
                                    >
                                    {{tag.label}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="notif-shortcut-buttons">
                    <div class="reply-box" *ngIf="buttonsToShow?.[0]?.buttonType === 'envoyer'">
                        <input
                            id="myInput"
                            type="text"
                            [value]="inputValue" 
                            (input)="onInputChange($event)"
                            (click)="eventStop($event)"
                            placeholder="repondre..."
                        >
                    </div>
                    <button
                    (click)="eventStop($event)"
                    *ngFor="let button of buttonsToShow"
                    [ngClass]="button.buttonClass"
                    >
                        {{button.buttonType}}
                    </button>
                </div>

            </div>
        </div>
        <div class="notif-icon" (click)="markasOpened(notification,redirect=false)">
            <div *ngIf="!notification.opened" class="notif-dot">
            </div>
        </div>
    </div>
</div>