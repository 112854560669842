import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "explanation",
  templateUrl: "./explanation.component.html",
  styleUrls: ["./explanation.component.scss"],
  standalone: false,
})
export class ExplanationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ExplanationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  isRight = false;

  ngOnInit(): void {
    if (this.data.answer === this.data.right_answer) {
      this.isRight = true;
    } else {
      this.isRight = false;
    }
  }

  close() :void {
    this.dialogRef.close();
  }
}
