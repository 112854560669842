import { AuthentificationService } from "./../../../main/authentification/authentification.service";
import { GlobalService } from "app/global.service";
import { NavbarService } from "./navbar.service";
import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class NavbarComponent {
  // Private
  _variant: string;

  /**
     * Constructor
     *
     * @param {ElementRef} _elementRef
     * @param {Renderer2} _renderer
     */
  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private navbarService: NavbarService,
    private globalService: GlobalService,
    private _authService: AuthentificationService,
  ) {
    // Set the private defaults
    this._variant = "vertical-style-1";
  }

  ngOnInit(): void {
    if (this._authService.loggedIn())
      this.navbarService.getQuestionCount().subscribe(
        (result: any) => {
          const badge = {
            title: result.count,
            fg: "white",
            bg: "red",
          };
          this.navbarService.setBadgeValue("Candidat-Code", badge);
          this.navbarService.setChildBadgeValue(
            "Candidat-Code",
            "Candidat-Questions",
            badge,
          );
          /* this.navbarService.setChildBadgeValue(
                    "Candidat-Code",
                    "Candidat-series",
                    badge
                ); */
        },
        (err) => {
          if (err.status !== 401) {
            throw err;
          }
        },
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
     * Variant
     */
  get variant(): string {
    return this._variant;
  }

  @Input()
  set variant(value: string) {
    // Remove the old class name
    this._renderer.removeClass(
      this._elementRef.nativeElement,
      this.variant,
    );

    // Store the variant value
    this._variant = value;

    // Add the new class name
    this._renderer.addClass(this._elementRef.nativeElement, value);
  }
}
