import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { depenseFamilles } from "./../../../../../global";
import { EventFormComponent } from "./../../../calendar/event-form/event-form.component";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { DepencesService } from "../depences.service";
import { ErreurService } from "app/main/authentification/erreur.service";

@Component({
  selector: "app-types-popup",
  templateUrl: "./types-popup.component.html",
  styleUrls: ["./types-popup.component.scss"],
  standalone: false,
})
export class TypesPopupComponent implements OnInit {
  familles = depenseFamilles;

  types = [];

  public form: UntypedFormGroup;

  addingType = false;

  deletingId = null;

  constructor(
    private errService: ErreurService,
    private service: DepencesService,
    public _matDialogRef: MatDialogRef<EventFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {}

  ngOnInit() :void  {

    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };
    history.pushState(modalState, null);
        
    this.form = new UntypedFormGroup({
      famille: new UntypedFormControl("", Validators.required),
      label: new UntypedFormControl("", Validators.required),
    });

    this.service.getTypes().subscribe(
      (res) => {
        this.types = res;
      },
      () => {
        this.addingType = false;
      },
    );
  }

  addType() : void {
    if (!this.addingType) {
      this.addingType = true;
      const type = this.form.getRawValue();
      this.service.addType(type).subscribe(
        (res) => {
          this.service.addTypeDepense(res);
          this.types.push(res);
          this.form.reset();
          this.addingType = false;
        },
        () => {
          this.addingType = false;
          const e = "custom";
          this.errService.popError(
            e,
            "Une erreur c'est produite.. \n veuillez réessayer de nouveau.",
          );
        },
      );
    }
  }

  delete(id) : any {
    if (id !== this.deletingId) {
      this.deletingId = id;
      this.service.deleteType(id).subscribe(
        (res) => {
          this.service.delTypeDepense(res);
          this.types.splice(
            this.types.findIndex((x) => x.id === res[0].id),
            1,
          );
          this.deletingId = null;
        },
        () => {
          this.deletingId = null;
          const e = "custom";
          this.errService.popError(
            e,
            "Une erreur c'est produite.. \n veuillez réessayer de nouveau.",
          );
        },
      );
    }
  }

  close() :void {
    this._matDialogRef.close();
  }

  ngOnDestroy():void {
  }

}
