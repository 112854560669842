import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Material Modules
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";

// Third Party Modules
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

// Fuse Modules
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseConfirmDialogModule } from "@fuse/components";

// App Modules
import { DirectivesModule } from "app/helper/directives/directives.module";
import { EventFormComponent } from "./event-form.component";

@NgModule({
  declarations: [
    EventFormComponent,
  ],
  imports: [
    // Angular Core
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    // Material Modules
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,

    // Third Party Modules
    NgxMatTimepickerModule,
    NgxMatSelectSearchModule,

    // Fuse Modules
    FuseSharedModule,
    FuseConfirmDialogModule,

    // App Modules
    DirectivesModule,
  ],
  exports: [
    EventFormComponent,
  ],
})
export class EventFormModule { }
