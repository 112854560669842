import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
@Component({
  selector: "app-mobile",
  templateUrl: "./mobile.component.html",
  styleUrls: ["./mobile.component.scss"],
  standalone: false,
})
export class MobileComponent implements OnInit {
  agents: any[];

  cands: any[];

  voitures = [];

  role: any;

  voiture = null;

  types = [{ value: "codeLess", nom: "Séance Code" }, 
    { value: "conduiteLess", nom: "Séance Conduite" }, 
    { value: "codeEx", nom: "Examen Code" }, 
    { value: "conduiteEx", nom: "Examen Conduite" },
    { value: "conduiteExparc", nom: "Examen Manoeuvre" },
  ];

  typeSelect = null;

  agentSelect = null;

  candidatSelect = null;

  type: any;

  constructor(
    public fb: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<MobileComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) { }

  ngOnInit():void {

    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };
    history.pushState(modalState, null);

    this.role = this._data.role;
    this.type = this._data.type;
    this.agents = this._data.agents;
    this.cands = this._data.cands;
    this.voitures = this._data.voitures;
  }

  closee():void {
    const data = [];
    data.push(this.typeSelect);
    data.push(this.agentSelect);
    data.push(this.candidatSelect);
    data.push(this.voiture);

    this._matDialogRef.close(data);
  }

  ngOnDestroy():void {
    if (window.history.state.modal) {
      history.go(-1);
    }
  }
}
