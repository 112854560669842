import { RealTimeService } from "./real-time.service";
import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { DeviceDetectorService } from "ngx-device-detector";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { environment } from "environments/environment";
import { Router } from "@angular/router";
import { FuseNavigationItem } from "@fuse/types";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  i = 0;

  uri = environment.uriG;

  private user: any = null;

  private navigation: any = {};

  private permission: any[] = [];

  private guide: any = null;

  private agents = [];

  private instructorsNumber = 1;

  private vehicules = [];

  private vehiculesNumber = 1;

  private tarif = {};

  
  private agency = { fraisInscription: 60 };
  
  
  private theme: string = 'light';  

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private socketService: RealTimeService,
  ) {}

  private _currentUser = new ReplaySubject<any>(1);

  private _currentNavigation = new ReplaySubject<any>();
  
  private _currentBottomNavigation = new ReplaySubject<any>();

  private _currentPermission = new ReplaySubject<any>();

  private _currentTheme = new ReplaySubject<any>();


  public getAgency():any {
    this.http
      .get<any>(`${this.uri}/api/agences/agence`)
      .subscribe((agency) => (this.agency = agency));
  }

  get Agency():any {
    return this.agency;
  }

  public getAgents():any {
    this.http
      .get<any>(`${this.uri}/api/employees`)

      .subscribe((agents) => {
        this.agents = agents;
      });
  }

  public getTarif():any {
    this.http
      .get<any>(`${this.uri}/api/agences/tarif`)
      .subscribe((tarif) => (this.tarif = tarif));
  }

  public getVehicules():any {
    this.http
      .get<any>(`${this.uri}/api/vehicles`)
      .subscribe((vehicules) => (this.vehicules = vehicules));
  }

  public getUser():any {
    return this.user;
  }

  public getNavigation():any {
    return this.navigation;
  }

  public getPermission():any {
    return this.permission;
  }

  public getGuide():any {
    return this.guide;
  }

  public getTheme():any {
    return this.theme;
  }


  get currentTarif():any {
    return this.tarif;
  }

  get currentAgents():any {
    return this.agents;
  }

  get currentVehicules():any {
    return this.vehicules;
  }

  loadAgency():any {
    this.getAgents();
    this.getTarif();
    this.getVehicules();
    this.getAgency();
  }


  load():any {
    const authService = this.injector.get(AuthentificationService);
    return new Promise((resolve) => {
      authService.signInUsingToken()
        .subscribe({
          next: (response:boolean | string)=>{
            console.log("look whos working here");
            if (response === "no-internet") {
              this.router.navigate(["/nointernet"]);
              resolve(true);
            } else if (response) {
              this.configUser(this.user, this.navigation, this.permission);
              resolve(true);
            } else {
              authService.logOut();
              resolve(true);
            }
          },
          error: (error)=>{
            if ((error.status === 0 && error.error instanceof ProgressEvent)
                        || error.status === 504) {
              this.router.navigate(["nointernet"]);
              resolve(true);
            }
            authService.logOut();
            resolve(true);
          },
        });
    }); 
  }

  configUser(user, navigation?, permission?, redirect_url?):any {
    const authService = this.injector.get(AuthentificationService);
    if (user) {
      this.user = user;
      this.navigation = navigation;
      this.permission = permission;
      this.guide = this.user.guide;
      this.socketService.connectSocket(
        this.user.id,
        this.user.agenceId,
        this.user.role,
      );
      this.i++;
            
      this.setCurrentUser(this.user);
      this.setCurrentNavigation(this.navigation);
      this.setCurrentPermission(this.permission);
      this.socketService.subscribeToNotifications(this.user.id);
      if (redirect_url) {
        this.router.navigate([redirect_url]);
      }
    } else {
      authService.logOut();
    }
  }

  updateGuide():any {
    this.http
      .put(`${this.uri}/api/employees/guide`, {
        id: this.user.id,
        guide: this.guide,
      })
      .subscribe({
        next: () => {},
        error: () => {} },
      );
  }




  toggleCurrentTheme():any {
    if(this.theme == 'light') {
      this.theme = 'dark';
    }else {
      this.theme = 'light';
    }      
    this._currentTheme.next(this.theme);
    console.log("this new theme", this.theme);
  }


  setCurrentUser(user):any {
    this.user = user;
    this._currentUser.next(user);
  }

  setCurrentNavigation(navigation):any {
    this.navigation = navigation;
    this._currentNavigation.next(navigation);

    const items = ['code-dashboard','dash','candidat','calendar','exams-center'];
    
    console.log('index',items.indexOf(this.navigation[0]?.children[0].id))
    let bottomNavigation = navigation[0]?.children
      ?.filter((e: any) => items.indexOf(e.id) !== -1) // Filter items
      ?.slice(0, 4) // Take the first 4 elements
      ?.map((e:any)=> {return {...e,title : e.title.split(" ")[0]}})
      ?.sort((a: any, b: any) => a.order - b.order);
    console.log(this.navigation[0]?.children?.filter((e: any) => items.indexOf(e.id) !== -1));//.slice(0,4);//.push(addButton)
    console.log({bottomNavigation})
    this._currentBottomNavigation.next(bottomNavigation)
    console.log('currr',this.currentBottomNavigation)

  }

  setCurrentPermission(permission):any {
    this.permission = permission;
    this._currentPermission.next(permission);
  }



  get currentUser():any {
    return this._currentUser.asObservable();
  }

  get currentNavigation():any {
    return this._currentNavigation.asObservable();
  }

  get currentBottomNavigation():any {
    return this._currentBottomNavigation.asObservable();
  }

  get currentPermission():any {
    return this._currentPermission.asObservable();
  }

  get currentTheme():any {
    return this._currentTheme.asObservable();
  }

  detectdevice():any {
    return this.deviceService.getDeviceInfo().os;
  }
}
