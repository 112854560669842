import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TopNotificationComponent } from "./top-notification.component";



@NgModule({
  declarations: [
    TopNotificationComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [TopNotificationComponent],

})
export class TopNotificationModule { }
