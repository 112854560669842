import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TopNotificationService {
  defaultConfig = {
    show: false,
    message: null,
    action: null,
  };

  topNotConfig: BehaviorSubject<any>;


  constructor() {
    this.topNotConfig = new BehaviorSubject(this.defaultConfig);
  }

  set config(config: any) {
    console.log("NEW CONFIG", config);
    this.topNotConfig.next(config);
  }

  get config(): Observable<any> {
    return this.topNotConfig.asObservable();
  }

  generateConfig(user): void {

    switch (user?.status) {
      case "limited":
        this.config = {
          show: true,
          message: user.warning 
          ?? "Votre acces est limité à cause de non paiement ! Veuillez acheter votre abonnement!",
          action: "Facturation",
        };
        break;
      case "warning": {
        this.config = {
          show: true,
          message: user.warning ?? "Veuillez payer votre abonnement" //+ day
            + " pour éviter la suspension de votre compte !", //user.trial_end || user.end,
          action: "Facturation",
        };
        break;
      }
      case "grace": {

        this.config = {
          show: true,
          message: user.warning 
          ?? "Votre abonnement a expire! Veuillez effectuer votre paiement"
            + " pour éviter la suspension de votre compte", //user.trial_end || user.end,
          action: "Facturation",
        };
        break;
      }
      case "blocked":
        break;
      case "blocked_inactive":
        break;
      case "suspended":
      case "incomplete_expired":
      case "incomplete":
        break;

      case "trialing":
        {

          const diff = (new Date(user.trial_end).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000);
          // * Warning before three days of the trial expiry date
          if (diff < 3) {
            this.config = {
              show: true,
              message: user.warning 
              ?? "Votre période d'essai expire bientôt. Veuillez effectuer votre paiement" 
                + " pour éviter la suspension de votre compte.",
              action: "Facturation",
            };
          }
        }
        break;
      case "settled":
        {
          //! settled and still is in trial, dont show notification.
          if (user.trial_end) return;
          const diff = (new Date(user.end).getTime() - new Date().getTime()) / (24 * 60 * 60 * 1000);
          if (diff < 3) {
            this.config = {
              show: true,
              message: user.warning 
              ?? "Votre abonnement expire bientôt. Veuillez effectuer votre paiement" 
                + " pour éviter la suspension de votre compte.",
              action: "Facturation",
            };
          }
        }
        break;
      default:
        break;
    }
  }

  setToDefault(): void {
    this.config = this.defaultConfig;
  }
}
