import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { getRest, getTimbre, getTotalNetHt, getTotalTTC, getTVA } from "app/utils";
import moment from "moment";

@Component({
  selector: "agency-invoice-print",
  templateUrl: "./agency-invoice-print.component.html",
  styleUrls: ["./agency-invoice-print.component.scss"],
  standalone: false,
})
export class AgencyInvoicePrintComponent implements OnInit {

  @Input("data") data;

  @ViewChild("container") container;

  ngOnInit(): void {
  }

  getPeriodStart(invoice_line): any {
    if (invoice_line.label_line.includes("Abonnement système de gestion") && this.data.sub_id) 
      return "(à partir du " + moment(this.data.start).format("Do/MM/yyyy") + " )";
  }

  getTotalNetHt(invoice): any {
    return getTotalNetHt(invoice);
  }

  getTVA(invoice): any {
    return getTVA(invoice);
  }

  getTimbre(invoice): any {
    return getTimbre(invoice);
  }

  getTotalTTC(invoice): any {
    return getTotalTTC(invoice);
  }



  getRest(invoice): any {
    return getRest(invoice);
  }

}
