import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-paiement-print",
  templateUrl: "./paiement-print.component.html",
  styleUrls: ["./paiement-print.component.scss"],
  standalone: false,
})
export class PaiementPrintComponent implements OnInit {
  @Input("data") data;

  countTotal(): any {
    let total = 0;
    this.data.Paiement.forEach((element) => {
      total += element.montant;
    });
    return total;
  }

  getDate(): any {
    const date = new Date();
    return (
      date.getDate() +
            " " +
            this.month[date.getMonth()] +
            " " +
            date.getFullYear()
    );
  }

  ngOnInit(): void {}
  
  month = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "aout",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
}

