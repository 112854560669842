import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-errordialog",
  templateUrl: "./errordialog.component.html",
  styleUrls: ["./errordialog.component.scss"],
  standalone: false,
})
export class ErrordialogComponent implements OnInit {
  public confirmMessage: string;

  balance_limit = false;

  constructor(public dialogRef: MatDialogRef<ErrordialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data?: any,
  ) {

  }

  ngOnInit(): void {
    if (this.data?.error?.message === "balance_limit") this.balance_limit = true;
    console.log("this.data:", this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  facturation(): void {
    this.dialogRef.close();
    this.router.navigate(["/administration/payment/fixed"]);
  }
}
