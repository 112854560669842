import { CandidatService } from "./../../../../../main/plus/candidats/candidat/candidat.service";
import { GlobalService } from "./../../../../../global.service";
import { AuthentificationService } from "./../../../../../main/authentification/authentification.service";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter, take, takeUntil, Subject, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { 
  FusePerfectScrollbarDirective,
} from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { SharedNavigationService } from "@fuse/services/shared-navigation.service";
import { environment } from "environments/environment";
import { ContractService } from "app/main/plus/administration/contract/contract.service";
import { trigger, style, animate, transition } from "@angular/animations";
import { ReclamationDialogComponent } from "app/main/components/reclamation-dialog/reclamation-dialog.component";
import { ErrordialogComponent } from "app/main/components/errordialog/errordialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "app/main/components/succes-snak-bar/succes-snak-bar.component";
import { PopupDialogQueueService } from "app/popup-dialog-queue.service";

@Component({
  selector: "navbar-vertical-style-1",
  templateUrl: "./style-1.component.html",
  styleUrls: ["./style-1.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
  animations: [
    trigger("slideInOut", [
      transition(":enter", [
        style({ transform: "translateX(100%)" }),
        animate("300ms ease-out", style({ transform: "translateX(0)" })),
      ]),
      transition(":leave", [
        animate("300ms ease-out", style({ transform: "translateX(100%)" })),
      ]),
    ]),
  ],
})
export class NavbarVerticalStyle1Component implements OnInit, AfterViewInit, OnDestroy {
  fuseConfig: any;

  fusePerfectScrollbarUpdateTimeout: any;

  navigation: any;

  urlImage: any;

  nom: any;

  adress: any;

  agencyBalance: number;

  isSmol: boolean = false;

  is64: boolean = false;

  is280: boolean = false;

  folded: boolean = false;

  private resizeObserver!: ResizeObserver;

  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;

  private _unsubscribeAll: Subject<any>;

  /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     */
  uriCurr = environment.uriG + "/api/accounts";

  uri = environment.uriG + "/api/employees"; //'http://localhost:3000/api/employees';

  uri2 = environment.uriG + "/api/agences"; //'http://localhost:3000/api/agences';

  getcurrent(): any {
    if (
      this._globalService.getUser() &&
            this._globalService.getUser().role === "superadmin"
    ) {
      return of([]);
    } else {
      return this.http.get<any>(`${this.uri}/current`);
    }
  }

  getImage(nom): any {
    return this.http.get(`${this.uri}/agent-image/small/${nom}`, {
      responseType: "arraybuffer",
    });
  }

  getAgence(id): any {
    console.log(id);
    return this.http.get<any>(`${this.uri2}/agence`);
  }

  constructor(
    private _globalService: GlobalService,
    private _sharedService: SharedNavigationService,
    private authService: AuthentificationService,
    private candidatService: CandidatService,
    private http: HttpClient,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    public contractService: ContractService,
    private elementRef: ElementRef,
    private snackBar: MatSnackBar,
    private _popupDialogService: PopupDialogQueueService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, { static: true })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
          this._fusePerfectScrollbar.update();
        }, 310);
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        setTimeout(() => {
          const activeNavItem: any = document.querySelector(
            "navbar .nav-link.active",
          );

          if (activeNavItem) {
            const activeItemOffsetTop = activeNavItem.offsetTop,
              activeItemOffsetParentTop =
                                activeNavItem.offsetParent.offsetTop,
              scrollDistance =
                                activeItemOffsetTop -
                                activeItemOffsetParentTop -
                                48 * 3 -
                                168;

            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
          }
        });
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
     * On init
     */
  loading(): void {
    this.getcurrent().subscribe(emp => {
      if (emp.image !== null) {
        if (emp.role === "client") {
          this.candidatService.getImage(emp.image).subscribe(file => {
            const bytes = new Uint8Array(file);
            this.urlImage = "data:image/png;base64," + this.encode(bytes);
          });
        } else {
          this.getImage(emp.image).subscribe(file => {
            const bytes = new Uint8Array(file);
            this.urlImage = "data:image/png;base64," + this.encode(bytes);
          });
        }
      } else {
        this.urlImage = "../../../../assets/images/avatars/agent.png";
      }
    });
    this.getcurrent().subscribe(id => {
      this.getAgence(id.agenceId).subscribe(emp => {
        this.nom = emp.nomAge;
        this.adress = emp.adresse;
      });
    });
  }

  getBalance(): void {
    this.contractService.getBilling().subscribe(balance => {   
      this.agencyBalance = balance.balance.balance 
        + balance.balance.bonus_balance
        + balance.balance.refferal_balance;
    });
      
  }


  ngOnInit(): void {

    this.detectWidth();
    if (this.authService.loggedIn() && this._globalService.getUser()) {
      console.log("opa, you are in");
      this.getBalance();
      if (
        this._globalService.getUser() &&
        this._globalService.getUser().role !== "superadmin"
      ) {
        this.loading();
      }
    }
    this._sharedService.getEmittedValue().subscribe(role => {
      if (role !== "superadmin") this.loading();
    });
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar("navbar")) {
          this._fuseSidebarService.getSidebar("navbar").close();
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.fuseConfig = config;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });

  }

  ngAfterViewInit(): void {
    this.detectWidth();
  }

  /**
     * On destroy
     */
    
  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    if (this.fusePerfectScrollbarUpdateTimeout) {
      clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
    }

    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Toggle sidebar opened status
     */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar("navbar").toggleOpen();
  }

  /**
     * Toggle sidebar folded status
     */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar("navbar").toggleFold();
  }
  
  encode(input): any {
    const keyStr =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
  
    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
      chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here
  
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
  
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output +=
              keyStr.charAt(enc1) +
              keyStr.charAt(enc2) +
              keyStr.charAt(enc3) +
              keyStr.charAt(enc4);
    }
    return output;
  }

  detectWidth(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        this.isSmol = width < 140;
        this.is64 = width === 64;
        this.is280 = width === 280;
      }
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  openReclamationDialog(): void {
    this._globalService.currentUser.pipe(take(1)).subscribe({
      next:(user)=>{
        this._popupDialogService.openDialog(ReclamationDialogComponent, {
          panelClass: ["common-dialog-style"],
          data: {
            agencyId: user.agenceId,
            isGeneral: true,
          },
        },
        response => {
          if (response === "success") {
            const snackBarRef = this.snackBar.openFromComponent(SuccesSnakBarComponent, {
              duration: 3000,
            });
            snackBarRef.instance.confirmMessage = "Message envoyée.";
          } else if (response === "error") {
            this._popupDialogService.unshiftDialog(ErrordialogComponent, {
              panelClass: ["common-dialog-style", "co-training-d-style"],
              data: {
                confirmMessage: "Une erreur s'est produite. Veuillez réessayer.",
              },
            });
          }
        });
      }
    })

  }

}
