import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ReclamationsService } from "./reclamations.service";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { CommonModule } from "@angular/common";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { Subject } from "rxjs";
import { ErrordialogComponent } from "../errordialog/errordialog.component";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";

@Component({
  selector: "app-reclamation-dialog",
  standalone: true, 
  imports: [
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatOptionModule,
  ],
  templateUrl: "./reclamation-dialog.component.html",
  styleUrl: "./reclamation-dialog.component.scss",
})
export class ReclamationDialogComponent {

  reclamationForm: FormGroup;

  successMessage: string = "";

  isLoading: boolean = false;

  status$ = new Subject<string>();

  reasons: string[] = [
    "Poser une question",
    "Faire une suggestion",
    "Signaler un probléme",
    "Autre demande",
  ];

  constructor(
    private fb: FormBuilder,
    private reclamationService: ReclamationsService,
    private _matDialog: MatDialog,
    public reclamationDialogRef: MatDialogRef<ReclamationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.reclamationForm = this.fb.group({
      message: ["", [Validators.required, Validators.minLength(10)]],
      reason: ["", this.data?.isGeneral ? [Validators.required] : []],
    });
  }

  submitForm(): void {
    const payload = {
      drivingSchoolId: String(this.data.agencyId),
      candidateId: this.data?.isGeneral ? null : String(this.data.candidateId),
      candidateName: this.data?.isGeneral ? null : this.data.candidateName,
      message: this.reclamationForm.value.message,
      reason: this.reclamationForm.value?.reason,
      isGeneral: this.data?.isGeneral,
    };
    
    this.isLoading = true;
    this.reclamationService.submitReclamation(payload)
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.reclamationDialogRef.close("success");
          this.reclamationForm.reset();
        },
        error: () => {
          this.isLoading = false;
          this._matDialog.open(ErrordialogComponent, {
            panelClass: ["common-dialog-style", "co-training-d-style"],
            data: {
              confirmMessage: "Une erreur s'est produite. Veuillez réessayer.",
            },
          });
        },
      });
  }

  onCancel(): void {
    this.reclamationDialogRef.close();
  }
}
