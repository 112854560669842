import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "fuse-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  standalone: false,
})
export class FuseConfirmDialogComponent {
  public confirmMessage: string;

  public onlyCloseModel: boolean = false;

  /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
  constructor(
    public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data?: any,
  ) {
  }

  ngOnInit(): void {


    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };

    if (this.data?.onlyCloseModel) {
      this.onlyCloseModel = true;
      this.data = null;
    }
    history.pushState(modalState, null);
  }

  ngOnDestroy(): void {
  }


}
