import { NgModule } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { FuseSharedModule } from "@fuse/shared.module";

import { QuickPanelComponent } from "app/layout/components/quick-panel/quick-panel.component";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NotificationBoxComponent } from "./notification-box/notification-box.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [QuickPanelComponent, NotificationBoxComponent],
  imports: [
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatTabsModule,
    BrowserAnimationsModule,
    FuseSharedModule,
  ],
  exports: [QuickPanelComponent],
})
export class QuickPanelModule {}
