import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject, Observable } from "rxjs";
import { environment } from "environments/environment";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  events: any;

  onEventsUpdated: Subject<any>;

  private uri: string = environment.uriG + "/api/calendar/";

  private agenceuri: string = environment.uriG + "/api/agences";

  constructor(private _httpClient: HttpClient) {
    this.onEventsUpdated = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      // Get first and last date of current month
      const today = moment();
      const firstDate = today.startOf("month").format("YYYY-MM-DD");
      const lastDate = today.endOf("month").format("YYYY-MM-DD");

      Promise.all([
        this.getEvents(firstDate, lastDate),
      ]).then(
        ([events]: [any]) => {
          this.events = events;
          this.onEventsUpdated.next(this.events);
          resolve(this.events);
        },
        reject,
      );
    });
  }

  getScheduleSettings():any {
    return this._httpClient.get<any>(`${this.uri}schedule-settings`);
  }

  updateScheduleSettings(scheduleSettings):any {
    const scheduleSettingsObj = scheduleSettings;
    return this._httpClient.put<any>(`${this.uri}schedule-settings/${scheduleSettingsObj.agency_id}`
      , { scheduleSettingsObj });
  }

  getEvent(id):any {
    return this._httpClient.get<any>(`${this.uri}OneEvent/${id}`);
  }

  getlastevent():any {
    return this._httpClient.get<any>(`${this.uri}lastevent`);
  }

  deleteResultExam(event):any {
    return this._httpClient.put<any>(
      `${this.uri}deleteresult/${event.id}`,
      event,
    );
  }

  togglePresence(eventId, absent):any {
    return this._httpClient.put<any>(
      `${this.uri}changepresence/${eventId}`,
      { absent: absent },
    );
  }


  getEventCode(id, numCont, tentCode):any {
    return this._httpClient.get<any>(
      `${this.uri}codeex/${id}/${numCont}/${tentCode}`,
    );
  }

  //? modification d'un exam conduite
  getEventConduite(id, numCont, tentative, parcOrCircuit?):any {
    return this._httpClient.get<any>(
      `${this.uri}condex/${id}/${numCont}/${tentative}/${parcOrCircuit}`,
    );
  }

  addEvent(event):any {
    return this._httpClient.post<any>(this.uri, event);
  }

  addMultipleEvents(events):any {
    return this._httpClient.post<any>(`${this.uri}multiple/`, events);
  }

  getCandidatEvents(id):any {
    return this._httpClient.get<any>(`${this.uri}candidatevents/${id}`);
  }

  getAgence():any {
    return this._httpClient.get<any>(`${this.agenceuri}/agence`);
  }

  getEvents(firstDate, LastDate):any {
    let params = new HttpParams();
    params = params.append("firstdate", firstDate);
    params = params.append("lastdate", LastDate);
    return this._httpClient.get<any>(`${this.uri}`, {
      params: params,
    });
  }

  getEventsCandidat(id):any {
    return this._httpClient.get<any>(`${this.uri}${id}`);
  }

  archieveEvent(id):any {
    return this._httpClient.delete<any>(this.uri + "archieve/" + id);
  }

  deleteEvent(id):any {
    return this._httpClient.delete<any>(`${this.uri}${id}`);
  }

  editEvent(event: any):any {
    return this._httpClient.put<any>(this.uri + event.id, event);
  }

  markAsCompleted(event):any {
    const validation = {
      candidatId: event.candidatId,
      duration: event.duration,
      type: event.type,
      agentResp: event.agentResp,
      contentId: event.id,
    };
    return this._httpClient.put<any>(
      this.uri + "validate/" + event.id,
      validation,
    );
  }

  getFiltredEvent(period, candId, type, monitId):any {
    period.end = moment(period.end).endOf("day");//.toISOString() 

    const params = new HttpParams()
      .set("candId", candId)
      .set("type", type)
      .set("monitId", monitId)
      .set("begin", period.begin.toISOString())
      .set("end", period.end.toISOString());

    return this._httpClient.get<any>(this.uri + "filtredEvent/", {
      params,
    });
  }

  addBusyEvents(events):any {
    return this._httpClient.post<any>(`${this.uri}busyEvent`, events);
  }


  // ! RESERVATIONS
  getSessions(type):any {
    let param = new HttpParams();
    param = param.append("type", type);
    return this._httpClient.get<any>(environment.uriG + "/api/reservation/" + "ReseredSessions/", {
      params: param,
    });
  }
}
