/**
 * @constant {Object} notificationStrcture
 * @description Represents the structure of notifications used throughout the application.
 * 
 * The structure is organized into several categories, each containing subcategories
 * that define the notification types and their attributes.
 * 
 * @property {Object} event - Notifications related to events.
 * @property {Object} reservation - Notifications related to reservations.
 * @property {Object} paiement - Notifications related to payments.
 * @property {Object} depense - Notifications related to expenses.
 * @property {Object} Candidate - Notifications related to candidates.
 * @property {Object} follow-up - Notifications related to follow-ups.
 * 
 * Each category includes:
 * @property {Object} <action> - Represents different actions (e.g., add, edit, delete).
 * @property {Array<Object>} <action>.toShow - Array of buttons to be displayed.
 * @property {Array<Object>} <action>.tags - Array of tag objects providing additional information.
 * 
 * Each tag object contains:
 * @property {string} id - Unique identifier for the tag.
 * @property {string} label - Display label for the tag.
 * @property {string} type - Type of the tag.
 * @property {string} tagClass - CSS class for styling the tag.
 * @property {string} matTooltip - Tooltip text for the tag.
 */

export const notificationStrcture = {
  event:{
    add:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },                
      ],
    },
    edit:{
      toShow:[],  //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
      ],
    },
    delete:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    confirm:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    deleteResult:{ //* I don't think there is type of buttons here
      toShow:[],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        }            ],
    },
    examen:{
      toShow:[],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
  },
  reservation:{
    add:{
      toShow:[
      ],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },               
      ],
    },
    accept:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    decline:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
  },
  paiement:{
    add:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"type",
          tagClass:"eventType-tag",
          matTooltip:"Paiement",
        },
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    edit:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"type",
          tagClass:"eventType-tag",
          matTooltip:"Paiement",
        },
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    delete:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"type",
          tagClass:"eventType-tag",
          matTooltip:"Paiement",
        },
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
  },
  depense:{
    add:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"famille",
          tagClass:"agentFullName-tag",
          matTooltip:"Depense",
        },
      ],
    },
    edit:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"famille",
          tagClass:"agentFullName-tag",
          matTooltip:"Depense",
        },
      ],
    },
    delete:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"famille",
          tagClass:"agentFullName-tag",
          matTooltip:"Depense",
        },
      ],
    },
  },
  Candidate:{
    add:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    edit:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
    delete:{
      toShow:[], //* I don't think there is type of buttons here
      tags:[
        {
          id:"id",
          label:"label",
          type:"eventType",
          tagClass:"eventType-tag",
          matTooltip:"Evenement",
        },
        {
          id:"id",
          label:"label",
          type:"agentFullName",
          tagClass:"agentFullName-tag",
          matTooltip:"Agent",
        },
      ],
    },
  },
  "follow-up":{
    message:{
      toShow:[
      ],
      tags:[
        {
          id:"id",
          label:"label",
          type:"questionNumber",
          tagClass:"agentFullName-tag",
          matTooltip:"Combien de message",
        },
      ],
    },
  },
  "exam_list":{
    add:{
      toShow:[
      ],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
        {
          id:"id",
          label:"label",
          type:"examType",
          tagClass:"agentFullName-tag",
          matTooltip:"Type d'examen",
        },
        {
          id:"id",
          label:"label",
          type:"examCategorie",
          tagClass:"eventType-tag",
          matTooltip:"Catégorie",
        },
      ],
    },
  },
  "user_warning":{
    block:{
      toShow:[
      ],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
      ],
    },
    warning:{
      toShow:[
      ],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
      ],
    },
  },

  user:{
    direct_agency_registration:{
      toShow:[
      ],
      tags:[
        {
          id:"id",
          label:"label",
          type:"candidateFullName",
          tagClass:"candidateFullName-tag",
          matTooltip:"Candidat",
        },
      ],
    },

  },
  
};