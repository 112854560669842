import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-candidat-add-warning",
  templateUrl: "./candidat-add-warning.component.html",
  styleUrls: ["./candidat-add-warning.component.scss"],
  standalone: false,
})
export class CandidatAddWarningComponent implements OnInit {
  type: null;

  nomcandidat: any;

  prenomcan: any;

  nomagence: any;

  adresse: any;

  telephone: any;

  cinNotPayed: any;

  constructor(
    public _matDialogRef: MatDialogRef<CandidatAddWarningComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) { }

  ngOnInit() :any {
    this.type = this._data.type;
    if (this._data.nomcandidat) {
      this.nomcandidat = this._data.nomcandidat;
      this.prenomcan = this._data.prenomcan;
      this.nomcandidat = this.nomcandidat.replace(this.nomcandidat.substring(3, this.nomcandidat.length), "****");
      this.prenomcan = this.prenomcan.replace(this.prenomcan.substring(3, this.nomcandidat.length), "****");
      this.nomagence = this._data.nomagence;
      this.adresse = this._data.adresse;
      this.telephone = this._data.telephone;
      this.cinNotPayed = this._data.cinNotPayed;
    }
  }

  closee() :any {
    this._matDialogRef.close("invalid");
  }
}
