<div id="code-online-training-dialog" class="code-online-training-deep">
    <div *ngIf="true" class="dialog-content-wrapper container-event-form">
        <mat-toolbar class="mat-accent m-0">

            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="24px">
                <span>
                    Formation code en ligne
                </span>

                <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                    <mat-icon class="fail" style="background: inherit !important">close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <!-- ? CONTENT -->

        <div class="p-24 m-0 content" style="overflow-y: auto; overflow-x: visible" fusePerfectScrollbar>
            <form *ngIf="productForm" fxLayout="column" [formGroup]="productForm" (ngSubmit)="onSubmit()">

                <div fxLayout="row" fxLayoutWrap class="w-100-p">
                    <div fxFlex="100" style="display: grid;">
                        <label for="title" class="input-label obligatoire" *ngIf="!isXsScreen" >Titre (العنوان)</label>
                        <mat-form-field appearance="outline">
                            <mat-label *ngIf="isXsScreen" >Titre</mat-label>
                            <input id="title" matInput formControlName="label" placeholder="Enter Titre" />
                            <mat-error *ngIf="productForm.get('label').hasError('required')">
                                Titre obligatoire
                            </mat-error>
                        </mat-form-field>
                    </div>

                    
                </div>
                <div fxLayout="row" fxLayoutWrap class="w-100-p" >

                    <div  fxFlex="100" style="display: grid;">
                        <label class="input-label obligatoire"  *ngIf="!isXsScreen">Description</label>
                        <mat-form-field appearance="outline">
                            <mat-label fxHide.gt-xs>Description</mat-label>
                            <textarea matInput formControlName="description" placeholder="Enter description">
                                </textarea>
                            <mat-error *ngIf="productForm.get('description').hasError('required')">
                                Description obligatoire
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutWrap class="w-100-p" *ngIf="disableAllOnlyPriceField">
                    <div fxFlex="50" style="display: grid;">
                        <label class="input-label obligatoire">Catégorie</label><br>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="category" placeholder="Sélectionnez une catégorie"
                                    multiple>
                                    <mat-option *ngFor="let cat of categories" [value]="cat">
                                        {{ cat }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="productForm.get('category').hasError('required')">
                                    Catégorie obligatoire
                                </mat-error>
                            </mat-form-field>
                    </div>

                    <div fxFlex="50">
                        <label  class="input-label obligatoire">Mode formation</label><br>
                        <mat-radio-group class="my-24" fxLayoutGap="8px" formControlName="limited_hours"
                            aria-label="Select an option">
                            <mat-radio-button [value]="false">illimité</mat-radio-button>
                            <mat-radio-button [value]="true">limité</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="productForm.get('limited_hours').hasError('required')">
                            An option is required
                        </mat-error>
                    </div>
                </div>

                <div *ngIf="disableAllOnlyPriceField" fxLayout="row" fxLayoutWrap class="w-100-p">
                    <div fxFlex="50"  *ngIf="productForm.controls['limited_hours']?.value">
                        <label class="input-label obligatoire">Nbr d'heure</label>
                        <mat-form-field appearance="outline">
                            <input matInput formControlName="max_limit" placeholder="Enter label">
                            <mat-error *ngIf="productForm.get('max_limit').hasError('required')">
                                Nbr obligatoire
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxFlex="50" *ngIf="!productForm.controls['limited_hours']?.value">
                        <label fxHide.xs class="input-label obligatoire">Durée</label>
                        <mat-form-field appearance="outline">
                            <mat-label fxHide.gt-xs>Durée</mat-label>
                            <input matInput formControlName="max_limit" placeholder="Enter label">
                            <mat-error *ngIf="productForm.get('max_limit').hasError('required')">
                                Nbr obligatoire
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="50">
                      <label class="input-label obligatoire">Price</label>
                      <mat-form-field appearance="outline">
                          <input matInput formControlName="price" placeholder="Enter price">
                          <mat-error *ngIf="productForm.get('price').hasError('required')">
                            Prix obligatoire
                          </mat-error>
                          <mat-error *ngIf="priceErrorsMinAndMax">
                            Le prix doit être entre 20 et 200
                          </mat-error>
                      </mat-form-field>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutWrap class="w-100-p">

                    <div fxFlex="50">
                        <label class="input-label obligatoire"  *ngIf="!isXsScreen" >Prix</label>
                        <mat-form-field appearance="outline">
                        <mat-label  *ngIf="isXsScreen" >Prix</mat-label>
                        <input matInput type="number" decimalPlaces formControlName="price" placeholder="Enter price">
                        <mat-error *ngIf="productForm.get('price').hasError('required')">
                            Prix obligatoire
                        </mat-error>
                        <mat-error *ngIf="productForm.get('price').hasError('min') || productForm.get('price').hasError('max')">
                            Le prix doit être entre 20 et 200
                        </mat-error>
                      </mat-form-field>
                  </div>

                </div>

                <div fxLayout="row" fxLayoutWrap class="w-100-p">
                        <div class="step-container-mini">

                            <div class="step-wrapper-mini">
                            <div class="step-tail-mini"></div>
                            <div class="supposed-icon-mini">
                                <span>-</span>
                            </div>
                            <div class="step-content-mini">
                                <div class="step-title-mini"><b>Prix produit TTC : </b>{{productPrice}} TND</div>
                            </div>
                            </div>
                            <div class="step-wrapper-mini">
                            <div class="step-tail-mini"></div>
                            <div class="supposed-icon-mini">
                                <span>-</span>
                            </div>
                            <div class="step-content-mini">
                                <div class="step-title-mini"><b>Frais Transaction TTC: </b>{{trasactionFee}} TND</div>
                        
                            </div>
                            </div>
                            <div class="step-wrapper-mini">
                            <div class="step-tail-mini"></div>
                            <div class="supposed-icon-mini">
                                <span>=</span>
                            </div>
                            <div class="step-content-mini">
                                <div class="step-title-mini"><b>Montant reçu : </b> {{montantRecu}} TND </div>
                            </div>
                            </div>
                        </div>

                    
                </div>
  
                

            </form>

        </div>

        <!-- ? ACTIONS -->

        <mat-divider class="mb-12"></mat-divider>

        <div class="m-0 pt-4 pb-8 px-20" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
                <button *ngIf="edit" mat-icon-button (click)="
                                    _matDialogRef.close(['delete', this._data.event.id])
                                    " aria-label="SAVE"
                                   >
                    <mat-icon style="color: red">delete_outline</mat-icon>
                </button>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-around end" fxLayoutGap="4px">
                <button (click)="onCancel()" mat-stroked-button>
                    Annuler
                </button>
                <button mat-raised-button class="save-button mat-accent" [disabled]="
                        false
                    " aria-label="SAVE" (click)="onSubmit()" [disabled]="productForm.invalid || disableSubmitButton">
                    Enregistrer
                </button>
            </div>
        </div>
    </div>