<div class="navbar-top mater">

    <div>
        <img class="big-navbar-img" style="margin-left: 0px; widows: 150px;height: 46px; z-index: 9999;"
            src="./assets/landing/autoplusLogo.png" alt="autoecoleplus.tn">
        <img class="small-navbar-img" style="margin-left: 0px; widows: 150px;height: 46px; z-index: 9999;"
            src="./assets/landing/minilogo2.svg" alt="autoecoleplus.tn">

    </div>

    <div class="buttons">
        <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>

<div class="solde-shortcut-btn" [ngClass]="{'solde-shortcut-btn-urgent': agencyBalance < 10}">
    <button class="recharge-shortcut-btn" [routerLink]="'/administration/payment/fixed'">
        <mat-icon>add</mat-icon>
    </button>
</div>

<div class="space-between"></div>

<div class="navbar-scroll-container" >
    <div class="solde-container" [ngClass]="{'solde-container-urgent': agencyBalance < 10, 'show': !folded}" [@slideInOut]>
        <div class="balance-label">Solde</div>
        <div class="balance-content">
            <span class="balance">
                <span class="montant">{{agencyBalance}}</span> 
                <span> TND</span>
            </span>
            <button class="recharge-btn" [routerLink]="'/administration/payment/fixed'">
                <mat-icon>add</mat-icon>
                <span>Recharger</span>
            </button>
        </div>
    </div>
    <div class="navbar-content" fusePerfectScrollbar  [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
        <fuse-navigation layout="vertical"></fuse-navigation>
    </div>

</div>

<div class="navbar-footer">
    <div class="invite-box">
        <button mat-flat-button class="invite-btn" [routerLink]="'/referral'">
            <mat-icon class="giftcard-icon">card_giftcard</mat-icon>
            inviter et gagner
        </button>
    </div>
    <div class="reclamation-box">
        <button class="reclamation-btn" (click)="openReclamationDialog()">
            <mat-icon class="reclamation-icon">forum</mat-icon>
            <span>Contactez-nous</span>
        </button>
    </div>
</div>