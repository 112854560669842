import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExamConduiteResultService {
  uri = environment.uriG;

  constructor(
    private http: HttpClient,
  ) {

  }

  getCandCategory(id: string):any {
    return this.http.get<any>(`${this.uri}/api/candidats/category/${id}`);
  }
}
