import { ApplicationRef, enableProdMode } from "@angular/core";
import { enableDebugTools } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "app/app.module";
import { environment } from "environments/environment";
import { hmrBootstrap } from "hmr";
import moment from "moment";

if (environment.production) {
    enableProdMode();
    // console.log = ()=>{}
    // console.warn = ()=>{}
    // console.error = ()=>{}
}
moment.locale('fr');
const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)

if ("serviceWorker" in navigator && environment.production) {
    navigator.serviceWorker.register("/ngsw-worker.js");
}
if (environment.hmr) {
    if (module["hot"]) {
        hmrBootstrap(module, bootstrap);
    } else {
        console.error("HMR is not enabled for webpack-dev-server!");
        console.log("Are you using the --hmr flag for ng serve?");
    }
} else {
    bootstrap()
        // THIS PART IS FOR PROFILING CHANGE DETECTION
        .then(module => {
            if (!environment.production) {
                // window.console.log = () =>{}
                enableDebugTools(module.injector.get(ApplicationRef).components[0])
            }
            /* else{
                let old_console_log = console.log;
                console.log = function() {
                    old_console_log.apply(this, arguments);
        }
            } */
        })
        // ENDS HERE
        .catch(err => console.log(err));
}
