import { CandidatService } from "./../../candidats/candidat/candidat.service";
import { GlobalService } from "app/global.service";
import { ErreurService } from "./../../../authentification/erreur.service";
import { PaymentCDialogService } from "./payment-cdialog.service";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { UsefulService } from "../../useful.service";
import { TeamService } from "@fuse/services/team.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "../../../components/succes-snak-bar/succes-snak-bar.component";
import { ReplaySubject, Subject, map, takeUntil, take } from "rxjs";
import moment from "moment";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { PermissionConfig } from "app/core/permission/permissions.config";

/* export interface dialogData {
    edit: boolean;
    idCandidat: number;
    payeCont: number;
    totCont: number;
    montant: number;
    agent: number;
    id: number;
    date: any;
    candidat: string;
    effectPar: number;
} */
@Component({
  selector: "app-payment-cdialog",
  templateUrl: "./payment-cdialog.component.html",
  styleUrls: ["./payment-cdialog.component.scss"],
  standalone: false,
})
export class PaymentCDialogComponent implements OnInit {
  paymentPermissions = PermissionConfig.comptability;

  confirmDialogRef: any;

  confirming = false;

  team = [];

  cands = [];

  today = new Date();

  role: any;

  types = [
    { label: "Non specific" },
    { label: "Frais d'inscription" },
    { label: "Acompte code" },
    { label: "Acompte conduite" },
    { label: "Renouvellement/inscription conduite" },
    { label: "Renouvellement code " },
    { label: "Frais d'examen" },
  ];

  readOnly: boolean = true;

  public bankCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: UntypedFormControl = new UntypedFormControl("");

  /** list of banks filtered by search keyword */
  public filteredBanks: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  protected _onDestroy = new Subject<void>();

  @ViewChild("singleSelect", { static: true }) singleSelect: MatSelect;


  constructor(
    private _matDialog: MatDialog,
    private router: Router,
    private candidatService: CandidatService,
    private globalService: GlobalService,
    private errService: ErreurService,
    private snackBar: MatSnackBar,
    private teamservice: TeamService,
    private service: PaymentCDialogService,
    public pRef: MatDialogRef<PaymentCDialogComponent>,
    public service1: UsefulService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.pRef.disableClose = true;
    this.role = globalService.getUser().role;
  }

  public form: UntypedFormGroup = new UntypedFormGroup({
    candidat: new UntypedFormControl(null),
    agent: new UntypedFormControl(null, [Validators.required]),
    montant: new UntypedFormControl(null, [
      Validators.required,
      Validators.min(0),
    ]),
    date: new UntypedFormControl(null, [Validators.required]),
    comment: new UntypedFormControl(null),
    type: new UntypedFormControl(this.types[0].label),
  });

  idCand: number;

  montant: number;

  total: number;

  reste: number;

  edit: boolean;

  candidat: string;

  comment: string;

  type: string;

  agents = [];

  availableCands: boolean = true;

  show = false;

  routerlink: string;


  ngOnInit(): void {
    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal: true,
      desc: "fake state for our modal",
    };
    history.pushState(modalState, null);
    console.log({ edit: this.data.edit });

    moment.locale("fr");

    if (this.data.fromDash === true) {
      this.initialWorkFromDash();
      this.readOnly = false;
    } else {
      this.initialWork();
    }
  }

  ngAfterViewInit(): void {
    //     this.setInitialValue();
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.go(-1);
    }
  }

  dinedAction(): string {
    const permissionName = {
      "add": "ajouter",
      "edit": "modifier",
      "delete": "supprimer",
    };
    const accessList = this.globalService.getPermission();
    const paymentPermissions = accessList.filter(perm => perm.name === "recette" && perm.access_granted === false);
    if (paymentPermissions.length === 0) return "";
    let actionString = "Vous n'etes pas autorisé à";
    const lastPermission = paymentPermissions[paymentPermissions.length - 1];
    for (const perm of paymentPermissions) {
      //Explination: if length = 1 don't add the 'et' else if it's the last-1 element then add et else don't 
      actionString += (paymentPermissions.length === 1) ? 
        "" : (perm === lastPermission) ?
          " et" : ",";
      actionString += ` ${permissionName[perm.action_name]}`;
    }
    return actionString + " les paiements";
  }

  goToCandidate(): void {
    console.log("this.form.get('candidat')?.value", this.form.get("candidat")?.value);
    if (this.form.get("candidat")?.value)
      this.router.navigate(["/candidats/" + this.form.get("candidat").value]);
  }

  initialWorkFromDash(): void {
    //! We can hide all forms and display them after selecting a candidate and display his data and rests
    this.fetchAccounts();
    const date = new Date().toISOString();
    this.form.controls.date.setValue(date);
    this.form.controls.date.setValidators([Validators.required]);
    this.form.get("agent").setValue(this.globalService.getUser().id);
  }

  protected setInitialValue(): void {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: any, b: any): boolean =>
          a && b && a.id === b.id;
      });
  }

  protected filterBanks(): void {
    if (!this.cands) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.cands.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    /* this.cands = this.cands.filter(
            bank => bank.username.toLowerCase().indexOf(search) > -1
        ); */
    this.filteredBanks.next(
      this.cands.filter(
        (bank) => bank.username.toLowerCase().indexOf(search) > -1,
      ),
    );
  }

  initialWork(): void {
    console.log("bahaab", this.data);
    this.fetchAccounts();
    this.show = true;
    this.idCand = this.data.idCandidat;
    this.montant = this.data.payeCont;
    this.total = this.data.totCont;
    this.edit = this.data.edit;
    this.candidat = this.data.candidat;
    this.comment = this.data.comment;
    this.readOnly = (this.data.type === "Achat service enligne");
    console.log("readOnly :", this.readOnly);
    if (this.readOnly) {
      this.types = [
        { label: "Non specific" },
        { label: "Frais d'inscription" },
        { label: "Acompte code" },
        { label: "Acompte conduite" },
        { label: "Renouvellement/inscription conduite" },
        { label: "Renouvellement code " },
        { label: "Frais d'examen" },
        { label: "Achat service enligne" },
      ];
    }
    this.type = this.data.type;
    this.reste = this.total - this.montant;
    this.form.get("agent").setValue(this.globalService.getUser().id);
    if (this.edit) {
      this.fetchMontant(this.idCand);
    }
    if (this.data.montant === undefined) {
      console.log("if");

      const date = new Date().toISOString();
      this.form.controls.date.setValue(date);
      this.form.controls.montant.setValidators([
        Validators.min(1),
        Validators.required,
      ]);
      this.fetchAccounts();
    } else {
      console.log("else", this.type);

      const date = new Date(
        moment(this.data.date, "DD-MM-YYYY").format(),
      ).toISOString();
      this.fetchAccounts();
      this.form.controls.montant.setValue(this.data.montant);
      this.form.controls.montant.setValidators([
        Validators.min(1),
        Validators.required,
      ]);
      this.form.controls.agent.setValue(this.data.agent);
      this.form.controls.date.setValue(date);
      this.form.controls.comment.setValue(this.comment);
      this.form.controls.type.setValue(this.type);
    }
    if (this.data.archived) {
      this.form.disable();
    }

    if (this.readOnly) {
      this.form.get("montant")?.disable();
      this.form.get("date")?.disable();
      this.form.get("type")?.disable();
      // this.form.controls["agent"].setValue(" ")

    }
  }

  changeSelected(): void {
    this.fetchMontant(this.form.controls.candidat.value);
  }

  fetchMontant(id): void {
    this.candidatService.getOneCandidat(id).subscribe((res) => {
      this.form.get("candidat").setValue(id);
      this.candidat = res.prenom + " " + res.nom;
      this.total = res.totCont;
      this.montant = res.payeCont;
      this.reste = this.total - this.montant;
      this.show = true;
    });
  }

  onDelete(id): void {

    this.confirmDialogRef = this._matDialog.open(
      FuseConfirmDialogComponent,
      {
        disableClose: false,
      },
    );

    this.confirmDialogRef.componentInstance.confirmMessage =
            "Voulez-vous confirmer la suppression ?";

    this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(
      (result) => {
        if (result) {
          this.service.deleteRecette(id).subscribe({
            next: () => {
              this.snackBar.openFromComponent(
                SuccesSnakBarComponent,
                {
                  duration: 3000,
                },
              );
              this.onCancel();
  
            },
            error: (err) => {
              this.errService.popError(
                err,
                "Un probléme s'est produit lors de suppression. \n Essayer de nouveau.",
              );
          }
          });
        }
        this.confirmDialogRef = null;
      },
    );
  }

  fetchAccounts(): void {
    const donnes = this.data;
    let disable = 0;
    this.teamservice
      .getTeam()
      .pipe(
        map((el) => {
          el.forEach(function (e, index, object) {
            if ((!e.active || e.archived) && donnes.fromDash) {
              object.splice(index, 1);
            } else if (
              e.archived &&
                            donnes.edit &&
                            e.id === donnes.agent
            ) {
              e.username = "Agent supprimé";
              disable = 1;
            } else if (
              (!e.active || e.archived) &&
                            !donnes.fromDash &&
                            !donnes.edit
            ) {
              object.splice(index, 1);
            } else {
              e.username = e.prenom + " " + e.nom;
              delete e.prenom;
              delete e.nom;
            }
          });
          return el;
        }),
      )
      .subscribe((el: any) => {
        this.team = el;
        if (disable === 1) {
          this.form.disable();
        }
        if (this.data.fromDash) {
          this.teamservice.getCands().subscribe((res) => {
            this.cands = res;
            if (this.cands.length === 1) {
              this.form.controls.candidat.setValue(
                this.cands[0].id,
              );
              this.changeSelected();
            } else if (this.cands.length === 0) {
              this.availableCands = false;
            }
            this.bankCtrl.setValue(this.cands[0]);
            // load the initial bank list

            this.filteredBanks.next(this.cands.slice());
            // listen for search field value changes
            this.bankFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterBanks();
              });
          });
        }
      });
  }

  onCancel(): void {
    this.pRef.close();
  }

  onConfirm(): void {
    if (!this.confirming) {
      // insertion
      this.confirming = true;
      if (this.data.montant === undefined && !this.data.fromDash) {
        const {
          agent,
          montant,
          comment,
          type,
        } = this.form.getRawValue();
        const p = {
          agentId: agent,
          candidatId: this.idCand,
          montant: montant,
          date: moment(this.form.getRawValue().date).local().format(),
          comment: comment,
          type: type,
        };
        this.service.setPayment(p).subscribe({
          next: () => {
            this.pRef.close(true);
            this.snackBar.openFromComponent(
              SuccesSnakBarComponent,
              {
                duration: 3000,
              },
            );
            this.confirming = false;
          },
          error: (err) => {
            // this.pRef.close();
            this.errService.popError(
              err,
              "Échec d'opération. \n Essayer de nouveau.",
            );
            this.confirming = false;
          },
        },
        );
        //! edit
      } else if (this.data.fromDash !== true) {
        const {
          agent,
          montant,
          comment,
          type,
        } = this.form.getRawValue();
        const p = {
          agentId: agent,
          effectPar: this.data.effectPar,
          candidatId: this.idCand,
          montant: montant,
          id: this.data.id,
          original: this.data.montant,
          date: moment(this.form.getRawValue().date).local().format(),
          comment: comment,
          type: type,
        };

        this.service.editPayment(p).subscribe({
          next: () => {
            this.pRef.close(true);
            this.snackBar.openFromComponent(
              SuccesSnakBarComponent,
              {
                duration: 3000,
              },
            );
            this.confirming = false;
          },
          error: (err) => {
            // this.pRef.close();
            this.errService.popError(
              err,
              "Échec d'opération. \n Essayer de nouveau.",
            );
            this.confirming = false;
          },
        },
        );
      } else if (this.data.fromDash === true) {
        const {
          agent,
          montant,
          date,
          candidat,
          comment,
          type,
        } = this.form.getRawValue();
        const p = {
          agentId: agent,
          effectPar: this.globalService.getUser().id,
          candidatId: candidat,
          montant: montant,
          date: moment(date).local().format(),
          comment: comment,
          type: type,
        };

        this.service.setPayment(p).subscribe({
          next: () => {
            this.pRef.close();
            this.snackBar.openFromComponent(
              SuccesSnakBarComponent,
              {
                duration: 3000,
              },
            );
            this.confirming = false;
          },
          error: (err) => {
            // this.pRef.close();
            this.errService.popError(
              err,
              "Échec d'opération. \n Essayer de nouveau.",
            );
            this.confirming = false;
          },
        },
        );
      }
      /* let data = {
    'montant' : this.montant,
    'total'   : this.total,
    'reste'   : this.montant, //from the form
    'agentId' :agent,          //from the form
    'effectPar':'mouna01',   //from the cache
    'candidatId':13610034     //we need to pass it at the start
    }
    this.service.setPayment(this.idCont,data); */
    }
  }
}
