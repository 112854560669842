<div class='container'>
    <mat-toolbar class="mat-accent m-0">
     <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
         <span class="title dialog-title" >télecharger csv </span>
         <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
             <mat-icon>close</mat-icon>
         </button>
     </mat-toolbar-row>
 </mat-toolbar>
 <div *ngIf="data.from==1" fxLayout="column" class="content w-100-p">
 <div *ngFor="let item of allOptionsRecette; let i=index;">
    <mat-checkbox [(ngModel)]="item.value" class="w-100-p">{{item.name}}</mat-checkbox>
</div>
</div>
<div *ngIf="data.from==2" fxLayout="column" class="content w-100-p">
    <div *ngFor="let item of allOptionsDepenses; let i=index;">
       <mat-checkbox [(ngModel)]="item.value" class="w-100-p">{{item.name}}</mat-checkbox>
   </div>
   </div>
   <div *ngIf="data.from==3" fxLayout="column" class="content w-100-p">
    <div *ngFor="let item of allOptionsCandidat; let i=index;">
       <mat-checkbox [(ngModel)]="item.value" class="w-100-p">{{item.name}}</mat-checkbox>
   </div>
   </div>

 <mat-divider></mat-divider>
 <div  fxLayout="row" dir='rtl' class=" w-100-p mt-16 mb-16 p-0" fxLayoutAlign="center center">
     <button mat-flat-button class="mat-accent" (click)="onYesClick()" cdkFocusInitial>telecharger</button>
     <button mat-stroked-button  class="buuton" (click)="onNoClick()">Annuler</button>
 </div>
 </div>