import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";

@Component({
  selector: "app-erreur-internet",
  templateUrl: "./erreur-internet.component.html",
  styleUrls: ["./erreur-internet.component.scss"],
  standalone: false,
})
export class ErreurInternetComponent implements OnInit, OnDestroy {

  constructor(
    private splashScreen: FuseSplashScreenService,
    private _fuseConfigService: FuseConfigService, private router: Router,
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  blink = true;

  public ngOnInit(): void {
    this.splashScreen.hide();
  }

  public ngOnDestroy(): void {}

  public recharge(): void {
    window.location.href = "/dashboards/analytics";
  }

}
