import { trigger, style, animate, transition } from "@angular/animations";
import { RealTimeService } from "./../../../real-time.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { Router } from "@angular/router";
import { LayoutService } from "./../../layout.service";
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Injector,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { delay, Subject, takeUntil } from "rxjs";
import moment from "moment";
import { SharedNavigationService } from "@fuse/services/shared-navigation.service";
import { AuthentificationService } from "app/main/authentification/authentification.service";
import { 
  NotificationSettingsDialogComponent,
} from "../notification-settings-dialog/notification-settings-dialog.component";
import { PopupDialogQueueService } from "app/popup-dialog-queue.service";

@Component({
  selector: "quick-panel",
  templateUrl: "./quick-panel.component.html",
  styleUrls: ["./quick-panel.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger("slideInOut", [
      transition(":increment", [
        style({ transform: "translateX(100%)" }),
        animate("300ms ease-out", style({ transform: "translateX(0)" })),
      ]),
      transition(":decrement", [
        style({ transform: "translateX(-100%)" }),
        animate("300ms ease-out", style({ transform: "translateX(0)" })),
      ]),
    ]),
    trigger("rotate", [
      transition("void=>*", [style({ transform: "rotate(0)" })]),
      transition("* => *", [
        style({ transform: "rotate(0)" }),
        animate("650ms", style({ transform: "rotate(360deg)" })),
      ]),
    ]),
  ],
  standalone: false,
})
export class QuickPanelComponent implements OnInit, OnDestroy {
  @ViewChild("scrollableDiv") scrollableDiv!: ElementRef;

  selectedTab: number = 0;

  previousTab: number = 0;

  notifications: any[] = [];

  notifs = Array.from({ length: 5 }, () => []);

  // This list is to note if the notifications has ended or not.
  isNotifsListShort: any[] = [];

  // This list is to note if the notifications list is short or not (without loading more)
  isNotifsListShorter: any[] = [];

  loadingData: boolean = false;

  loadingData$ = new Subject<boolean>();

  intervalId: any;

  badge: number;

  date: Date;

  events: any[];

  notes: any[];

  settings: any;

  unreadNotifications: any;

  computedClass: string;

  emptyNotif: boolean;

  emptuNotifFormat: any;

  emptyNotifList: { [key: string]: any } = {};

  notificationPreferenceData: any;

  quickPanelOpened = true;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
  constructor(
    private injector: Injector,
    private _sharedService: SharedNavigationService,
    private router: Router,
    private _fuseSidebarService: FuseSidebarService,
    private socketService: RealTimeService,
    private authService: AuthentificationService,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService,
    private _popupDialogService: PopupDialogQueueService

  ) {
    // Set the defaults
    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true,
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  dialogRef: any;

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
     * On init
     */

  ngOnInit(): void {
    // Subscribe to the events
    if (this.authService.loggedIn()) {
      for (let i = 0; i < 5; i++) {
        this.loading(i);
      }
    }
    this._sharedService.loginNotification().subscribe(() => {
      for (let i = 0; i < 5; i++) {
        this.loading(i);
      }
    });

    this.updateSubscription();

    this.cdr.detectChanges();
    window.onpopstate = (): void => {
      if (this.quickPanelOpened) {
        this.close();
      }
    };
    this.layoutService.quickPanelToggleOpen.subscribe((res) => {
      if (!res[0]) {
        this.quickPanelOpened = true;

      }
    });

  }

  selectTab(index: number): boolean {
    const previousTab = this.selectedTab;
    const bool = previousTab < index;
    if (previousTab !== index) {
      this.scrollableDiv.nativeElement.scrollTop = 0;
    }
    this.layoutService.scrollToTop$.subscribe(() => {
      this.scrollableDiv.nativeElement.scrollTop = 0;
    });
    this.selectedTab = index;
    this.loadingData = true;
    this.loadingMore(index);
    return bool;
  }

  loading(index: number): void {
    if (this.notifs[index].length === 0) {
      this.layoutService = this.injector.get(LayoutService);
      this.layoutService.getNotifications(this.identifyList(index)).subscribe((qres: any) => {
        qres.forEach((notif) => {
          this.notifs[index].push(notif);
        });

        if (this.notifs[index].length === 0) {
          this.emptyNotif = true;
          this.emptuNotifFormat = { "state": "empty" };
        } else {
          this.emptyNotif = false;
        }
        this.emptyNotifList[this.identifyList(index)] = this.emptyNotif;
        this.isNotifsListShorter[index] = (this.notifs[index].length < 10) ? true : false;


        // console.log("this list ",this.identifyList(index),": ",this.notifs[index]);
      });
    } else {
      console.log("this list ", this.identifyList(index), " is already populated.");
    }
  }

  loadingMore(index: number): void {
    this.loadingData = !this.loadingData;
    if (this.loadingData) {
      this.notifications = [];
      const lastId = this.notifs[index][this.notifs[index].length - 1].id;
      this.layoutService = this.injector.get(LayoutService);
      this.layoutService.getNotifications(this.identifyList(index), lastId)
        .pipe(delay(1000))
        .subscribe((qres: any) => {
          this.isNotifsListShort[index] = (qres.length < 10) ? true : false;

          qres.forEach((notif: any) => {
            this.notifs[index].push(notif);
          });
          console.log("this list ", this.identifyList(index), ": ", this.notifs[index]);
          this.loadingData = !this.loadingData;
        });
    } else {
      console.log("No more hello world :(");
    }
  }

  identifyList(index: number): any {
    if (index === 0) { 
      return "all"; 
    } else if (index === 1) { 
      return "candidate"; 
    } else if (index === 2) {
      return "team"; 
    } else if (index === 3) {
      return "system"; 
    } else if (index === 4) {
      return "exam"; 
    } else { 
      return null; 
    }
  }

  updateNotificationOnFollowUp(notification: any, i: number): void {
    // Search for notification that has the same contentId as the real-time notification.
    const foundFollowUpNotification = this.notifs[i].find((notif) =>
      notif.contentTable === "follow-up"
            && notif.contentId === notification.contentId,
    );
    if (foundFollowUpNotification) {
      const index = this.notifs[i].indexOf(foundFollowUpNotification);
      // If it is found then we change the found one to have the new data and put it in the front
      if (index !== 0) {
        this.notifs[i].unshift(notification);
        this.notifs[i].splice(index + 1, 1);
      } else {
        this.notifs[i][index] = notification;
      }
      this.notifs = [...this.notifs];
      this.cdr.detectChanges();
    } else {
      this.notifs[i].unshift(notification);
      this.notifs = [...this.notifs];
    }
  }

  updateSubscription(): void {
    this.socketService
      .getRealTimeNotification()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notification) => {
        if (notification.contentTable === "follow-up") {
          this.updateNotificationOnFollowUp(notification, 0); //for all
          this.updateNotificationOnFollowUp(notification, 1); //for candidate
        } else {
          this.notifs[0].unshift(notification);
          if (notification.contentTable === "exam_list") {
            if (this.notifs[4].length === 0) { this.loading(4); }
            this.notifs[4].unshift(notification);
          }
          if (notification.contentTable === "reservation") {
            if (notification.action === "add") {
              if (this.notifs[1].length === 0) { this.loading(1); } else { this.notifs[1].unshift(notification); }
            } else {
              if (this.notifs[2].length === 0) { this.loading(2); } else { this.notifs[2].unshift(notification); }
            }
          } else if (["paiement", "depense", "Candidat", "event"].includes(notification.contentTable)) {
            if (this.notifs[2].length === 0) { this.loading(2); } else { this.notifs[2].unshift(notification); }
          }
        }
      });
  }



  goToPage(notification: any): any {
    this.notificationOpened(notification);
    const { contentId, contentTable } = notification;
    const navigate = (params: any, url: string): { urls: string[], params?: any } => {
      params = JSON.stringify([params]);
      const listOfURLS = {
        reservation: {
          urls: ["/reservations"],
          params: { reservation: params },
        },

        event: {
          urls: ["/calendar"],
          params: { event: params },
        },
        paiement: {
          urls: ["/finance/recettes"],
          params: { paiement: params },
        },
        depense: {
          urls: ["/finance/depenses/historique"],
          params: { depense: params },
        },
        exam_list: {
          urls: ["/list-exams"],
        },
        Candidat: {
          urls: ["/candidats"],
          params: { candidat: params },
        },
        "follow-up": {
          urls: ["/code/questions"],
          params: { question: JSON.parse(params)[0] },
        },
      };
      return listOfURLS[url] || { urls: ["/"] };
    };

    if (contentId !== undefined || contentTable !== undefined) {
      const navigationOptions = navigate(contentId, contentTable);
      console.log(navigationOptions);
      this.router.navigate(
        navigationOptions.urls,
        { queryParams: navigationOptions.params },
      );
    } else {

      this.router.navigate(
        navigate("", contentTable).urls,
      );
    }

    this._fuseSidebarService.getSidebar("quickPanel").toggleOpen();

  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }



  notificationOpened(notification): void {
    if (notification.opened === false) {
      notification.opened = true;
    }
  }

  notificationsOpened(notifications): void {
    notifications.forEach(notification => this.notificationOpened(notification));
  }

  markasOpened(notification: any): void {
    this.layoutService
      .notificationOpened(notification.id)
      .subscribe(() => {
        this.goToPage(notification);
        console.log("notif state after: ", notification.opened);
      });
  }

  readAll(index: number): void {
    const type = this.identifyList(index);
    this.layoutService.notificationOpenedByType(type)
      .subscribe({
        next: () => {
          for (let i = 0; i < 5; i++) {
            this.notificationsOpened(this.notifs[i]);
          }
        },
        error: () => { },
      });
  }


  close(): void {
    this.layoutService.closeNotificationBar();
    this.quickPanelOpened = false;
    this.notifs = Array.from({ length: 5 }, () => []);
    for (let i = 0; i < 5; i++) {
      this.loading(i);
    }
  }

  getDate(item): any {
    return moment(item.createdAt).fromNow();
  }

  onSvgClick(event: MouseEvent, item: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.markasOpened(item);
  }

  unreadNotifs(type: any): any {
    let count: number = 0;
    if (type === "all") { 
      this.notifications = this.notifs[0]; 
    } else if (type === "candidate") { 
      this.notifications = this.notifs[1]; 
    } else if (type === "team") { 
      this.notifications = this.notifs[2]; 
    } else if (type === "system") { 
      this.notifications = this.notifs[3]; 
    } else if (type === "exam") { 
      this.notifications = this.notifs[4]; 
    }
    this.notifications.forEach((notif) => {
      if (!notif.opened) {
        count++;
      }
    });
    this.unreadNotifications = count;
    this.unreadNotifications = count >= 10 ? "9+" : count;
    return this.unreadNotifications;
  }

  unreadNotifTen(num: any): any {
    return (num >= 10) ? "9+" : num;
  }

  openNotificationPreferenceDialog(): void {
    this._popupDialogService.openDialog(NotificationSettingsDialogComponent, {
      panelClass: ["notification-settings-style", "common-dialog-style"],
      data: null,
    });
  }


}
