import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class ContractService {
  uri = environment.uriG + "/api/billing";

  token = (environment.production 
    && location.origin.includes("localhost")) 
    ? environment.localPMToken 
    : environment.paymeeToken;

  link = (environment.production
    && location.origin.includes("localhost")) 
    ? environment.localPMLink + "api/v1/payments/" 
    : environment.paymeeLink + "api/v1/payments/";

  constructor(private http: HttpClient) {
  }

  initiatePayment(body): any {
    const header = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Token " + this.token);
    return this.http.post<any>(`${this.link}create`, body, { "headers": header });
  }

  checkPayment(token): any {
    const header = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Authorization", "Token " + this.token);
    return this.http.get<any>(`${this.link}${token}/check`, { "headers": header });
  }

  getExactBalance(): any {
    return this.http.get<any>(`${this.uri}/balance/exact`);
  }

  getBilling(): any {
    return this.http.get<any>(`${this.uri}/balance`);
  }

  getBillingHistory(page = 1): any {
    return this.http.get<any>(`${this.uri}/balance-historique/${page}`);
  }

  getCurrBilling(invoice_id): any {
    return this.http.get<any>(`${this.uri}/curr-billing/${invoice_id}`);

  }

  getPlans(): any {
    return this.http.get<any>(`${this.uri}/plans`)
      .pipe(
        map(plans=>{
          plans.forEach(plan => {
            plan.total_ht = plan.total_plan - plan.total_discount_plan;
            plan.tax = plan.total_ht * 0.19;

          });
          return plans;
        }));
  }

  getSubscriptions(): any {
    return this.http.get<any>(`${this.uri}/subscriptions`);

  }


  makeSubscriptionOnlineDirectPayment(direct_payment, plan_id, duration, lines?): any {
    return this.http.post<any>(`${this.uri}/payment/subscription/online`,
      {
        direct_payment:direct_payment,
        plan_id:plan_id,
        duration:duration,
        lines:lines ?? [],
      });
  }

  makeInvoiceOnlineDirectPayment(direct_payment): any {
    return this.http.post<any>(`${this.uri}/payment/invoice/online`,
      {
        direct_payment:direct_payment,
      });
  }

  creditBalance(amount, metadata): any {
    return this.http.post<any>(`${this.uri}/payment/fixed/online`,
      {
        balance_transaction :{
          type:"balance",
          amount:amount,
          status:"succeeded",
          metadata:metadata,
        },
      },
    );
  }
}
