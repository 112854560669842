import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErreurInternetComponent } from "./erreur-internet/erreur-internet.component";
import { FuseSharedModule } from "@fuse/shared.module";


@NgModule({
  declarations: [ErreurInternetComponent],
  imports: [
    CommonModule,
    FuseSharedModule,
  ],
  exports: [ErreurInternetComponent],

})
export class NoInternetModule { }
