import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { SetLocationComponent } from "./main/pop-ups/set-location/set-location.component";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { LocationService } from "./main/pop-ups/set-location/location.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "./main/components/succes-snak-bar/succes-snak-bar.component";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { PopupDialogQueueService } from "./popup-dialog-queue.service";


@Injectable({
  providedIn: "root",
})
export class CommunicationPopupService {
  i = 0;

  uri = environment.uriG;

  private routerEventsSubscription: Subscription;


  constructor(
    private _authService: AuthentificationService,
    private _locationService: LocationService,
    private snackBar: MatSnackBar,
    private router: Router,
    private _popupDialogService: PopupDialogQueueService
  ) {}



  agenceLocationDialogStart(locationData: any = null): void {
    const hasLocation = locationData && Object.keys(locationData).length > 0;

    this._popupDialogService.openDialog(SetLocationComponent, {
      panelClass: ["common-dialog-style", "common-dialog-large-style", "set-location-d-style"],
      data: {
        fromDash: true,
        title: hasLocation ? "Modifier mon adresse" : "Ajouter ma position",
        location: hasLocation ? locationData : null,
      },
      disableClose: true, 
    },
    (response) => {
      if (response) {
        this.snackBar.openFromComponent(SuccesSnakBarComponent, {
          duration: 3000,
        });
      }
    });
  }

  showLocationPopup(): void {

    if (this._authService.loggedIn()) {
      this.routerEventsSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe((event: NavigationEnd) => {
        if (
          event.urlAfterRedirects !== "/nointernet" && 
                    event.urlAfterRedirects !== "/auth/blocked" &&
                    !event.urlAfterRedirects.includes("administration/payment") &&
                    event.urlAfterRedirects !== "/administration/contract" &&
                    !event.urlAfterRedirects.startsWith("/welcome")
        ) {
          if (this.routerEventsSubscription) {
            this.routerEventsSubscription.unsubscribe();
          }
          setTimeout(()=>{
            this._locationService.getAgencyLocation().subscribe({
              next: (data)=> {
                console.log("getAgencyLocation", data);
                                
                if (data.message === "Not Found") {
                  this.agenceLocationDialogStart(null);
                }
              },
              error : (error) => {
                console.log("error", error);
              },
            });
                        
          }, 4000);
        }
      });
            
    }
  }
}
