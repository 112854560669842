import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { OnlineServicesService } from "app/main/plus/online-services/online-services.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-code-online-training-form",
  templateUrl: "./code-online-training-form.component.html",
  styleUrls: ["./code-online-training-form.component.scss"],
  standalone: false,
})
export class CodeOnlineTrainingFormComponent implements OnInit {
  productForm: FormGroup;

  categories: string[] = ["A", "B", "C"];


  edit = false;

  role = "proprietaire";

  onlineServiceId = null;

  disableSubmitButton = true;


  disableAllOnlyPriceField = false;

  priceErrorsMinAndMax = false;


  isXsScreen = false;

  montantRecu: number = 0.000;


  trasactionFee: number = 0.000;

  totalPrice: number = 0.000;

  productPrice: number = 0.000;

  fee: number = 0.000;

  feePercentage: number = 0.000;


  constructor(
    public _matDialogRef: MatDialogRef<CodeOnlineTrainingFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog,
    private fb: FormBuilder,
    private _onlineServicesService : OnlineServicesService,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.onlineServiceId = data.onlineServiceId;
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
      this.isXsScreen = result.matches;
    });
  }

  ngOnInit(): void {

    console.log(this.onlineServiceId);
    this.productForm = this.fb.group({
      label: ["", Validators.required],
      sub_description: [""],
      description: [""],
      price: ["", [Validators.required, Validators.min(0)]],
      available: [false],
      available_until: [""],
      limited_hours: ["", [Validators.min(0)]],
      max_limit: ["", [Validators.min(0)]],
      allow_freemium: [false],
      category: [[], Validators.required],
    });

    if (this.onlineServiceId !== null) { // when using this compomet for update 
      this._onlineServicesService.getOnlineSerivice(this.onlineServiceId).subscribe({
        next:(onlineServiceData) => {
          this.productForm = this.fb.group({
            label: [onlineServiceData.label, Validators.required],
            sub_description: [onlineServiceData.sub_description],
            description: [onlineServiceData.description],
            price: [onlineServiceData.price, [Validators.required, Validators.min(20), Validators.max(200)]],
            available: [onlineServiceData.available],
            available_until: [onlineServiceData.available_until],
            limited_hours: [onlineServiceData.limited_hours, [Validators.min(0)]],
            max_limit: [onlineServiceData.max_limit, [Validators.min(0)]],
            allow_freemium: [false],
            category: [onlineServiceData.category, Validators.required],
          });
          this.totalPrice = onlineServiceData.price; 
          this.getFees(onlineServiceData);
          this.productForm.get("price").valueChanges.subscribe(newPrice => {
            this.totalPrice = newPrice;
            this.calculatePrice();
            // Perform any additional actions here
          });


          this.productForm.valueChanges.subscribe(() => {
              
            this.disableSubmitButton = false;

          });
            
        },
        error: (err) => {
          console.error(err);
        },
      });
    }
  }


  onSubmit():void {
    this.disableSubmitButton = true;
    if (this.onlineServiceId === null) {
      this._onlineServicesService.createOnlineSerivice(this.productForm.value).subscribe({
        next: data => {
          this.disableSubmitButton = false;

          if (data.msg === "success") {
            this._matDialogRef.close();
          }
        },
        error: () => {
          this.disableSubmitButton = false;
          this._matDialogRef.close();
        },
      });
    } else {
      this._onlineServicesService.updateOnlineSerivice(this.productForm.value, this.onlineServiceId).subscribe({
        next: data => {
          this.disableSubmitButton = false;

          if (data.msg === "success") {
            this._matDialogRef.close(true);
          }
        },
        error: () => {
          this._matDialogRef.close();
        },
      });
    }
  }


  onCancel():void {
    this._matDialogRef.close();
  }


  getFees(service):void {
    this.productPrice = service.productPrice;
    this.feePercentage = service.percentage; 
    this.fee = service.fee;
    console.log("fee", this.fee);
    console.log("fee", ((this.feePercentage / 100 * service.price) + this.fee + this.productPrice));
    console.log("fee", ((this.feePercentage / 100 * service.price) + this.fee + this.productPrice) * 1.19);
    this.montantRecu = +(service.price - this.productPrice
      - ((this.feePercentage / 100 * service.price) + this.fee) * 1.19).toFixed(3);
    this.trasactionFee = +(((this.feePercentage / 100 * service.price) + this.fee) * 1.19).toFixed(3);
    this.productPrice = + this.productPrice.toFixed(3);
  }

  calculatePrice():void {
    console.log("tot", this.totalPrice);
    this.montantRecu = +(this.totalPrice - this.productPrice - ((this.feePercentage / 100 * this.totalPrice)
    + this.fee) * 1.19).toFixed(3);
    this.trasactionFee = +(((this.feePercentage / 100 * this.totalPrice) + this.fee) * 1.19).toFixed(3);
    this.productPrice = + this.productPrice.toFixed(3);
  }



}
