import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseNavigationItem } from '@fuse/types';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { GlobalService } from 'app/global.service';
import { Observable, pipe, Subject, takeUntil } from 'rxjs';
import { ContentModule } from '../components/content/content.module';
import { TopNavSmallComponent } from '../components/top-nav-small/top-nav-small.component';

@Component({
  selector: 'small-layout',
  imports: [
    MatToolbarModule, MatButtonModule, MatIconModule,
    FlexLayoutModule,
    TopNavSmallComponent,
    CommonModule,
    ContentModule
  ],
  templateUrl: './small-layout.component.html',
  styleUrl: './small-layout.component.scss'
})


export class SmallLayoutComponent {

  private _unsubscribeAll: Subject<any>;
  //navigation : []
  navigation$ : Observable<FuseNavigationItem[]>

  constructor(
    private _globalService : GlobalService
  ){}

  ngOnInit(){
    this._unsubscribeAll = new Subject();
    this.navigation$ = this._globalService.currentBottomNavigation;
   /*  this._globalService.currentBottomNavigation.pipe(takeUntil(this._unsubscribeAll))
    .subscribe({
      next:(newNav)=>{this.navigation = newNav}
    }) */
  }

  ngOnDestroy(){
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

}
