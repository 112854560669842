import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PrintService } from "app/layout/vertical/layout-1/print.service";

@Component({
  selector: "recu-agence-payment",
  templateUrl: "./recu-agence-payment.component.html",
  styleUrls: ["./recu-agence-payment.component.scss"],
  standalone: false,
})
export class RecuAgencePaymentComponent implements OnInit {
  now = new Date();

  @Input("data") data;

  pathName: any;
  
  constructor(
    private _printService:PrintService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.pathName = this.router.url.split("/")[1];
  }

  print(): void {
    this._printService.printAgencyRecu(this.data);
    
  }

}
