import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { GlobalService } from "app/global.service";

@Injectable({
  providedIn: "root",
})
export class OnlineServicesService {
  private API_BASE_URL = environment.uriG;

  constructor(private http: HttpClient, private _globalService: GlobalService) { }


  getOnlineServicesList(): Observable<any> {
    return this.http.get(`${this.API_BASE_URL}/api/online-services/`);
  }

  changeAvailabilitySatus(id: any, availability: boolean): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    const requestBody = {
      available: availability,
    };
    
    return this.http.put(`${this.API_BASE_URL}/api/online-services/set-available/${id}`
      , requestBody, { "headers": headers });

  }

  createOnlineSerivice(data:any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.post(`${this.API_BASE_URL}/api/online-services/`, data, { "headers": headers });

  }

  getOnlineSerivice(id:any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.get(`${this.API_BASE_URL}/api/online-services/${id}`,  { "headers": headers });

  }

  updateOnlineSerivice(data:any, id: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.put(`${this.API_BASE_URL}/api/online-services/${id}`, data, { "headers": headers });

  }

  requestPageWeb(): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.post(`${this.API_BASE_URL}/api/online-services/request-new-web-page`,  { "headers": headers });
  }

  getServicePrice(id: any): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.get(`${this.API_BASE_URL}/api/online-services/services/fee/${id}`,  { "headers": headers });
    
  }
}
