import { GlobalService } from "app/global.service";
import { AuthentificationService } from "./authentification.service";
import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of, switchMap } from "rxjs";
                

@Injectable({
  providedIn: "root",
})
export class AuthentificationGuard  {
  constructor(
    private globalService: GlobalService,
    private service: AuthentificationService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    console.log("URL2", state.url);

    return this.service.check()
      .pipe(
        switchMap((authenticated)=>{
          console.log("authenticated", authenticated);
          if (!authenticated ) {
            this.service.logOut();
            return of(false);
          } else {
            if (state.url.startsWith("/welcome")) {
              if (this.globalService.getUser().registration_status !== "referral_pending") {
                this.router.navigate(["/dashboards/analytics"]);
                return of(false);
              }
              return of(true);
            } else if (this.globalService.getUser().registration_status === "referral_pending") {
              this.router.navigate(["/welcome"]);
            }
            const status = this.globalService.getUser().status;
            console.log("status", status, state);
            switch (status) {
              case "limited":
              case "warning":
              case "grace":
                if (state.url === "/auth/blocked") {
                  this.router.navigate(["/dashboards/analytics"]);
                  return of(false);
                }

                // Create the login to display the warning 
                //or do what we need to do. 
                return of(true);
                break;
              case "blocked":
              case "blocked_inactive":
              case "suspended":
              case "incomplete_expired":
              case "incomplete":
                if (state.url !== "/administration/contract" &&
                               state.url !== "/auth/blocked" &&
                               state.url !== "/administration/payment-choice" &&
                               !state.url.includes("/administration/payment/") 
                ) {
                  this.router.navigate(["/auth/blocked"]);
                  return of(false);
                } else return of(true);
                break;

              case "trialing":
              case "settled":

                if (state.url === "/auth/blocked") {
                  this.router.navigate(["/dashboards/analytics"]);
                  return of(false);
                }
                return of(true);
                break;
              default:
                return of(true);
                break;
            }
          }
          return of(true);
        },
        ));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    console.log("URL3", state.url);
    return this.service.check()
      .pipe(
        switchMap((authenticated)=>{
          if (!authenticated ) {
            this.service.logOut();
            return of(false);
          } else {
            const status = this.globalService.getUser().status;
            switch (status) {
              case "limited":
              case "warning":
              case "grace":
                if (state.url === "/auth/blocked") {
                  this.router.navigate(["/dashboards/analytics"]);
                  return of(false);
                }

                // Create the login to displayt the worning
                //or do what we need to do.
                return of(true);
                break;
              case "blocked":
              case "blocked_inactive":
              case "suspended":
              case "incomplete_expired":
                if (state.url !== "/administration/contract" &&
                            state.url !== "/auth/blocked" &&
                            state.url !== "/administration/payment-choice" &&
                            !state.url.includes("/administration/payment") 
                ) {
                  this.router.navigate(["/auth/blocked"]);
                  return of(false);
                } else return of(true);
                break;

              case "trialing":
              case "settled":
              case "incomplete":
                if (state.url === "/auth/blocked") {
                  this.router.navigate(["/dashboards/analytics"]);
                  return of(false);
                }
                return of(true);
                break;
              default:
                return of(true);
                break;
            }
          }
          return of(true);
        },
        ));
  } 
}
