import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { PaiementPrintComponent } from "./../../components/paiement-print/paiement-print.component";
import { PrintComponent } from "./../../components/print/print.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FuseSidebarModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
import { ContentModule } from "app/layout/components/content/content.module";
import { NavbarModule } from "app/layout/components/navbar/navbar.module";
import { QuickPanelModule } from "app/layout/components/quick-panel/quick-panel.module";
import { ToolbarModule } from "app/layout/components/toolbar/toolbar.module";
import { MatButtonModule } from "@angular/material/button";
import { VerticalLayout1Component } from "app/layout/vertical/layout-1/layout-1.component";
import { MatDividerModule } from "@angular/material/divider";
import { PlanningPrintComponent } from "app/layout/components/planning-print/planning-print.component";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { TopNotificationModule } from "app/layout/components/top-notification/top-notification.module";
import { AgencyInvoicePrintModule } from "app/layout/components/agency-invoice-print/agency-invoice-print.module";
import { RecuAgencePaymentModule } from "app/layout/components/recu-agence-payment/recu-agence-payment.module";
import { SmallLayoutComponent } from "app/layout/small-layout/small-layout.component";
@NgModule({
  declarations: [
    VerticalLayout1Component,
    PaiementPrintComponent,
    PrintComponent,
    PlanningPrintComponent,
  ],
  imports: [
    SmallLayoutComponent,
    AgencyInvoicePrintModule,
    RouterModule,
    MatButtonModule,
    FuseSharedModule,
    FuseSidebarModule,
    ContentModule,
    NavbarModule,
    QuickPanelModule,
    ToolbarModule,
    FlexLayoutModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    TopNotificationModule,
    RecuAgencePaymentModule,
  ],
  exports: [VerticalLayout1Component],
})
export class VerticalLayout1Module { }
