<div class='container'>
   <mat-toolbar class="mat-accent m-0">
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title" >Reçu</span>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>
<div  class="content" >
    <div fxLayout="column" fxLayoutAlign="space-between stretch">
        <div fxFlex fxLayout="row" >
            <div fxFlex fxLayout="row">
                <div fxFlex fxFlex fxLayout="row" class="w-100-p" >
                    <div class="w-100-p f-size">date</div>
                </div>
                <div class="font  f-size mr-16" fxFlex>montant</div>
            </div>
        </div>
        <div
            fxFlex
            *ngFor="let item of data.Paiement"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <div fxFlex fxLayout="row" class="inside" >
                <mat-checkbox
                [(ngModel)]="item.selected"
                (change)="verifAll()"
            ></mat-checkbox>
            <div fxLayout="row" fxLayoutAlign="space-around center" style="flex-grow: 1">
                <div class="startt" fxFlex>{{ item.date | date: "dd/MM/yyyy" }}</div>
                <div class="font mr-48" fxFlex>{{ item.montant }}</div>
            </div>
            </div>
        </div>
        <div fxFlex *ngIf="data.Paiement?.length >1">
            <mat-checkbox [(ngModel)]="selectedAll" (change)="selecteAll()" class="font">{{
                selectedAll === false ? "sélectionner tout" : "déselectionner tout"
            }}</mat-checkbox>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div  fxLayout="row" dir='rtl' class=" w-100-p mt-16 mb-16 p-0" fxLayoutAlign="center center">
    <button mat-flat-button class="mat-accent" (click)="onYesClick()" cdkFocusInitial>Imprimer</button>
    <button mat-stroked-button  class="buuton" (click)="onNoClick()">Annuler</button>
</div>
</div>