<div id="depense-dialog" class="depence-container">
    <div class="dialog-content-wrapper">

        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title" ngClass.xs='titlefontxs'>Depense(مصروف)</span>
                <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div mat-dialog-content class="p-24 width" style="overflow-y: auto;" fusePerfectScrollbar>
            <form name="eventForm" [formGroup]="depenseForm" class="w-100-p" autocomplete="off" fxLayout="column">
                <div class="w-100-p" fxLayout="row" fxLayoutGap="12px" fxLayout.xs="column">
                    <mat-form-field class="lezem w-mx" floatLabel="always" appearance="outline" fxHide.gt-xs style="color: #03a9f4;">
                        <mat-label>Famille(العائلة)</mat-label>
                        <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="famille" (valueChange)="familleChange($event)"
                            placeholder="Famille(لازم)">
                            <mat-option *ngFor="let famille of familles" required [value]="famille.val">
                                {{ famille.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label obligatoire">Famille(العائلة)</label>
                        <mat-form-field class="lezem w-mx" floatLabel="always" appearance="outline">
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="famille" (valueChange)="familleChange($event)"
                                placeholder="Famille(لازم)">
                                <mat-option *ngFor="let famille of familles" [value]="famille.val">
                                    {{ famille.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxHide.gt-xs>
                        <mat-form-field floatLabel="always" class="w-mx" appearance="outline">
                            <mat-label>Type(النوع)</mat-label>
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="type" [attr.disabled]="NotModified" placeholder="Type"
                                required>
                                <mat-option *ngFor="let type of types" [value]="type.label">
                                    {{ type.label }}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                        <button *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" type="button" mat-icon-button (click)="addType()" class="pt-16">
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>

                    </div>
                    <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                            <label class="input-label obligatoire">Types(النوع)</label>
                            <button *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" type="button" fxLayout="row" fxLayoutAlign="center center" class="add-type-btn"
                                mat-icon-button (click)="addType()">
                                <mat-icon>add_circle_outline</mat-icon>
                            </button>
                        </div>
                        <mat-form-field floatLabel="always" class="w-mx" appearance="outline">
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="type" [attr.disabled]="NotModified" placeholder="Type">
                                <mat-option *ngFor="let type of types" [value]="type.label">
                                    {{ type.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="showCandidat" class="w-100-p" fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline" fxHide.gt-xs>
                        <mat-label>Candidat(المترشح)</mat-label>
                        <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="candidatId" [attr.disabled]="NotModified" placeholder="(candidat)">
                            <mat-option *ngFor="let candidat of candidats" [value]="candidat.id">
                                {{ candidat.nom }} {{ candidat.prenom }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label">Candidat(المترشح)</label>
                        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="candidatId" [attr.disabled]="NotModified"
                                placeholder="Candidat">
                                <mat-option *ngFor="let candidat of candidats" [value]="candidat.id">
                                    {{ candidat.nom }} {{ candidat.prenom }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="showAgents" class="w-100-p" fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline" fxHide.gt-xs>
                        <mat-label>Agent(عامل)</mat-label>
                        <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="effectPar" [attr.disabled]="NotModified" placeholder="(لازم)">
                            <mat-option *ngFor="let agent of agents" [value]="agent.id">
                                {{ agent.username }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label obligatoire">Agent(عامل)</label>
                        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="effectPar" [attr.disabled]="NotModified" placeholder="(لازم)">
                                <mat-option *ngFor="let agent of agents" [value]="agent.id">
                                    {{ agent.username }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="showResources" class="w-100-p" fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline" fxHide.gt-xs>
                        <mat-label>Ressource(مورد)</mat-label>
                        <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="ressourceId" [attr.disabled]="NotModified">
                            <mat-option *ngFor="let ressource of ressources" [value]="ressource.id"
                                placeholder="(لازم)">
                                {{ ressource.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label obligatoire">Ressource(مورد)</label>
                        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="ressourceId" [attr.disabled]="NotModified">
                                <mat-option *ngFor="let ressource of ressources" [value]="ressource.id"
                                    placeholder="(لازم)">
                                    {{ ressource.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="showVehicle" class="w-100-p" fxLayout="column" fxLayout.gt-xs="row">
                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline" fxHide.gt-xs>
                        <mat-label>Véhicule(العربة)</mat-label>
                        <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="vehiculeId" [attr.disabled]="NotModified" placeholder="(لازم)">
                            <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                                {{ vehicle.nom }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label obligatoire">Véhicule(العربة)</label>
                        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                            <mat-select *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" formControlName="vehiculeId" [attr.disabled]="NotModified" placeholder="(لازم)">
                                <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                                    {{ vehicle.nom }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field class="w-mx lezem" floatLabel="always" appearance="outline" fxHide.gt-xs>
                        <mat-label>Montant(المبلغ)</mat-label>
                        <input *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" decimalPlaces
                            (keyup.enter)="!depenseForm.invalid  &&_matDialogRef.close(depenseForm.getRawValue())"
                            autocomplete="off" matInput type="number" [attr.disabled]="NotModified"
                            formControlName="montant" placeholder="Montant(لازم)" min="1" step="1" />
                        <!-- onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" -->
                    </mat-form-field>
                    <div fxLayout="row">
                        <div class="mr-12" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                            <label class="input-label obligatoire">Montant(المبلغ)</label>
                            <mat-form-field class="w-mx lezem" floatLabel="always" appearance="outline">
                                <input *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" decimalPlaces
                                    (keyup.enter)="!depenseForm.invalid  &&_matDialogRef.close(depenseForm.getRawValue())"
                                    autocomplete="off" matInput type="number" [attr.disabled]="NotModified"
                                    formControlName="montant" min="0" placeholder="Montant(لازم)" />
                                <!-- onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" -->
                            </mat-form-field>
                        </div>
                        <div fxLayout="column"
                            fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                            <label class="input-label obligatoire">Date(التاريخ)</label>
                            <mat-form-field style="max-width: 210px;" class="lezem" floatLabel="always"
                                appearance="outline">
                                <input *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" autocomplete="off" [max]="today" [attr.disabled]="NotModified" matInput
                                    [matDatepicker]="startDatePicker1" (click)="startDatePicker1.open()" readonly
                                    placeholder="Date" name="date" formControlName="date" />
                                <mat-datepicker-toggle *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" matSuffix [for]="startDatePicker1"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <mat-form-field class="lezem"
                    floatLabel="always" appearance="outline" fxHide.gt-xs>
                    <mat-label>Date(التاريخ)</mat-label>
                    <input *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" autocomplete="off" [max]="today" [attr.disabled]="NotModified" matInput
                        [matDatepicker]="startDatePicker" (click)="startDatePicker.open()" readonly required
                        placeholder="Date " name="date" formControlName="date" />
                    <mat-datepicker-toggle *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline" fxHide.gt-xs>
                    <mat-label> Commentaire(تعليق)</mat-label>
                    <input *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" (keyup.enter)="!depenseForm.invalid  &&_matDialogRef.close(depenseForm.getRawValue())"
                        autocomplete="off" matInput type="text" formControlName="comment" placeholder="commentaire" />
                </mat-form-field>

                <div class="w-100-p" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                    <label class="input-label"> Commentaire(تعليق)</label>
                    <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                        <input *accessControl="{ permissions: paymentPermissions[this.action === 'edit' ? 'edit-depense' : 'add-depense']}" (keyup.enter)="!depenseForm.invalid  &&_matDialogRef.close(depenseForm.getRawValue())"
                            autocomplete="off" matInput type="text" formControlName="comment"
                            placeholder="commentaire" />
                    </mat-form-field>
                </div>
            </form>
        </div>
        <!-- _matDialogRef.close(depenseForm.getRawValue()) -->
        <mat-divider></mat-divider>
        <div class="m-0 px-16" fxLayout="row" dir="rtl" fxLayoutAlign="space-between center">

            <div mat-dialog-actions class="m-0 mt-16 mb-16" fxLayout="row" dir="rtl" fxLayoutAlign="start center">
                <button *ngIf="action != 'edit'" mat-flat-button
                    (click)="_matDialogRef.close(depenseForm.getRawValue())" class="mat-accent"
                    [disabled]="depenseForm.invalid || !depenseForm.dirty" aria-label="SAVE">
                    Ajouter
                </button>
                <button *ngIf="action == 'edit' && !NotModified"
                    mat-flat-button (click)="_matDialogRef.close(depenseForm.getRawValue())" class="mat-accent"
                    [disabled]="depenseForm.invalid || !depenseForm.dirty" aria-label="SAVE">
                    Modifier
                </button>
                <button mat-stroked-button (click)="_matDialogRef.close()">Annuler</button>



            </div>

            <ng-container  *ngIf="action == 'edit'&& !NotModified">
                <button *accessControl="{ permissions: paymentPermissions['delete-depense']}"
                    class="historique-button" mat-icon-button (click)="onDelete(_data.row.id)">
                    <mat-icon style="color: red">delete_outline</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="(action == 'edit') && this.dinedAction().length">
                <span 
                    style="margin: 20px 14px 20px 14px; text-align: left; max-width: 330px;">
                    {{this.dinedAction()}}
                </span>
            </ng-container>


        </div>
    </div>
</div>