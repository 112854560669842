import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CodeOnlineTrainingFormComponent } from "./code-online-training-form.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FuseSharedModule } from "@fuse/shared.module";
import { MatDividerModule } from "@angular/material/divider";
import { ReactiveFormsModule } from "@angular/forms";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";



@NgModule({
  declarations: [
    CodeOnlineTrainingFormComponent,
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    ReactiveFormsModule,
    TextFieldModule,
    MatRadioModule,
    MatSelectModule,
  ],
})
export class CodeOnlineTrainingFormModule { }
