
export const toDoTypes = [{
  type: "Type B",
},
{
  type: "Type A",
},
{
  type: "Type AA",
},
{
  type: "Type C",
},
{
  type: "Type C+E",
},
{
  type: "Type D",
},

{
  type: "Type D1",
},

];

export const depenseFamilles = [{
  val: 0,
  label: "Véhicules",
  defaultTypes: [{
    label: "Pieces de Recharge",
  },
  {
    label: "Carburant",
  },
  {
    label: "Visite technique",
  },
  {
    label: "Vignette",
  },
  {
    label: "Traite",
  },
  {
    label: "Assurance",
  },
  {
    label: "Lavage",
  },
  {
    label: "Réparation",
  },
  {
    label: "Autre",
  },
  ],
},

{
  val: 1,
  label: "Candidats",
  defaultTypes: [{
    label: "Examen code",
  },
  {
    label: "Examen conduite",
  },
  {
    label: "Autre",
  },
  ],
},
{
  val: 2,
  label: "Personnelles",
  defaultTypes: [{
    label: "Salaire",
  },
  {
    label: "Prime",
  },
  {
    label: "Avance",
  },
  {
    label: "Autre",
  },
  ],
},
{
  val: 3,
  label: "Locaux",
  defaultTypes: [{
    label: "Facture internet",
  },
  {
    label: "Facture electricite",
  },
  {
    label: "Facture eau",
  },
  {
    label: "Loyer",
  },
  {
    label: "Netoyage",
  },
  {
    label: "Réparation/Amélioration",
  },
  {
    label: "Autre",
  },
  ],
},
{
  val: 4,
  label: "Autre",
  defaultTypes: [{
    label: "Carte telephonique",
  },
  {
    label: "Autre",
  },
  ],
},
];

export const globalGuide = {
  //dashboard
  dashboard: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "dashboard",
    description: "dashboard discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //candidats
  candidats: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "candidats",
    description: "candidats discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //candidat
  candidat: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "candidat",
    description: "candidat discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //cand_contrat
  cand_contrat: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "cand_contrat",
    description: "cand_contrat discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //cand_compte
  cand_compte: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "cand_compte",
    description: "cand_compte discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //cond_historique
  cond_historique: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "cond_historique",
    description: "cond_historique discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //cand_remarque
  cand_remarque: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "cand_remarque",
    description: "cand_remarque discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //cand_infogeneral
  cand_infogeneral: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "cand_infogeneral",
    description: "cand_infogeneral discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //recette
  recette: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "recette",
    description: "recette discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //depences
  depences: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "depences",
    description: "depences discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //revenus
  revenus: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "revenus",
    description: "revenus discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //calendrier
  calendrier: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "calendrier",
    description: "calender discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //vehicules
  vehicules: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "vehicules",
    description: "vehicules discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //equipe
  equipe: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "equipe",
    description: "equipe discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //ressource
  ressource: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "ressource",
    description: "ressource discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //mon_compte
  mon_compte: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "mon_compte",
    description: "mon_compte discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //mon_ecole
  mon_ecole: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "mon_ecole",
    description: "mon_ecole discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //connaissance
  connaissance: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "connaissance",
    description: "connaissance discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
  //faq
  faq: {
    imgUrl: "/assets/images/help-box-img.png",
    title: "faq",
    description: "faq discription",
    videoUrl: "https://www.youtube.com/embed/zZKwwpJtNxU",
    videoSize: 30,
    helpUrl: "/help/knowledge/candidat",
  },
};

export const BaseKnowledge = [
  // exemple
  {
    title_fr: "Guide départ",
    title_ar: "تقديم",
    description: "كيفيّة البداية في إستخدام التطبيق.",
    lien: "/help/knowledge/general-info",
  },
  {
    title_fr: "Acceuil",
    title_ar: "الإستقبال",
    description: "إستعمال الإستقبال وفهم القواعد العامّة للتطبيق.",
    lien: "/help/knowledge/dashboard",
  },
  {
    title_fr: "Candidat",
    title_ar: "المترشح",
    description: "كيفية إضافة المترشح و حساب المترشح. قائمة المترشحين وقانون الطرقات عن بعد",
    lien: "/help/knowledge/candidat",
  },

  {
    title_fr: "Administration",
    title_ar: "الإدارة",
    description: " إدارة المؤسسة, العربات و الموارد.",
    lien: "/help/knowledge/administration",
  },
  {
    title_fr: "Equipe",
    title_ar: "فريق العمل",
    description: "تعلّم كيفيّة إدارة فريق العمل, إضافة الحساب ومتابعة المستحقّات والمصاريف",
    lien: "/help/knowledge/team",
  },
  {
    title_fr: "Comptabilité",
    title_ar: "الحسابات",
    description: "متابعة خلاصات المترشحين, مصاريف المدرسة والمرابيح",
    lien: "/help/knowledge/candidat",
  },
  {
    title_fr: "Calendrier",
    title_ar: "الجدول",
    description: "جدولة الحصص و الإمتحانات, و متابعتها",
    lien: "/help/knowledge/calendar",
  },

];

export const types = [{
  id: "0",
  type: "إشارات وعلامات الطريق",
  qn: 4,
},
{
  id: "1",
  type: "السائق والعربة",
  qn: 2,
},
{
  id: "2",
  type: "الوقوف والتوقف",
  qn: 2,
},
{
  id: "3",
  type: " المقاطعة والمجاوزة",
  qn: 3,
},
{
  id: "4",
  type: " أولوية المرور",
  qn: 4,
},
{
  id: "5",
  type: "قواعد الجولان ",
  qn: 7,
},
{
  id: "6",
  type: "جرائم الجولان ",
  qn: 3,
},
{
  id: "7",
  type: "الإسعافات الأولية ",
  qn: 2,
},
{
  id: "8",
  type: "الصيانة الوقائية والتحكم في الطاقة",
  qn: 2,
},
{
  id: "9",
  type: " نقل المواد الخطرة عبر الطرقات",
  qn: 1,
},
{
  id: "10",
  type: "",
  qn: 1,
},
{
  id: "11",
  type: "لواج / ثقيلة",
  qn: 1,
},
{
  id: "11",
  type: "حافلات / ثقيلة + مجرورة",
  qn: 1,
},
];

export const series = [{
  num: 1,
  name: "serie 1",
},
{
  num: 2,
  name: "serie 2",
},
{
  num: 3,
  name: "serie 3",
},
{
  num: 4,
  name: "serie 4",
},
{
  num: 5,
  name: "serie 5",
},
{
  num: 6,
  name: "serie 6",
},
{
  num: 7,
  name: "serie 7",
},
{
  num: 8,
  name: "serie 8",
},
{
  num: 9,
  name: "serie 9",
},
{
  num: 10,
  name: "serie 10",
},
{
  num: 11,
  name: "serie 11",
},
{
  num: 12,
  name: "serie 12",
},
{
  num: 13,
  name: "serie 13",
},
{
  num: 14,
  name: "serie 14",
},
{
  num: 15,
  name: "serie 15",
},
];


export const typeoptions = {
  // {
  // name: "1أ",
  // value: "A1",
  // iconUrls: ["assets/vehicule/bicycle-solid.svg"],
  // },
  typeA: {
    name: "أ",
    value: "A",
    iconUrls: ["assets/vehicule/motorcycle-solid.svg"],
    dbValue: "typeA",
    optionValue: "صنف أ",
  },
  typeB: {
    name: "ب",
    value: "B",
    iconUrls: ["assets/vehicule/car-solid.svg"],
    dbValue: "typeB",
    optionValue: "صنف ب",

  },
  // {
  // name: "ب+هـ",
  // value: "B+E",
  // iconUrls: [
  // "assets/vehicule/caravan-solid.svg",
  // "assets/vehicule/car-solid.svg",
  // ],
  // },
  typeD1: {
    name: "د1",
    value: "D1",
    iconUrls: [
      "assets/vehicule/taxi-solid.svg",
      "assets/vehicule/shuttle-van-solid.svg",
    ],
    dbValue: "typeD1",
    optionValue: "صنف د1",


  },
  typeD: {
    name: "د",
    value: "D",
    iconUrls: ["assets/vehicule/bus-solid.svg"],
    dbValue: "typeD",
    optionValue: "صنف د",

  },
  // {
  // name: "د+هـ",
  // value: "D+E",
  // iconUrls: [
  // "assets/vehicule/caravan-solid.svg",
  // "assets/vehicule/bus-solid.svg",
  // ],
  // },
  typeC: {
    name: "ج",
    value: "C",
    iconUrls: ["assets/vehicule/truck-solid.svg"],
    dbValue: "typeC"

    ,
    optionValue: "صنف ج",

  },
  typeCE: {
    name: "ج+هـ",
    value: "C+E",
    iconUrls: ["assets/vehicule/truck-moving-solid.svg"],
    dbValue: "typeCE",
    optionValue: "صنف ج+هـ",

  },
};


export const subdivision = [
  "TUNIS",
  "TUNIS2 SIJOUMI",
  "ARIANA",
  "MANOUBA",
  "BEN AROUS",
  "BIZERTE",
  "SOUSSE",
  "MONASTIR",
  "MAHDIA",
  "GABES",
  "SFAX",
  "SAKIT ZIT",
  "JENDOUBA",
  "MEDNINE",
  "KASSERINE",
  "ZARZIS",
  "KAIROUAN",
  "BEJA",
  "SIDI BOUZID",
  "TOZEUR",
  "KEBILI",
  "DJERBA",
  "NABEUL",
  "TATAOUINE",
  "SALAMBO",
  "LE KEF",
  "ZAGHOUAN",
  "SILIANA",
  "GAFSA",
  "BEN GUERDENE",
];

export const tnBanksInfo = [
  {
    name: "ARAB TUNISIAN BANK",
    atl: "ATB",
    image: "assets/images/bankslogo/ATB.jpg",
  },
  {
    name: "AMEN BANK",
    atl: "AMEN BANK",
    image: "assets/images/bankslogo/AMEN_BANK.jpg",
  },
  {
    name: "BANQUE ATTIJARI DE TUNISIE",
    atl: "ATTIJARI",
    image: "assets/images/bankslogo/ATTIJARI.jpg",
  },
  {
    name: "BH BANK",
    atl: "BH",
    image: "assets/images/bankslogo/BH.jpg",
  },
  {
    name: "BANQUE TUNISO-KOWEÏTIENNE",
    atl: "BTK",
    image: "assets/images/bankslogo/BTK.jpg",
  },
  {
    name: "BANQUE DE TUNISIE ET DES EMIRATS",
    atl: "BTE",
    image: "assets/images/bankslogo/BTE.jpg",
  },
  {
    name: "ALUBAF INTERNATIONAL BANK",
    atl: "ALUBAF",
    image: "assets/images/bankslogo/ALUBAF.jpg",
  },
  {
    name: "UNION INTERNATIONALE DE BANQUES",
    atl: "UBI",
    image: "assets/images/bankslogo/UBI.jpg",
  },
  {
    name: "TUNISIAN SAUDI BANK",
    atl: "TSB",
    image: "assets/images/bankslogo/TSB.jpg",
  },
  {
    name: "ARAB BANKING CORPORATION BRANCHE ONSHORE",
    atl: "ABC",
    image: "assets/images/bankslogo/ABC.jpg",
  },
  {
    name: "NORTH AFRICA INTERNATIONAL BANK",
    atl: "NAIB",
    image: "assets/images/bankslogo/NAIB.jpg",
  },
  {
    name: "LA POSTE TUNISIENNE",
    atl: "LPT",
    image: "assets/images/bankslogo/LPT.jpg",
  },
  {
    name: "BANQUE NATIONALE AGRICOLE",
    atl: "BNA",
    image: "assets/images/bankslogo/BNA.jpg",
  },
  {
    name: "BANQUE DE TUNISIE",
    atl: "BT",
    image: "assets/images/bankslogo/BT.jpg",
  },
  {
    name: "SOCIETE TUNISIENNE DE BANQUE",
    atl: "STB",
    image: "assets/images/bankslogo/STB.jpg",
  },
  {
    name: "BANQUE INTERNATIONALE ARABE DE TUNISIE",
    atl: "BIAT",
    image: "assets/images/bankslogo/BIAT.jpg",
  },
  {
    name: "LOAN AND INVESTMENT CO",
    atl: "LINC",
    image: "assets/images/bankslogo/LINC.jpg",
  },
  {
    name: "BANQUE ZITOUNA",
    atl: "BZ",
    image: "assets/images/bankslogo/BZ.jpg",
  },
  {
    name: "WIFAK INTERNATIONNAL BANK",
    atl: "WIFAK INTERNATIONNAL BANK",
    image: "assets/images/bankslogo/WIFAK_INTERNATIONNAL_BANK.jpg",
  },
  {
    name: "BANQUE FRANCO-TUNISIENNE",
    atl: "BFT",
    image: "assets/images/bankslogo/BFT.jpg",
  },
  {
    name: "UNION BANCAIRE POUR LE COMMERCE ET L'INDUSTRIE",
    atl: "UBCI",
    image: "assets/images/bankslogo/UBCI.jpg",
  },
  {
    name: "BANQUE TUNISO-LIBYENNE",
    atl: "BTL",
    image: "assets/images/bankslogo/BTL.jpg",
  },
  {
    name: "BANQUE TUNISIENNE DE SOLIDARITE",
    atl: "BTS",
    image: "assets/images/bankslogo/BTS.jpg",
  },
  {
    name: "AL BARAKA BANK TUNISIA",
    atl: "AL BARAKA",
    image: "assets/images/bankslogo/AL_BARAKA.jpg",
  },
  {
    name: "TUNIS INTERNATIONAL BANK",
    atl: "TIB",
    image: "assets/images/bankslogo/TIB.jpg",
  },
  {
    name: "CITIBANK BRANCHE ONSHORE",
    atl: "CITI BANK",
    image: "assets/images/bankslogo/CITI_BANK.jpg",
  },
  {
    name: "QATAR NATIONAL BANK",
    atl: "QNB",
    image: "assets/images/bankslogo/QNB.jpg",
  },
];


export const contactPhoneNumber = "50889883";