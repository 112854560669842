<div class="print">
    <table id="head-table" class="table">
        <tr>
            <td valign="top">
                <table class="table">
                    <tr>
                        <td class="ecole-name bold">{{ data.info.nomAge }}</td>
                    </tr>
                    <tr>
                        <td class="semi-bold">{{ data.info.adresse }}</td>
                    </tr>
                    <tr>
                        <td class="semi-bold">Tel: {{ data.info.telephone }}</td>
                    </tr>
                    <!-- <tr>
                        <td class="semi-bold">
                            Site : autoecoleplus.tn/autoecolemaher
                        </td>
                    </tr> -->
                </table>
            </td>
            <td valign="top" rowspan="2">
                <table class="tb-al-right">
                    <tr>
                        <td valign="top">
                            <table class="tb-al-center">

                                <tr>
                                    <td
                                        class="country-name fit-width pl-10 bold"
                                    >
                                        <!-- الجمهورية التونسية -->
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="divider head-divider"></div>
                        </td>
                    </tr>
                    <tr>
                        <td valign="top" class="fit-width">
                            <table class="tb-al-center">
                                <tr>
                                    <td class="center">
                                        <img
                                            src="https://autoecoleplus.tn/assets/Group.png"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td class="fit-width pl-10 bold">
                                        autoecoleplus.tn
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td valign="bottom">
                <table class="table mt-30">
                    <tr>
                        <td colspan="2" class="bold">
                            Pour Mr/Mme : {{ data.info.prenom }} {{ data.info.nom }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="bold">
                            CIN : {{ data.info.canCin }}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="bold">
                            Date : {{ getDateNow() }}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table id="table-service" class="table mt-30" *ngIf="data.show.service">
        <tr>
            <th class="left">Service</th>
            <th>Unité</th>
            <th>Prix Unitaire</th>
            <th>Nombre</th>
            <th>Total</th>
        </tr>
        <tr>
            <td colspan="5"><div class="divider head-divider"></div></td>
        </tr>
        <tr>
            <td class="bold">Inscription</td>
            <td class="center semi-bold">fois</td>
            <td class="center semi-bold">{{ data.services.inscription }}</td>
            <td class="center semi-bold">1</td>
            <td class="center semi-bold">{{ data.services.inscription }}</td>
        </tr>
        <ng-container *ngFor="let onlineService of  data.services.servicePurchases">
            <tr>
                <td colspan="5"><div class="divider body-divider"></div></td>
            </tr>
            <tr>
                <td class="bold">{{ onlineService.type }}</td>
                <td class="center semi-bold">-</td>
                <td class="center semi-bold">{{ onlineService.montant }}</td>
                <td class="center semi-bold">1</td>
                <td class="center semi-bold">{{ onlineService.montant }}</td>
            </tr>
        </ng-container>
        
        <tr>
            <td colspan="5"><div class="divider body-divider"></div></td>
        </tr>
        <tr>
            <td class="bold">Séance Code</td>
            <td class="center semi-bold">1 heure</td>
            <td class="center semi-bold">{{ data.services.code.prixHeure }}</td>
            <td class="center semi-bold">{{ data.services.code.nbHeures }}</td>
            <td class="center semi-bold">{{ data.services.code.total }}</td>
        </tr>
        <tr>
            <td colspan="5"><div class="divider body-divider"></div></td>
        </tr>
        <tr>
            <td class="bold">Séance Conduite</td>
            <td class="center semi-bold">50 minutes</td>
            <td class="center semi-bold">
                {{ data.services.conduit.prixHeure }}
            </td>
            <td class="center semi-bold">
                {{ data.services.conduit.nbHeures }}
            </td>
            <td class="center semi-bold">{{ data.services.conduit.total }}</td>
        </tr>
        <tr>
            <td colspan="5"><div class="divider body-divider"></div></td>
        </tr>
        <tr>
            <td class="bold">
                Renouvellement / inscription <br />
                Examen
            </td>
            <td class="center semi-bold">fois</td>
            <td class="center semi-bold">
                {{ data.services.renouv.prixUnite }}
            </td>
            <td class="center semi-bold">{{ data.services.renouv.nb }}</td>
            <td class="center semi-bold">{{ data.services.renouv.total }}</td>
        </tr>
        <tr>
            <td colspan="5"><div class="divider body-divider"></div></td>
        </tr>
        <tr>
            <td class="bold">Frais d’examen</td>
            <td class="center semi-bold">fois</td>
            <td class="center semi-bold">{{ data.services.exam.prixUnite }}</td>
            <td class="center semi-bold">{{ data.services.exam.nb }}</td>
            <td class="center semi-bold">{{ data.services.exam.total }}</td>
        </tr>
        <tr>
            <td colspan="5"><div class="divider body-divider"></div></td>
        </tr>
        <tr *ngIf="data.services.fraisAdditionnel">
            <td class="bold">Frais Additionnel</td>
            <td class="center semi-bold">-</td>
            <td class="center semi-bold">-</td>
            <td class="center semi-bold">-</td>
            <td class="center semi-bold">{{ data.services.fraisAdditionnel }}</td>
        </tr>
        <tr *ngIf="data.services.fraisAdditionnel">
            <td colspan="5"><div class="divider body-divider"></div></td>
        </tr>
    </table>

    <table class="table mt-30" *ngIf="data.show.service">
        <tr>
            <td class="fit-width right">
                <table id="table-total" class="tb-al-right">
                    <tr *ngIf="data.services.total.remise">
                        <td class="left bold">TOTAL</td>
                        <td class="pl-10 total bold">
                            {{ data.services.total.total| currency:'TND':'DT'}}
                        </td>
                    </tr>
                    <tr *ngIf="data.services.total.remise">
                        <td class="left bold">REMISE</td>
                        <td class="pl-10 total bold">
                            {{ data.services.total.remise| currency:'TND':'DT'}}
                        </td>
                    </tr>
                    <tr>
                        <td class="left bold">TOTAL TTC</td>
                        <td class="pl-10 total bold">
                            {{ data.services.total["total TTC"]| currency:'TND':'DT'}}
                        </td>
                    </tr>
                    <tr>
                        <td class="left bold">Payé</td>
                        <td class="total bold">
                            {{ data.services.total.paye | currency:'TND':'DT'}}
                        </td>
                    </tr>
                    <tr class="reste">
                        <td class="left bold">Reste</td>
                        <td class="bold">
                            {{ data.services.total.reste | currency:'TND':'DT'}}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table id="table-paiement" class="table mt-30" *ngIf="data.show.paiement">
        <tr>
            <th class="left">Paiements</th>
            <th class="right">Date</th>
        </tr>
        <tr>
            <td colspan="2"><div class="divider head-divider"></div></td>
        </tr>
        <tr>
            <td colspan="2" class="center" *ngIf="data.paiement.length === 0">
                paiement vide
            </td>
        </tr>
        <ng-container *ngFor="let item of data.paiement">
            <ng-container
                *ngIf="
                    !item.type || item.type === 'Non specific';
                    else withType
                "
            >
                <tr>
                    <td class="bold">{{ item.montant | currency:'TND':'DT'}}</td>
                    <td class="right semi-bold">{{ getDate(item.date) }}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="divider body-divider"></div>
                    </td>
                </tr>
            </ng-container>
            <ng-template #withType>
                <tr>
                    <td>
                        <table>
                            <tr>
                                <td class="bold">{{ item.montant | currency:'TND':'DT'}}</td>
                            </tr>
                            <tr>
                                <td class="gris">{{ item.type }}</td>
                            </tr>
                        </table>
                    </td>
                    <td class="right semi-bold">{{ getDate(item.date) }}</td>
                </tr>
                <tr>
                    <td colspan="2">
                        <div class="divider body-divider"></div>
                    </td>
                </tr>
            </ng-template>
        </ng-container>
        <tr>
            <td colspan="2" class="right">
                <table class="tb-al-right">
                    <tr>
                        <td class="bold">TOTAL</td>
                        <td class="pl-10 bold total">
                            {{ getPaimenetTotal() | currency:'TND':'DT'}}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table id="table-seance" class="table mt-30" *ngIf="data.show.code">
        <tr>
            <th class="left">Séance Code</th>
            <th>Horaire</th>
            <th>Durée</th>
        </tr>
        <tr>
            <td colspan="3"><div class="divider head-divider"></div></td>
        </tr>
        <tr>
            <td colspan="3" class="center" *ngIf="data.code.length === 0">
                Séance Code vide
            </td>
        </tr>
        <ng-container *ngFor="let item of data.code">
            <tr>
                <td class="bold">{{ getDate(item.debut) }}</td>
                <td class="semi-bold center">
                    {{ getHours(item.debut) }} - {{ getHours(item.fin) }}
                </td>
                <td class="semi-bold center">
                    {{ item.duration }}
                </td>
            </tr>
            <tr>
                <td colspan="3"><div class="divider body-divider"></div></td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="3" class="right">
                <table class="tb-al-right">
                    <tr>
                        <td class="bold">TOTAL</td>
                        <td class="pl-10 bold total">
                            {{ getTotalDuration(data.code) }} Heures
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table id="table-seance" class="table mt-30" *ngIf="data.show.conduit">
        <tr>
            <th class="left">Séance Conduite</th>
            <th>Horaire</th>
            <th>Durée</th>
        </tr>
        <tr>
            <td colspan="3"><div class="divider head-divider"></div></td>
        </tr>
        <tr>
            <td colspan="3" class="center" *ngIf="data.conduit.length === 0">
                Séance Conduite vide
            </td>
        </tr>
        <ng-container *ngFor="let item of data.conduit">
            <tr>
                <td class="bold">{{ getDate(item.debut) }}</td>
                <td class="semi-bold center">
                    {{ getHours(item.debut) }} - {{ getHours(item.fin) }}
                </td>
                <td class="semi-bold center">
                    {{ item.duration }}
                </td>
            </tr>
            <tr>
                <td colspan="3"><div class="divider body-divider"></div></td>
            </tr>
        </ng-container>
        <tr>
            <td>Une séance code dure 50 minutes.</td>
            <td colspan="2" class="right">
                <table class="tb-al-right">
                    <tr>
                        <td class="bold">TOTAL</td>
                        <td class="pl-10 bold total">
                            {{ getTotalDuration(data.conduit) }} Heures
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table id="table-exam" class="table mt-30" *ngIf="data.show.exam">
        <tr>
            <th>Examen</th>
            <th>Tentative</th>
            <th>Resultat</th>
            <th>Detaille</th>
            <th>Date</th>
        </tr>
        <tr>
            <td colspan="5"><div class="divider head-divider"></div></td>
        </tr>
        <tr>
            <td colspan="5" class="center" *ngIf="data.exam.length === 0">
                Examen vide
            </td>
        </tr>
        <ng-container *ngFor="let item of data.exam">
            <tr>
                <td class="semi-bold center">{{ getExamType(item.type) }}</td>
                <td class="semi-bold center">{{ item.tentative }}</td>
                <td class="semi-bold center">{{ getResult(item.success) }}</td>
                <td class="semi-bold center">{{ getDetail(item) }}</td>
                <td class="semi-bold center">{{ getDate(item.debut) }}</td>
            </tr>
            <tr>
                <td colspan="5"><div class="divider body-divider"></div></td>
            </tr>
        </ng-container>
    </table>


    <table id="table-frais-additionnel" class="table mt-30" *ngIf="data.show.fraisAdditionnel && data.fraisAdditionnel.length">
        <tr>
            <th class="left">Frais Additionnel</th>
            <th>Description</th>
            <th>Total</th>
        </tr>
        <tr>
            <td colspan="3"><div class="divider head-divider"></div></td>
        </tr>
        <ng-container *ngFor="let fraisAdditionnel of  data.fraisAdditionnel">
            <tr>
                <td class="bold">{{ fraisAdditionnel.label }}</td>
                <td class="center semi-bold">{{ fraisAdditionnel.description || '-' }}</td>
                <td class="center semi-bold">{{ fraisAdditionnel.montant }}</td>
            </tr>
            <tr>
                <td colspan="3"><div class="divider body-divider"></div></td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="3" class="right">
                <table class="tb-al-right">
                    <tr>
                        <td class="bold">TOTAL</td>
                        <td class="pl-10 bold total">
                            {{ data.services.fraisAdditionnel | currency:'TND':'DT'}}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <table id="table-remise" class="table mt-30" *ngIf="data.show.remise && data.remise.length">
        <tr>
            <th class="left">Remise</th>
            <th class="right">Total</th>
        </tr>
        <tr>
            <td colspan="2"><div class="divider head-divider"></div></td>
        </tr>
        <ng-container *ngFor="let remise of  data.remise">
            <tr>
                <td class="bold">{{ remise.label }}</td>
                <td class="semi-bold right">{{ remise.montant }}</td>
            </tr>
            <tr>
                <td colspan="2"><div class="divider body-divider"></div></td>
            </tr>
        </ng-container>
        <tr>
            <td colspan="2" class="right">
                <table class="tb-al-right">
                    <tr>
                        <td class="bold">TOTAL</td>
                        <td class="pl-10 bold total">
                            {{ data.services.total.remise | currency:'TND':'DT'}}
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

    <div class="div-btm">
        Merci pour votre fidélité et votre confiance
    </div>
</div>
