import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { PermissionConfig } from "app/core/permission/permissions.config";
import { GlobalService } from "app/global.service";

@Component({
  selector: "app-event-choice",
  templateUrl: "./event-choice.component.html",
  styleUrls: ["./event-choice.component.scss"],
  standalone: false,
})
export class EventChoiceComponent implements OnInit {
  role: any;

  calendarPermissions = PermissionConfig.calendar;
    
  constructor(
    public matDialogRef: MatDialogRef<EventChoiceComponent>,
    private globalService: GlobalService,
  ) {}

  ngOnInit():void {
    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };
    history.pushState(modalState, null);

    this.role = this.globalService.getUser().role;
  }

  ngOnDestroy():void {
    if (window.history.state.modal) {
      history.go(-1);
    }
  }
}
