import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReclamationsService {
  
  private API_URL = environment.uriG + "/api/reclamations";
  
  constructor(
    private http: HttpClient,
  ) {}

  // Submit a new reclamation
  submitReclamation(reclamationData: any): Observable<any> {
    return this.http.post<any>(`${this.API_URL}/fraud-candidate`, { claimObj: reclamationData });
  }

}
