import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-succes-snak-bar",
  templateUrl: "./succes-snak-bar.component.html",
  styleUrls: ["./succes-snak-bar.component.scss"],
  standalone: false,
})
export class SuccesSnakBarComponent implements OnInit {
  
  public confirmMessage: string = "Succès d'opération";

  ngOnInit(): void {}

}
