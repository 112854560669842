
<div class="layout-container">
    <div class="overlay"></div>

    <div id="main">
        <div id="container-1" class="container">
            <!-- NAVBAR: Left -->
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
            <!-- / NAVBAR: Left -->

            <div id="container-2" class="container invisible-buttons">
                <ng-container *ngTemplateOutlet="topNot"></ng-container>

                <ng-container *ngIf="innerWidth > 763">

                    <!-- TOOLBAR: Below fixed -->
                    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
                    <!-- / TOOLBAR: Below fixed -->
                            <div id="container-3" class="container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{
                                suppressScrollX: true,
                                updateOnRouteChange: true
                            }">
                                <!-- CONTENT -->
                                <content></content>
                                <!-- / CONTENT -->
                            </div>
                </ng-container>

                <small-layout *ngIf="innerWidth < 763">
                    <!-- CONTENT -->
                    <div navigation-drawer>

                    </div>

                     <div page-content class="page-content">
                         <content class="svp-content"></content>
                     </div>
                    <!-- / CONTENT -->
                </small-layout>
            </div>
        </div>
    </div>

    <!-- QUICK PANEL -->
    <fuse-sidebar name="quickPanel" position="right" class="quick-panel" >
        <quick-panel style="height: 100%;"></quick-panel>
    </fuse-sidebar>
    <!-- / QUICK PANEL -->

    <!-- ----------------------------------------------------------------------------------------------------- -->
    <!-- @ PARTIALS

         TOOLBAR -->
    <ng-template #topNot>
        <top-notification [@slideInOut] (close)="setNotToDefault()" *ngIf="topNotConfig && topNotConfig.show" [action]="topNotConfig.action" [message]="topNotConfig.message" >
        </top-notification>
    </ng-template>

    <!-- / TOOLBAR -->
    

    <ng-template #toolbar>
        <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden" [ngClass]="[
                fuseConfig.layout.toolbar.position,
                fuseConfig.layout.toolbar.background
            ]" style="z-index: 1 !important;">
        </toolbar>
        <app-pop-up class="app-pop-up"></app-pop-up>
    </ng-template>
    <!-- / TOOLBAR -->

    <!-- LEFT NAVBAR -->
    <ng-template #leftNavbar>
            <ng-container *ngIf="(!editingEvent || innerWidth < 1099)">
            <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded"
            lockedOpen="gt-md" *ngIf="!fuseConfig.layout.navbar.hidden">
            <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"
            [ngClass]="fuseConfig.layout.navbar.background"></navbar>
            </fuse-sidebar>
        </ng-container>
    </ng-template>
    <!-- / LEFT NAVBAR -->
</div>

<app-paiement-print class="printer" [data]="printerData" *ngIf="printer === 'pp'"></app-paiement-print>
<app-print class="printer" [data]="printerData" *ngIf="printer === 'p'"></app-print>
<app-planning-print class="printer" [data]="printerData" *ngIf="printer === 'ppl'"></app-planning-print>
<agency-invoice-print *ngIf="printer === 'aip'" class="printer" [data]="printerData"></agency-invoice-print>
<recu-agence-payment size="A5" *ngIf="printer === 'par'" class="printer print-recu" [data]="printerData"></recu-agence-payment>