import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import { NavbarService } from "./layout/components/navbar/navbar.service";
import { BehaviorSubject, take,  Subject } from "rxjs";
import { Injectable } from "@angular/core";
import io from "socket.io-client";
import { SwPush } from "@angular/service-worker";
import { ExamNotificationDialogComponent } 
  from "./main/plus/components/exam-notification-dialog/exam-notification-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PopupDialogQueueService } from "./popup-dialog-queue.service";
import { AppUpdateDialogComponent } from "./main/plus/components/app-update-dialog/app-update-dialog.component";
import { AppUpdateService } from "./main/plus/components/app-update-dialog/app-update.service";

@Injectable({
  providedIn: "root",
})
export class RealTimeService {

  r = Math.random();

  j = 0;

  readonly VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;

  socket;

  //* if we are in production but still in localhost
  urlSocket = !location.origin.includes("localhost") ?  environment.urlSocket : "http://localhost:3005/Agence";

  pushSubscription = null;

  urlRealTime = environment.urlRealTime + "/account/";

  uri = environment.uriG;

  ip = "undefined";

  timer = null;

  id = null;

  agenceId = null;

  dialogRef: any;

  notifications = [];

  realTimeFollowUps: Subject<any> = new Subject<any>();

  realTimaQuestion: Subject<any> = new Subject<any>();

  realTimaNotification: Subject<any> = new Subject<any>();

  private exam_list_notifications:  BehaviorSubject<any> = new BehaviorSubject<any>([]);


  constructor(
    private _appUpdateService: AppUpdateService,
    private navbarService: NavbarService,
    private swPush: SwPush,
    private http: HttpClient,
    private _matDialog: MatDialog,
    private router: Router,
    private _popupDialogService: PopupDialogQueueService
  ) {


  }



  get examListNotifications() :any {
    return this.exam_list_notifications.asObservable();
  }

  notificationOpened(id) :any {
    console.log("notification is opened", id);
    return this.http.put<any>(
      `${this.uri}/api/notifications/opened/`,
      { ids:id },
    );
  }

  notificationOpenedAndDelivered(id) :any {
    console.log("notification is opened and delivered", id);
    return this.http.put<any>(
      `${this.uri}/api/notifications/opened/${id}`, {}
    );
  }

  notificationDelivered(id) :any {
    console.log("notification is delivered", id);
    return this.http.put<any>(
      `${this.uri}/api/notifications/delivered/${id}`, {}
    );
  }
    
  loggedIn() :any {
    return !!localStorage.getItem("token");

  }


  resetExamListNotification() :any {
    const notificationIds = this.exam_list_notifications.value.map(notification => notification.notification_id);
    this.notificationOpened(notificationIds).subscribe(() => {
      console.log("notification.notification_id set to opned", notificationIds);  
      this.exam_list_notifications.next([]);
    });

  }

  if2NotificationIsTheSame(notification1, notification2): boolean {
    const notificationWithoutId1 = { ...notification1 };
    const notificationWithoutId2 = { ...notification2 };

    delete notificationWithoutId1.notification_id;
    delete notificationWithoutId2.notification_id;

    return JSON.stringify(notificationWithoutId1) === JSON.stringify(notificationWithoutId2);
  }

  addExamNotificationToExamDialog(notification) :any {
    if (notification) {
      const currentUrl = this.router.url; // Get the current URL

      if (
        currentUrl !== "/nointernet" &&
                currentUrl !== "/auth/blocked" &&
                this.loggedIn()
      ) {
        console.log("notification received");
        
        const exam_list_id = notification.contentId;
        if (exam_list_id) {
          this.getExamNotificationDetails(exam_list_id).subscribe((data: any) => {
            if (data.data) {
              const notificationExists = this.exam_list_notifications.value.some(
                existingNotification => this.if2NotificationIsTheSame(existingNotification, data.data),
              );
            
              let newExamListNotifications;
            
              if (!notificationExists) {
                const newNotification = { ...data.data, notification_id: notification.id };
                newExamListNotifications = this.exam_list_notifications.value.concat([newNotification]);
              } else {
                newExamListNotifications = [...this.exam_list_notifications.value];
              }
            
              this.exam_list_notifications.next(newExamListNotifications);
              this.openExamNotificationDialog();
            }
          });
        }
      }
                
    }
        
  }

  appUpdateNotification(notification) :any {
    const currentUrl = this.router.url; // Get the current URL
      if (
        currentUrl !== "/nointernet" &&
                currentUrl !== "/auth/blocked" &&
                this.loggedIn()
      ) {
        console.log("app update notification received");
      
        setTimeout(() => {
          // check if the app update dialog is already open or waiting in the queue to be opened
          // if not, insert it into the queue
          const isDialogQueuedOrOpen = this._popupDialogService.queue
          .some(dialog => dialog.component === AppUpdateDialogComponent) ||
          this._matDialog.openDialogs
            .some(dialogRef => dialogRef.componentInstance instanceof AppUpdateDialogComponent);
          if (!isDialogQueuedOrOpen) {
    
            console.log("app update dialog opened", notification);
            

            this._popupDialogService.openDialog(AppUpdateDialogComponent, {
              panelClass: ["common-dialog-style", "common-dialog-large-style"],
              disableClose: true,
              data: {
                ...notification.data.description,
                notificationId: notification.data.notificationId,
                id: notification.data.id,
              }
            })
          }
        }, 2000);

        
      }
  }

  openExamNotificationDialog() :any {
    setTimeout(() => {
      const isDialogQueuedOrOpen = this._popupDialogService.queue
      .some(dialog => dialog.component === ExamNotificationDialogComponent) ||
      this._matDialog.openDialogs
        .some(dialogRef => dialogRef.componentInstance instanceof ExamNotificationDialogComponent);
      if (!isDialogQueuedOrOpen) {

        this._popupDialogService.openDialog(ExamNotificationDialogComponent, {
          panelClass: ["common-dialog-style"],
          data: {
          },
          width:"800px",
          disableClose: true,
        },
        () => {
          this.resetExamListNotification();
        })
      }
    }, 2000);

  }

  getExamNotificationDetails(exam_list_id) :any {
    return this.http.get(`${this.uri}/api/centers-settings/exam-list-center-details/${exam_list_id}`);
  }

    

  connectSocket(id, agenceId, role) :any {
    if (environment.production) {
      this.id = id;
      this.agenceId = agenceId;
      if (!this.socket) {
        this.socket = io(this.urlSocket, {
          secure:true,
          rejectUnauthorized: false,
          query: {
            "userId":id,
            "agenceId":agenceId,
            "role":role,   
          },
        });

        this.socket.on("msg", () => {
        });

        this.socket.on("notification", () => {
          console.log("notification");
        });

        // ? listen to question Notifications
        this.socket.on("follow-up", (followUP) => {
          this.realTimeFollowUps.next(followUP);
        });

        // ? listen to question Notifications
        this.socket.on("question", (question) => {
          let x = this.navbarService.getBadgeCount("Candidat-Code");
          x++;
          this.navbarService.setBadgeValue("Candidat-Code", {
            title: "" + x,
            fg: "white",
            bg: "red",
          });
          this.navbarService.setChildBadgeValue(
            "Candidat-Code",
            "Candidat-Questions",
            {
              title: "" + x,
              fg: "white",
              bg: "red",
            },
          );
          this.realTimaQuestion.next(question);
        });

        // ? listen to other Notifications
        this.socket.on("notification-ready", (notification) => {
          this.realTimaNotification.next(notification);
          if (notification.contentTable === "exam_list") {
            this.addExamNotificationToExamDialog(notification);
          } else if(notification.contentTable === "app_update") {



            this._appUpdateService.getAppUpdateById(notification.contentId).subscribe((appUpdate) => {

              console.log("appUpdate", appUpdate);
              
              this._popupDialogService.openDialog(AppUpdateDialogComponent, {
                panelClass: ["common-dialog-style", "common-dialog-large-style"],
                disableClose: true,
                data: {
                  ...appUpdate.data.description,
                      notificationId: appUpdate.data.notificationId,
                      id: appUpdate.data.id,
                }
              });
            });
          }
        });
      }
      this.startPing(id, agenceId);
      this.loadIp();
    }
  }

  disconnectNotification() :any {
    this.id = null;
    this.agenceId = null;
    this.disconnectSocket();
    this.unsubscribeFromPushNotification();
    this.stopPing();
  }

  disconnectSocket() :any {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  getRealTimeQuestion() :any {
    return this.realTimaQuestion.asObservable();
  }

  getRealTimeFollowUps() :any {
    return this.realTimeFollowUps.asObservable();
  }

  getRealTimeNotification() :any {
    return this.realTimaNotification.asObservable();
  }

  subscribeToNotifications(compte_id) :any {

    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY,
    })
      .then(sub => {
        const subscription = { compte_id:compte_id, subscription:sub };
        this.pushSubscription = subscription;

        this.addPushSubscriber(subscription).subscribe(res=>{
          console.log("swPushres", res);

          this.swPush.notificationClicks.subscribe( arg => {
            console.log("swPusharg", arg);
          });
        });
      },
      )
      .catch(err => {console.log("swpush", err);});
  }

  //? NOTIFICATION SUBS
  unsubscribeFromPushNotification() :any {
    if (this.pushSubscription) {
      console.log(this.pushSubscription.subscription.endpoint);

      this.http.delete(`${this.urlRealTime}pushservice`, 
        { params: { endpointUrl:this.pushSubscription.subscription.endpoint } })
        .subscribe({ 
          next: () => {},
          error:(err)=>{
            console.error("Session destroy error:", err);
          } });
    }
  }


    

  addPushSubscriber(e) :any {
    return this.http.post(`${this.urlRealTime}pushservice`, e);
  }

  //? SESSION 

  loadIp() :any {
    this.http.get("https://api.ipify.org/?format=json")
      .subscribe({ 
        next:(response:any) => {
          this.ip = response.ip;     
        }, error:(err) => {
          console.error("Session destroy error:", err);
          return;
        } });
  }
      
  ping(id, agenceId) :any {
    this.http.post(`${this.urlRealTime}session-ping/${id}/${this.ip}/${agenceId}`, {}, { withCredentials: true })
      .pipe(take(1))
      .subscribe({
        next: () => {
          // Handle success if needed
        },
        error: (err) => {
          console.error("Session destroy error:", err);
        },
      });
  }
    
  startPing(id, agenceId) :void {
    setTimeout(()=>{this.ping(id, agenceId);}, 2000) ;
    this.timer = setInterval(()=>{this.ping(id, agenceId);}, 50000);
  }
    
  stopPing() :any {

    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      //! if there is more then one active tab just activeTab--
      this.http.post(`${this.urlRealTime}session-destroy`, {}, { withCredentials: true })
        .pipe(take(1))
        .subscribe({
          next: () => {
            // Handle success if needed
          },
          error: (err) => {
            console.error("Session destroy error:", err);
          },
        });
    }
  }

  holdPing() :void {
    clearInterval(this.timer);
    this.timer = null;
  }
    
  resumePinging() :void {

    if (!this.timer && this.id) {
      this.startPing(this.id, this.agenceId);
    }
  }    

}
