import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SetLocationComponent } from "./set-location.component";
import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { GoogleMapsModule } from "@angular/google-maps";

import { FuseSharedModule } from "@fuse/shared.module";


@NgModule({
  declarations: [
    SetLocationComponent,
  ],
  imports: [
    CommonModule,
    FuseSharedModule,
    MatSelectModule,
    MatInputModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    GoogleMapsModule,
  ],
  exports: [SetLocationComponent],
})
export class SetLocationModule {}
