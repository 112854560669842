import { PrintService } from "./../../../layout/vertical/layout-1/print.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";

@Component({
  selector: "app-paiement-popup",
  templateUrl: "./paiement-popup.component.html",
  styleUrls: ["./paiement-popup.component.scss"],
  standalone: false,
})
export class PaiementPopupComponent implements OnInit {
  selectedAll = false;

  constructor(
    public dialogRef: MatDialogRef<PaiementPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _printService: PrintService,
  ) {
    this.dialogRef.disableClose = true;


  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onYesClick(): void {
    const result = [];
    (this.data.Paiement as any[]).forEach((element) => {
      if (element.selected) {
        result.push({
          date: element.date,
          montant: element.montant,
        });
      }
    });
    this.dialogRef.close(result);
    this._printService.printPaiement({
      AgenceName: this.data.AgenceName,
      AgenceTel: this.data.AgenceTel,
      Condidat: this.data.Condidat,
      Paiement: result,
    });
  }

  selecteAll(): void {
    (this.data.Paiement as any[]).forEach((element) => {
      element.selected = this.selectedAll;
    });
  }

  verifAll(): void {
    let b = true;
    try {
      (this.data.Paiement as any[]).forEach((element) => {
        if (!element.selected) {
          b = false;
          throw new Error;
        }
      });
    } catch (e) {console.error(e);}
    this.selectedAll = b;
  }

  ngOnInit(): void {}
}
