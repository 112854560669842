import { Directive, ElementRef, Input, Optional, Renderer2, TemplateRef, ViewChild, ViewContainerRef }
  from "@angular/core";
import { NgControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { GlobalService } from "app/global.service";

/**
 * Directive to control access to UI elements based on user permissions
 */
@Directive({
  selector: "[accessControl]",
  standalone: false,
})
export class RoleAccessControlDirective  {
  // List of permissions
  private accessList: any[] = [];

  // List of Material components
  private materialComponents = [
    "matInput",
    "textarea",
    "input",
    "mat-list",
    "mat-option",
    "mat-checkbox",
    "mat-radio",
    "mat-select",
    "mat-button",
    "mat-card",
    "mat-radio-group",
  ];

  // Flag to track if view has been created
  private hasView = false;

  @ViewChild("select") matSelect!: MatSelect;

  constructor(
    private globalService: GlobalService,
    private renderer: Renderer2,
    private el: ElementRef,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    @Optional() private ngControl: NgControl,
  ) {
    // Initialize access list from global service
    this.accessList = this.globalService.getPermission();
  }

  /**
   * Controls the access to UI elements based on user permissions
   * @param actionPermission - The name of the access permission to check
   * 
   * This setter handles two main cases:
   * 1. For Material components: Disables them when access is not granted
   * 2. For non-Material components: Hides them when access is not granted
   */
  @Input() set accessControl(combo: any) {
    try {
      
      if (!combo) {
        this.viewContainer.clear();
        return;
      }
      
      let permissions = combo.permissions;
      const oneIsTrue = combo.oneIsTrue;

      // Initialize access based on oneIsTrue flag
      let hasAccess = !oneIsTrue;

      if (Array.isArray(permissions)) {
        permissions.forEach(perm => {
          // Find matching permission in access list
          const action = this.accessList?.find(obj => 
            obj && obj.name === perm?.resourceName && 
            obj.action_name === perm?.actionName,
          );

          // Update access based on oneIsTrue flag
          if (oneIsTrue) {
            hasAccess = hasAccess || (action?.access_granted ?? false);
          } else {
            hasAccess = hasAccess && (action?.access_granted ?? false);
          }
        });
      } else if (permissions) {
        // Handle single permission object
        const action = this.accessList?.find(obj => 
          obj && obj.name === permissions.resourceName && 
          obj.action_name === permissions.actionName,
        );
        hasAccess = action?.access_granted ?? false;
      }

      // Only proceed if view hasn't been created yet
      if (!this.hasView) {
        // Create the embedded view from the template
        const view = this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
        // Handle null or undefined input
        if (combo?.code_only_access == true)  {
          return;
        }
        // Get the root element
        const element = view.rootNodes[0];
        if (!element) return;

        // Determine if the element is a Material component
        const elementTag = element.tagName?.toLowerCase();
        const isMaterialComponent = this.materialComponents.some(component => 
          elementTag?.includes(component),
        );
        const isStandardInput = elementTag === "input" || elementTag === "textarea";

        if (!hasAccess) {
          if (isMaterialComponent || isStandardInput) {
            // AGGRESSIVE DISABLING APPROACH
            
            // 1. Use multiple renderer methods
            this.renderer.setProperty(element, "disabled", true);
            this.renderer.setProperty(element, "readonly", true);
            this.renderer.setAttribute(element, "aria-disabled", "true");
            this.renderer.setAttribute(element, "disabled", "true");
            this.renderer.setAttribute(element, "readonly", "true");
            
            // 2. Apply CSS that can't be overridden
            this.renderer.setStyle(element, "pointer-events", "none !important");
            this.renderer.setStyle(element, "cursor", "not-allowed !important");
            this.renderer.setStyle(element, "opacity", "0.6 !important");
            this.renderer.setStyle(element, "user-select", "none !important");
            this.renderer.setStyle(element, "-webkit-user-select", "none !important");
            this.renderer.setStyle(element, "-moz-user-select", "none !important");
            this.renderer.setStyle(element, "-ms-user-select", "none !important");
            
            // 3. Add a transparent overlay to block interactions
            const overlay = this.renderer.createElement('div');
            this.renderer.setStyle(overlay, 'position', 'absolute');
            this.renderer.setStyle(overlay, 'top', '0');
            this.renderer.setStyle(overlay, 'left', '0');
            this.renderer.setStyle(overlay, 'width', '100%');
            this.renderer.setStyle(overlay, 'height', '100%');
            this.renderer.setStyle(overlay, 'z-index', '1000');
            this.renderer.setStyle(overlay, 'background-color', 'transparent');
            
            // Get the element's position style
            const position = window.getComputedStyle(element).position;
            
            // If the element isn't positioned, make it relative
            if (position === 'static') {
              this.renderer.setStyle(element, 'position', 'relative');
            }
            
            // Add the overlay as a child of the element
            this.renderer.appendChild(element, overlay);
            
            // 4. Direct property manipulation
            if (element.disabled !== undefined) {
              element.disabled = true;
            }
            
            // 5. For Angular Material components
            if (element._disabled !== undefined) {
              element._disabled = true;
            }
            // 6. For select elements
            if (elementTag === 'mat-select' || element.classList?.contains('mat-select')) {
              // Force update the disabled state for mat-select
              setTimeout(() => {
                this.renderer.setProperty(element, "disabled", true);
                if (element._elementRef?.nativeElement) {
                  this.renderer.setProperty(element._elementRef.nativeElement, "disabled", true);
                  this.renderer.setAttribute(element._elementRef.nativeElement, "disabled", "true");
                }
                
                // Try to access and disable the MatSelect instance
                if (element._control) {
                  element._control.disabled = true;
                }
                
                // Disable all child options
                const options = element.querySelectorAll('mat-option');
                options.forEach((option: any) => {
                  this.renderer.setProperty(option, "disabled", true);
                  this.renderer.setAttribute(option, "disabled", "true");
                });
              });
              
              // Additional Angular Material specific handling for mat-select
              try {
                // Find the parent form-field if it exists
                let parentFormField = element.closest ? element.closest('mat-form-field') : null;
                if (!parentFormField && element.parentElement) {
                  // Try to find it by traversing up
                  let parent = element.parentElement;
                  while (parent && !parentFormField) {
                    if (parent.tagName && parent.tagName.toLowerCase() === 'mat-form-field') {
                      parentFormField = parent;
                    }
                    parent = parent.parentElement;
                  }
                }
                
                if (parentFormField) {
                  // Add disabled class to the form field
                  this.renderer.addClass(parentFormField, 'mat-form-field-disabled');
                  
                  // Find and disable the form field wrapper
                  const formFieldInfix = parentFormField.querySelector('.mat-mdc-form-field-infix');
                  if (formFieldInfix) {
                    this.renderer.setStyle(formFieldInfix, 'pointer-events', 'none !important');
                  }

                  // Find and disable the form field wrapper
                  const formFieldWrapper = parentFormField.querySelector('.mat-mdc-text-field-wrapper');
                  if (formFieldWrapper) {
                    this.renderer.setStyle(formFieldWrapper, 'pointer-events', 'none !important');
                    formFieldWrapper.setAttribute('disabled', 'true');
                    formFieldWrapper.classList.add('disabled');
                    formFieldWrapper.style.pointerEvents = 'none';
                  }

                  // Disable the dropdown arrow
                  const matSelectArrow = parentFormField.querySelector('.mat-mdc-select-arrow');
                  if (matSelectArrow) {
                    this.renderer.setStyle(matSelectArrow, 'pointer-events', 'none !important');
                    this.renderer.setStyle(matSelectArrow, 'opacity', '0.6 !important');
                  }
                }
                
                // Try to access Angular Material's internal components
                if (element._valueChange && element._valueChange.observers) {
                  // Prevent value changes by removing all observers
                  element._valueChange.observers = [];
                }
                
                if (element.panelOpen) {
                  // Close the panel if it's open
                  element.close();
                }
                
                // Override open method
                if (element.open && typeof element.open === 'function') {
                  const originalOpen = element.open;
                  element.open = function() {
                    // Do nothing - prevent opening
                    return false;
                  };
                }
                
                // Override toggle method
                if (element.toggle && typeof element.toggle === 'function') {
                  const originalToggle = element.toggle;
                  element.toggle = function() {
                    // Do nothing - prevent toggling
                    return false;
                  };
                }
                
                // Add click interceptor to prevent opening
                element.addEventListener('click', (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return false;
                }, true);
                
                // If there's a trigger element, disable it too
                if (element._trigger && element._trigger.nativeElement) {
                  this.renderer.setProperty(element._trigger.nativeElement, "disabled", true);
                  this.renderer.setAttribute(element._trigger.nativeElement, "disabled", "true");
                  this.renderer.setStyle(element._trigger.nativeElement, "pointer-events", "none !important");
                }
              } catch (error) {
                console.error("Error in mat-select specific handling:", error);
              }
            }
            
            // 7. For buttons
            if (elementTag === 'button' || elementTag === 'mat-button' || element.classList?.contains('mat-button')) {
              // Add event listeners to prevent clicks
              const preventClick = (event: Event) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
              };
              
              element.addEventListener('click', preventClick, true);
              element.addEventListener('mousedown', preventClick, true);
              element.addEventListener('touchstart', preventClick, true);
            }

            // 8. Handle ngModel if present
            if (this.ngControl) {
              // Disable with options to prevent events
              this.ngControl.control?.disable({emitEvent: false, onlySelf: true});
              
              // Force disable the control
              if (this.ngControl.control) {
                // Apply multiple disable calls
                this.ngControl.control.disable();
                
                // Set internal properties
                if (this.ngControl.control['_disabled'] !== undefined) {
                  this.ngControl.control['_disabled'] = true;
                }
                
                // Ensure the disabled state is maintained after Angular's change detection
                setTimeout(() => {
                  if (this.ngControl && this.ngControl.control) {
                    this.ngControl.control.disable({emitEvent: false, onlySelf: true});
                  }
                });
              }
            }
            
            // 9. Add a MutationObserver to ensure the element stays disabled
            const observer = new MutationObserver((mutations) => {
              mutations.forEach((mutation) => {
                if (mutation.attributeName === 'disabled' || 
                    mutation.attributeName === 'readonly' || 
                    mutation.attributeName === 'aria-disabled') {
                  const target = mutation.target as HTMLElement;
                  if (!target.hasAttribute('disabled') || 
                      target.getAttribute('disabled') !== 'true') {
                    this.renderer.setAttribute(target, 'disabled', 'true');
                  }
                  if (!target.hasAttribute('readonly') || 
                      target.getAttribute('readonly') !== 'true') {
                    this.renderer.setAttribute(target, 'readonly', 'true');
                  }
                  if (!target.hasAttribute('aria-disabled') || 
                      target.getAttribute('aria-disabled') !== 'true') {
                    this.renderer.setAttribute(target, 'aria-disabled', 'true');
                  }
                }
              });
            });
            
            observer.observe(element, { attributes: true });
          } else {
            // Hide non-Material components
            this.viewContainer.clear();
          }
        }
      }
    } catch (error) {
      console.error("Error in RoleAccessControlDirective:", error);
      // Fallback to hiding the element on error
      this.viewContainer.clear();
    }
  }
}
