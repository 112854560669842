import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {  map, forkJoin } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventLoadingService {
  uri = environment.uriG + ""; //"http://localhost:3000";

  constructor(
    private http: HttpClient,
  ) { }


  getEvent(id):any {
    return this.http.get<any>(`${this.uri}/api/calendar/one/${id}`);
  }

  getCandidatCodeEx():any {
    return this.http.get<any[]>(`${this.uri}/api/candidats/min`).pipe(
      map((elems) => {
        const arr = [];
        //? arr array qui contient les candidat allégible pour planifier un ex code
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].phase === "code" && !elems[i].dateExCo) {
            elems[i].username =
                            elems[i].prenom + " " + elems[i].nom;
            arr.push(elems[i]);
          }
        }
        return arr;
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getCandidatCodeLess() {
    return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
      map((elems) => {
        const arr = [];
        //? arr array qui contient les candidat allégible pour planifier un ex code
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].phase === "code") {
            elems[i].username =
                            elems[i].prenom + " " + elems[i].nom;
            arr.push(elems[i]);
          }
        }
        return arr;
      }),
    );
  }

  getCandidatConduiteEx():any {
    return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
      map((elems) => {
        const arr = [];
        //? arr array qui contient les candidat allégible pour planifier un ex code
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].phase === "conduite" 
                //! is not in phase circuit and already has exam
                && (((elems[i].cond_type === "circuit" || !elems[i].cond_type) && !elems[i].dateExCon) 
                //! Parc and doesnt have date for parc
                    || (elems[i].cond_type === "parc" && !elems[i].dateExParc)) ) {
            elems[i].username =
                            elems[i].prenom + " " + elems[i].nom;
            arr.push(elems[i]);
          }
        }
        return arr;
      }),
    );
  }

  getOneCandidat(id):any {
    return this.http.get<any>(`${this.uri}/api/candidats/${id}`).pipe(
      map((elem) => {
        if (elem.archived) {
          elem.username = "Candidat supprimé";
          return [elem];
        } else {
          elem.username = elem.prenom + " " + elem.nom;
          return [elem];
        }
      }),
    );
  }

  getOneCandidatMin(id):any {
    return this.http.get<any>(`${this.uri}/api/candidats/min/${id}`).pipe(
      map((elem) => {
        if (elem.archived) {
          elem.username = "Candidat supprimé";
          return [elem];
        } else {
          elem.username = elem.prenom + " " + elem.nom;
          return [elem];
        }
      }),
    );
  }

  getCandidat():any {
    return this.http.get<any>(`${this.uri}/api/candidats/min`).pipe(
      map((elems) => {
        elems.forEach((elem) => {
          elem.username = elem.prenom + " " + elem.nom;
        });
        return elems;
      }),
    );
  }

  getVehicules():any {
    return this.http.get<any>(`${this.uri}/api/vehicles`);
  }

  getCenters():any {
    return this.http.get<any>(`${this.uri}/api/agences/centers`);
  }

  getCentersCode():any {
    return this.http.get<any>(`${this.uri}/api/agences/centers/code`);
  }

  getCentersConduite():any {
    return this.http.get<any>(`${this.uri}/api/agences/centers/conduite`);
  }

  getTarif():any {
    return this.http.get<any>(`${this.uri}/api/agences/tarif`);
  }

  getAllActiveAgents():any {
    return this.http.get<any>(`${this.uri}/api/employees/min`).pipe(
      map((elems) => {
        console.log("elems", elems);
        elems.forEach(
          (elem) => (elem.username = elem.prenom + " " + elem.nom),
        );
        return elems.filter(elem => !elem.archived && elem.active);
      }),
    );
  }

  getAgents():any {
    return this.http.get<any>(`${this.uri}/api/employees/min`).pipe(
      map((elems) => {
                
        elems = elems.filter((elem) => 
          (
            elem.role !== "Agent bureau" 
          ),
        );
        return elems;
      }),
      map((elems) => {
        elems.forEach(
          (elem) => (elem.username = elem.prenom + " " + elem.nom),
        );
        return elems;
      }),
    );
  }

  //? used in edit exam conduite
  getAgents0Vehicles1Centers2ConduiteEx():any {
    return forkJoin([
      this.getAgents(),
      this.getVehicules(),
      this.getCentersConduite(),
    ]);

  }

  //? used in edit less conduite
  getAgents0Vehicles1Centers2ConduiteLess():any {
    return forkJoin([
      this.getAgents(),
      this.getVehicules(),
      this.getDefaultBlockedTime("exam_cond_duration"),        
    ]);
  }

  //? used in plan examen conduite
  getAgents0Vehicles1Cands2Centers3Tarif4BlockedMinutes5ConduiteEx():any {

    return forkJoin([
      this.getAgents(),
      this.getVehicules(),
      this.getCandidatConduiteEx(),
      this.getCentersConduite(),
      this.getTarif(),
      this.getDefaultBlockedTime("exam_cond_duration"),        
    ],
    );

  }

  //? Used in plan Less Conduite
  getAgents0Vehicles1Cands2BlockedMinutes3ConduiteLess():any {

    return forkJoin([
      this.getAgents(),
      this.getVehicules(),
      this.getCandidat(),
      this.getDefaultBlockedTime("less_cond_duration"),        
    ],
    );

  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getDefaultBlockedTime(type) {
    return this.http.get<any>(`${this.uri}/api/calendar/schedule-settings`)
      .pipe(
        map(e=>e[type]));
  }
}
