import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDialogModule } from "@angular/material/dialog";
import { FuseSharedModule } from "@fuse/shared.module";
import { ToolbarComponent } from "app/layout/components/toolbar/toolbar.component";
import { MatBadgeModule } from "@angular/material/badge";
import { DirectivesModule } from "app/helper/directives/directives.module";

@NgModule({
  declarations: [ToolbarComponent],
  imports: [
    MatBadgeModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatDialogModule,
    FuseSharedModule,
    DirectivesModule,
        
  ],
  exports: [ToolbarComponent],
})
export class ToolbarModule {}
