import { ErreurService } from "./../../../authentification/erreur.service";
import { CandidatService } from "./../../candidats/candidat/candidat.service";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject } from "@angular/core";
import { SuccesSnakBarComponent } from "../../../components/succes-snak-bar/succes-snak-bar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
@Component({
  selector: "app-exam-result",
  templateUrl: "./exam-result.component.html",
  styleUrls: ["./exam-result.component.scss"],
  standalone: false,
})
export class ExamResultComponent implements OnInit {
  note = 25;

  candidatId: any;

  //?booleans
  reussite = true;

  typeDePermis = null;

  nouvelleTentative = true;

  tentativeConduite = true;

  options = [
    { option: "Réussite", value: true },
    { option: "Echec", value: false },
  ];

  constructor(
    private errService: ErreurService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public _matDialogRef: MatDialogRef<ExamResultComponent>,
    public candService: CandidatService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.candidatId = _data.candidatId;
  }

  ngOnInit():void {
    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };
    history.pushState(modalState, null);

    this.typeDePermis = this._data.typeDePermis;
  }

  ngOnDestroy():void {
    if (window.history.state.modal) {
      history.go(-1);
    }
  }

  register():void {
    if (!this.reussite) {
      //&& this.nouvelleTentative) {
      this.candService.echecCoNouv(this.candidatId, this.note).subscribe({
        next:(res) => {
          if (res !== null) {
            this._matDialogRef.close({
              note: this.note,
              success: this.reussite,
            });
            this.snackBar.openFromComponent(
              SuccesSnakBarComponent,
              {
                duration: 3000,
              },
            );
          }
          //
        },
        error:(err) => {
          this.errService.popError(
            err,
            "Erreur s'est produit lors d'ajout. \n Essayez plus tard",
          );
        },
      },
      );
    } else {
      this.candService.succCoNouv(this.candidatId, this.note).subscribe({
        next:(res) => {
          if (res !== null) {
            this._matDialogRef.close({
              note: this.note,
              success: this.reussite,
            }); //
            this.snackBar.openFromComponent(
              SuccesSnakBarComponent,
              {
                duration: 3000,
              },
            );
          }
        },
        error:(err) => {
          this.errService.popError(
            err,
            "Erreur s'est produit lors d'ajout. \n Essayez plus tard",
          );
        },
      });
    }
  }

  onNote(event):void {
    console.log(event);

    if (this.note) {
      switch (this.typeDePermis) {
        case "Type C+E":
        case "Type D":
          if (this.note < 30) this.reussite = false;
          else this.reussite = true;
          break;
        case "Type A":
          if (this.note < 24) this.reussite = false;
          else this.reussite = true;
          break;
            
        default:
          if (this.note < 24) this.reussite = false;
          else this.reussite = true;
          break;
      }
    }
  }
}
