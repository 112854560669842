import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "./notification.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "app/main/components/succes-snak-bar/succes-snak-bar.component";
import { GlobalService } from "app/global.service";

// Defines the structure for notification preferences
interface NotificationPreference {
  InApp: boolean;
  Push: boolean;
  Child?: { [key: string]: NotificationPreference };
}

@Component({
  selector: "app-notification-settings-dialog",
  templateUrl: "./notification-settings-dialog.component.html",
  styleUrls: ["./notification-settings-dialog.component.scss"],
  standalone: false,
})
export class NotificationSettingsDialogComponent implements OnInit {
  preferenceForm: FormGroup;

  notificationSettingsForm: FormGroup;

  notificationPreferenceData: { [key: string]: NotificationPreference };

  gestionNotificationPreferenceData: { [key: string]: NotificationPreference };
 
  candidateNotificationPreferenceData: { [key: string]: NotificationPreference };
 
  examsNotificationPreferenceData: { [key: string]: NotificationPreference };
 
  expandedCategories: Set<string> = new Set();

  indeterminateStates: { [key: string]: boolean } = {};

  preferenceData: any;

  isFormReady = false;

  loading = false;

  notificationSettingsInitData: any[];

  notificationPreferenceInitData: any[];

  settingPushIsOff = false;

  settingsIsOn = true;

  user = null;

  permissionList: any = null;

  depensePermissions: any = null;

  recettePermissions: any = null;

  actionName: any = {
    "Ajout": "add",
    "Modification": "edit",
    "Suppression": "delete",
  };

  eventActions: any = {
    "Ajout": "add",
    "Modification": "edit",
    "Suppression": "delete",
    "Confirmation": "confirm",
    "Résultat d'examen": "add-result",
    "Suppression du résultat": "delete-result",
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationSettingsDialogComponent>,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    private _globalService: GlobalService,    
  ) {

    // Initialize notification preference data structures
    this.examsNotificationPreferenceData = {
      "Listes des Examen": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Nouvelle liste": { "InApp": false, "Push": false },
        },
      },
    };
    this.gestionNotificationPreferenceData = {
      "Événement": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Modification": { "InApp": false, "Push": false },
          "Suppression": { "InApp": false, "Push": false },
          "Confirmation": { "InApp": false, "Push": false },
          "Résultat d'examen": { "InApp": false, "Push": false },
          "Suppression du résultat": { "InApp": false, "Push": false },
        },
      },
      "Paiement": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Modification": { "InApp": false, "Push": false },
          "Suppression": { "InApp": false, "Push": false },
        },
      },
      "Dépense": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Modification": { "InApp": false, "Push": false },
          "Suppression": { "InApp": false, "Push": false },
        },
      },
    };

    this.candidateNotificationPreferenceData = {
      "Question": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Message": {
            "InApp": false,
            "Push": false,
          },
        },
      },
      "Reservation": {
        "InApp": false,
        "Push": false,
        "Child": {
          "Ajout": { "InApp": false, "Push": false },
          "Acceptation": { "InApp": false, "Push": false },
          "Refuse": { "InApp": false, "Push": false },
        },
      },
    };
    // Combine all preference data into one object
    this.notificationPreferenceData = {
      ...this.gestionNotificationPreferenceData,
      ...this.candidateNotificationPreferenceData,
      ...this.examsNotificationPreferenceData,
    };
  }

  ngOnInit(): void {
    // Load user data first
    this.user = this._globalService.getUser();
    this.initializePermissions();
    
    // Load preferences and then ensure non-visible items are false
    this.loadPreferences();

  }

  initializePermissions(): any {
    this.permissionList = this._globalService.getPermission();
    this.depensePermissions = this.permissionList.filter((permission) => permission.name === "depense");
    this.recettePermissions = this.permissionList.filter((permission) => permission.name === "recette");
  }

  canReceiveNotification(): any {
    return this.isVisibleGestion() || this.isVisibleForCandidate() || this.isVisibleForExams();
  }

  isVisibleGestion(): any {
    return this.isVisibleForEvent() || this.isVisibleForExpenses() || this.isVisibleForPayment();
  }


  // Visibility methods for each section
  isVisibleForExams(section?: string): boolean {
    if (!section) {
      if (this.user.sub_type === "code_only") {
        return true;
      }
      const examListPermission = this.permissionList.find((permission) => permission.name === "exams-center");
      return examListPermission.access_granted;

    }// For the main section visibility

    if (section === "Ajout") {
      return this.user.role !== "moniteur" && this.user.sub_type !== "code_only";
    }
    if (section === "Nouvelle liste") {
      return this.user.role !== "moniteur";
    }
    return false;
  }

  isVisibleForGestion(): boolean {
    return this.user.sub_type !== "code_only";
  }
  
  isVisibleForExpenses(action?: string): boolean { // for depense
    if (this.user.sub_type === "code_only") {
      return true;
    }
    if (!action) {
      let viewExpensePermission = false;
      const intermediairePermission 
        = this.depensePermissions.find((permission) => permission.action_name == 'view');
      viewExpensePermission = viewExpensePermission || intermediairePermission.access_granted;
      return viewExpensePermission;
    }
    const viewExpensePermission 
      = this.depensePermissions.find((permission) => permission.action_name === this.actionName[action]);
    return viewExpensePermission.access_granted;
  }
  
  isVisibleForPayment(action?: string): boolean {
    if (this.user.sub_type === "code_only") {
      return true;
    }
    if (!action) {
      let viewPaymentPermission = false;
      const intermediairePermission 
        = this.recettePermissions.find((permission) => permission.action_name == 'view');
      viewPaymentPermission = viewPaymentPermission || intermediairePermission?.access_granted;
      return viewPaymentPermission;
    }
    const viewPaymentPermission 
      = this.recettePermissions.find((permission) => permission.action_name === this.actionName[action]);
    return viewPaymentPermission.access_granted;
  }
  
  isVisibleForEvent(action?: string): boolean {
    if ( this.user.sub_type === "code_only" ) return false;
    if (!action) {
      let viewGlobalEventPermission = false;
      viewGlobalEventPermission = this.checkEveryEventPermission('view');
      return viewGlobalEventPermission;
    }
    return this.checkEveryEventPermission(action);

  }

  // for an action (eg, 'Ajout'), it checks all the events that can do this action.
  checkEveryEventPermission(action: any): boolean {
    // code-session, code-exam, conduit-session, conduit-exam
    const sessionEvents = ["code-session", "conduit-session"];
    const examEvents =  ["code-exam", "conduit-exam"];
    const events = [...sessionEvents, ...examEvents];
    let usedList: string[] = [];
    let viewEventPermission = false;
    if (["Suppression du résultat", "Résultat d'examen"].includes(action)) {
      usedList = [...examEvents];
    } else if (action === "Confirmation") {
      usedList = [...sessionEvents];
    } else { 
      usedList = [...events]; 
    }
    for (const event of usedList) {
      const permission = this.permissionList
        .find((permissionElement) => permissionElement.name === event 
      && permissionElement.action_name == 'view');
      viewEventPermission = viewEventPermission || permission?.access_granted;
    }
    if (!viewEventPermission) {
      const occupiedEventList = ['add','edit','delete'];
      occupiedEventList.forEach( event => {
        let occupiedPermission = this.permissionList
        .find((permissionElement) => permissionElement.name == 'occupied-event' && permissionElement.action_name == event );
        viewEventPermission = viewEventPermission || occupiedPermission?.access_granted;
      });
      if (viewEventPermission && ["Confirmation","Suppression du résultat", "Résultat d'examen"].includes(action)) return false;
      else return   true
    }
    return viewEventPermission;
  }

  
  isVisibleForCandidate(section?: string): boolean {
    if (!section) return true; // For the main section visibility
    
    if (section === "Message" ) {
      if (this.user.sub_type !== "code_only" && this.user.role === "moniteur") return false;
    }
    return true;
  }

  isVisibleForQuestion(): boolean {
    if (this.user.sub_type === "code_only") {
      return true;
    }
    const suiviOnlinePermission = this.permissionList.find((permission) => permission.name === "candidat-questions");
    return suiviOnlinePermission.access_granted;



  }
  
  isVisibleForReservation(action?: string): boolean {
    if (!action) {
      if (this.user.sub_type === "code_only") {
        return true;
      }
      const reservationPermission = this.permissionList.find((permission) => permission.name === "reservation");
      return reservationPermission.access_granted;
    }
    
    switch (action) {
      case "Acceptation":
        return this.user.sub_type !== "code_only" && this.user.role !== "moniteur";
      case "Ajout":
        return true;
      case "Refuse":
        return this.user.sub_type !== "code_only" && this.user.role !== "moniteur";
      default:
        return false;
    }
  }

  /**
   * Helper method to set form values while respecting visibility rules
   * If an item is not visible based on user role/type, its value will be forced to false
   * @param category The category name (e.g., 'Question', 'Reservation')
   * @param child Optional child category name
   * @param state The type of notification state ('inApp', 'push', or 'main')
   * @param value The value to set
   */
  setFormValueWithVisibility(
    category: string,
    child: string | null,
    state: "inApp" | "push" | "main",
    value: boolean,
  ): void {
    // Construct the form control key based on whether it's a child or main category
    const formKey = child ? `${category}_${child}_${state}` : `${category}_${state}`;
    
    // Get the appropriate visibility check function for this category
    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    
    // Check visibility based on whether it's a child or main category
    const isVisible = child ? visibilityCheck(child) : visibilityCheck();
    
    // If not visible, force value to false, otherwise use the provided value
    const finalValue = isVisible ? value : false;
    this.preferenceForm.patchValue({ [formKey]: finalValue });
  }

  /**
   * Helper method to get form values while respecting visibility rules
   * Returns false for any item that is not visible based on user role/type
   * @param category The category name
   * @param child Optional child category name
   * @param state The type of notification state ('inApp' or 'push')
   * @returns boolean The effective form value considering visibility rules
   */
  getFormValueWithVisibility(category: string, child: string | null, state: "inApp" | "push"): boolean {
    const formKey = child ? `${category}_${child}_${state}` : `${category}_${state}`;
    const formValue = this.preferenceForm.get(formKey)?.value || false;
    
    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    const isVisible = child ? visibilityCheck(child) : visibilityCheck();
    
    return isVisible ? formValue : false;
  }

  /**
   * Updates the notification preference data object with current form values
   * Ensures all values respect visibility rules before updating
   */
  updateNotificationPreferenceData(): void {
    Object.keys(this.notificationPreferenceData).forEach(category => {
      // Update main category notification states
      this.notificationPreferenceData[category].InApp = this.getFormValueWithVisibility(category, null, "inApp");
      this.notificationPreferenceData[category].Push = this.getFormValueWithVisibility(category, null, "push");

      // Update child category notification states if they exist
      if (this.notificationPreferenceData[category].Child) {
        Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
          this.notificationPreferenceData[category].Child![child].InApp 
          = this.getFormValueWithVisibility(category, child, "inApp");
          this.notificationPreferenceData[category].Child![child].Push 
          = this.getFormValueWithVisibility(category, child, "push");
        });
      }
    });
  }


  generalSettingsChangesHandling(): void {
    const inAppValue = this.notificationSettingsForm.get("in_app")!.value;
    const pushValue = this.notificationSettingsForm.get("push")!.value;
    if (!pushValue) {
      this.settingPushIsOff = true;
    } else {
      this.settingPushIsOff = false;
    }

    if (!inAppValue) {
      this.settingsIsOn = false;
    } else {
      this.settingsIsOn = true;
    }
    
  }


  /**
   * Updates child checkbox state and its indicators
   * @param category The parent category
   * @param child The child category
   */
  updateChild(category: string, child: string): void {
    console.log(child);
    // Update main checkbox state based on all visible children
    this.updateMainStateFromChildState(category);
  }

  /**
   * Updates the main checkbox state for a category
   * Maintains consistency with linked InApp and Push states
   */
  updateMain(category: string): void {
    this.updateNotificationPreferenceData();
    
    // Check if form state has changed from initial state
    if (!this.areObjectsEqual(this.notificationPreferenceInitData, this.notificationPreferenceData) || 
        !this.areObjectsEqual(this.notificationSettingsInitData, this.notificationPreferenceData)) {
      this.loading = true;
    } else {
      this.loading = false;
    }

    const inAppChecked = this.preferenceForm.get(`${category}_inApp`)!.value;
    const pushChecked = this.preferenceForm.get(`${category}_push`)!.value;
    const inAppIndeterminate = this.indeterminateStates[`${category}_inApp`];
    const pushIndeterminate = this.indeterminateStates[`${category}_push`];

    // Update main checkbox state based on InApp and Push states
    if (inAppChecked && pushChecked && !inAppIndeterminate && !pushIndeterminate) {
      this.preferenceForm.patchValue({ [`${category}_main`]: true });
      this.indeterminateStates[`${category}_main`] = false;
    } else if (!inAppChecked && !pushChecked && !inAppIndeterminate && !pushIndeterminate) {
      this.preferenceForm.patchValue({ [`${category}_main`]: false });
      this.indeterminateStates[`${category}_main`] = false;
    } else {
      this.preferenceForm.patchValue({ [`${category}_main`]: false });
      this.indeterminateStates[`${category}_main`] = true;
    }
  }

  /**
   * Handles changes to main category notification state checkboxes
   * Implements the linked behavior between InApp and Push notifications
   * @param category The category being updated
   * @param state The type of notification being changed ('inApp' or 'push')
   * @param checked The new checkbox state
   */
  onChangeMainState(category: string, state: "inApp" | "push", checked: boolean): any {
    // First check if the category is visible
    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    if (!visibilityCheck()) {
      // If category is not visible, force all its values to false
      this.setFormValueWithVisibility(category, null, "inApp", false);
      this.setFormValueWithVisibility(category, null, "push", false);
      return;
    }

    // Handle linked behavior between InApp and Push notifications
    if (state === "inApp" && !checked) {
      // When InApp is turned off, Push must also be turned off
      // because Push notifications require InApp to be enabled
      this.setFormValueWithVisibility(category, null, "inApp", false);
      this.setFormValueWithVisibility(category, null, "push", false);
    } else if (state === "push" && checked) {
      // When Push is turned on, InApp must also be turned on
      // because Push notifications require InApp to be enabled
      this.setFormValueWithVisibility(category, null, "inApp", true);
      this.setFormValueWithVisibility(category, null, "push", true);
    } else {
      // For other cases, just update the specific state
      this.setFormValueWithVisibility(category, null, state, checked);
    }

    // Reset indeterminate state after direct user action
    this.indeterminateStates[`${category}_${state}`] = false;

    // Propagate changes to child categories
    if (this.notificationPreferenceData[category].Child) {
      Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
        this.updateChildStateFromMainState(category, child, state, checked);
      });
    }

    // Update main category state based on new child states
    this.updateMainStateFromChildState(category);
  }

  /**
   * Handles changes to child category notification state checkboxes
   * Implements the linked behavior between InApp and Push notifications at the child level
   * @param category Parent category name
   * @param child Child category name
   * @param state The type of notification being changed ('inApp' or 'push')
   * @param checked The new checkbox state
   */
  onChangeChildState(category: string, child: string, state: "inApp" | "push", checked: boolean): any {
    // First check if the child category is visible
    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    if (!visibilityCheck(child)) {
      // If child is not visible, force all its values to false
      this.setFormValueWithVisibility(category, child, "inApp", false);
      this.setFormValueWithVisibility(category, child, "push", false);
      return;
    }

    // Handle linked behavior between InApp and Push notifications
    if (state === "inApp" && !checked) {
      // When InApp is turned off, Push must also be turned off
      this.setFormValueWithVisibility(category, child, "inApp", false);
      this.setFormValueWithVisibility(category, child, "push", false);
    } else if (state === "push" && checked) {
      // When Push is turned on, InApp must also be turned on
      this.setFormValueWithVisibility(category, child, "inApp", true);
      this.setFormValueWithVisibility(category, child, "push", true);
    } else {
      // For other cases, just update the specific state
      this.setFormValueWithVisibility(category, child, state, checked);
    }

    // Update child checkbox state and propagate changes to parent
    this.updateChild(category, child);
    this.updateMainStateFromChildState(category);
  }
  
  /**
   * Maintains linked behavior between InApp and Push states
   * @param category The parent category name
   * @param child The child category name
   * @param state The type of notification being changed ('inApp' or 'push')
   * @param checked The new checkbox state
   */
  updateChildStateFromMainState(category: string, child: string, state: "inApp" | "push", checked: boolean): void {
    // Apply the same linked behavior rules as the parent
    if (state === "inApp" && !checked) {
      // When parent InApp is turned off, both child InApp and Push must be off
      this.setFormValueWithVisibility(category, child, "inApp", false);
      this.setFormValueWithVisibility(category, child, "push", false);
    } else if (state === "push" && checked) {
      // When parent Push is turned on, both child InApp and Push must be on
      this.setFormValueWithVisibility(category, child, "inApp", true);
      this.setFormValueWithVisibility(category, child, "push", true);
    } else {
      // For other cases, just update the specific state
      this.setFormValueWithVisibility(category, child, state, checked);
    }
    
    // Reset indeterminate state and update child UI
    this.indeterminateStates[`${category}_${child}_${state}`] = false;
    this.updateChild(category, child);
  }

  /**
   * Updates main category state based on child states
   * Ensures parent state correctly reflects children's linked states
   */
  updateMainStateFromChildState(category: string): any {
    if (!this.notificationPreferenceData[category].Child) {
      return;
    }

    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    const visibleChildren = Object.keys(this.notificationPreferenceData[category].Child!)
      .filter(child => visibilityCheck(child));

    if (visibleChildren.length === 0) {
      // If no visible children, set main state to false
      this.setFormValueWithVisibility(category, null, "inApp", false);
      this.setFormValueWithVisibility(category, null, "push", false);
      return;
    }

    // Calculate states for visible children
    const inAppStates = visibleChildren.map(child => 
      this.preferenceForm.get(`${category}_${child}_inApp`)!.value,
    );
    const pushStates = visibleChildren.map(child => 
      this.preferenceForm.get(`${category}_${child}_push`)!.value,
    );

    // Update main category state based on visible children
    const allInAppChecked = inAppStates.every(state => state === true);
    const allPushChecked = pushStates.every(state => state === true);

    this.setFormValueWithVisibility(category, null, "inApp", allInAppChecked);
    this.setFormValueWithVisibility(category, null, "push", allPushChecked);

    // Update indeterminate states
    this.updateIndeterminateState(category);
  }

  /**
   * Updates the indeterminate state for a category based on its visible child items
   * @param category The category to update
   */
  private updateIndeterminateState(category: string): void {
    if (!this.notificationPreferenceData[category].Child) {
      return;
    }

    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    const visibleChildren = Object.keys(this.notificationPreferenceData[category].Child!)
      .filter(child => visibilityCheck(child));

    if (visibleChildren.length === 0) {
      this.indeterminateStates[`${category}_inApp`] = false;
      this.indeterminateStates[`${category}_push`] = false;
      return;
    }

    // Check inApp state
    const inAppStates = visibleChildren.map(child => 
      this.preferenceForm.get(`${category}_${child}_inApp`)!.value,
    );
    const allInAppChecked = inAppStates.every(state => state === true);
    const allInAppUnchecked = inAppStates.every(state => state === false);
    this.indeterminateStates[`${category}_inApp`] = !allInAppChecked && !allInAppUnchecked;

    // Check push state
    const pushStates = visibleChildren.map(child => 
      this.preferenceForm.get(`${category}_${child}_push`)!.value,
    );
    const allPushChecked = pushStates.every(state => state === true);
    const allPushUnchecked = pushStates.every(state => state === false);
    this.indeterminateStates[`${category}_push`] = !allPushChecked && !allPushUnchecked;
  }

  toggleIcon(category: string): void {
    if (this.expandedCategories.has(category)) {
      this.expandedCategories.delete(category);
    } else {
      this.expandedCategories.clear();
      this.expandedCategories.add(category);
    }
  }

  isExpanded(category: string): boolean {
    return this.expandedCategories.has(category);
  }

  onSubmit(): void {
    // Update the object data. 
    this.updateNotificationPreferenceData();
    this.sendPreferencesToServer();
  }

  //Send saved data to server
  sendPreferencesToServer(): void {
    this.loading = false;
    const notificationSettingObj = {
      "notification_settings" : this.notificationSettingsForm.getRawValue(),
      "notification_preference" : this.notificationPreferenceData,
    }; 
    this.notificationService.sendPreferences(notificationSettingObj)
      .subscribe({
        next: (response) => {
          this.snackBar.openFromComponent(SuccesSnakBarComponent, {
            duration: 3000,
          });
          console.log("Preferences sent successfully:", response);
          this.closeDialog();
        },
        error: (error) => {
          console.error("Error sending preferences:", error);
        },
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  areObjectsEqual(obj1, obj2): any {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return obj1 === obj2;
    }
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
      if (!this.areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  } 

  loadPreferences(): void {
    this.notificationService.getPreferences().subscribe({
      next: (data) => {
        this.data = data;
        console.log("Preferences loaded:", this.data );
        this.data.notificationPreferences = this.notificationService.renameFields(this.data.notificationPreferences);
        console.log("renamedData  loaded:", this.data );
        if (this.data) {
          console.log("Using passed data:", this.data);
          this.notificationSettingsInitData = JSON.parse(JSON.stringify(this.data.notificationSettings));
          this.notificationPreferenceInitData = JSON.parse(JSON.stringify(this.data.notificationPreferences));
          this.notificationPreferenceData = JSON.parse(JSON.stringify(this.notificationPreferenceInitData));
          console.log("notificationPreferenceInitData", this.notificationPreferenceInitData);
          this.initFormWithVisibility();
        } else {
          console.warn("No data provided.");
        }

      },
      error: (error) => {
        console.error("Error fetching preferences:", error);
      },
    });
  }

  getVisibilityCheckForCategory(category: string): (action?: string) => boolean {
    switch (category) {
      case "Événement":
        return this.isVisibleForEvent.bind(this);
      case "Dépense":
        return this.isVisibleForExpenses.bind(this);
      case "Paiement":
        return this.isVisibleForPayment.bind(this);
      case "Question":
        return this.isVisibleForCandidate.bind(this);
      case "Reservation":
        return this.isVisibleForReservation.bind(this);
      default:
        return () => true;
    }
  }

  hasVisibleChildItems(category: string): boolean {
    const categoryData = this.notificationPreferenceData[category];
    if (!categoryData?.Child) {
      return false;
    }

    return Object.keys(categoryData.Child).some(childKey => {
      const visibilityCheck = this.getVisibilityCheckForCategory(category);
      return visibilityCheck ? visibilityCheck(childKey) : false;
    });
  }

  /**
   * Checks if a category has only one visible child
   * @param category The category to check
   * @returns boolean indicating if the category has exactly one visible child
   */
  hasOnlyOneVisibleChild(category: string): boolean {
    if (!this.notificationPreferenceData[category]?.Child) {
      return false;
    }
    
    const visibilityCheck = this.getVisibilityCheckForCategory(category);
    const visibleChildren = Object.keys(this.notificationPreferenceData[category].Child!)
      .filter(child => visibilityCheck(child));
    
    return visibleChildren.length === 1;
  }

  /**
   * Checks if category header controls should be shown
   * @param category The category to check
   * @returns boolean indicating if header controls should be shown
   */
  showCategoryHeaderControls(category: string): boolean {
    return !this.hasOnlyOneVisibleChild(category);
  }

  /**
   * Determines if a category should be expanded
   * @param category The category to check
   * @returns boolean indicating if the category should be expanded
   */
  shouldBeExpanded(category: string): boolean {
    return this.hasOnlyOneVisibleChild(category) || this.isExpanded(category);
  }

  /**
   * Initializes the form with visibility rules enforced
   */
  private initFormWithVisibility(): void {
    const group: any = {};
    
    // Initialize form controls with visibility-aware values
    Object.keys(this.notificationPreferenceData).forEach(category => {
      const visibilityCheck = this.getVisibilityCheckForCategory(category);
      const isCategoryVisible = visibilityCheck();
      
      // Initialize main category controls
      group[`${category}_main`] = [isCategoryVisible ? false : false];
      group[`${category}_inApp`] = [isCategoryVisible ? false : false];
      group[`${category}_push`] = [isCategoryVisible ? false : false];
      
      this.indeterminateStates[`${category}_main`] = false;
      this.indeterminateStates[`${category}_inApp`] = false;
      this.indeterminateStates[`${category}_push`] = false;

      // Initialize child category controls
      if (this.notificationPreferenceData[category].Child) {
        Object.keys(this.notificationPreferenceData[category].Child!).forEach(child => {
          const isChildVisible = visibilityCheck(child);
          
          group[`${category}_${child}_main`] = [isChildVisible ? false : false];
          group[`${category}_${child}_inApp`] = [isChildVisible ? false : false];
          group[`${category}_${child}_push`] = [isChildVisible ? false : false];
          
          this.indeterminateStates[`${category}_${child}_main`] = false;
        });
      }
    });

    // Create the form
    this.preferenceForm = this.fb.group(group);

    // Initialize notification settings form
    this.notificationSettingsForm = this.fb.group({
      push: [this.notificationSettingsInitData[0].push_on],
      in_app: [this.notificationSettingsInitData[0].inapp_on],
    });

    // Set up form value change subscriptions
    this.setupFormSubscriptions();

    // Set initial values with visibility rules enforced
    this.setFormValuesWithVisibility(this.notificationPreferenceInitData);

    this.isFormReady = true;
  }

  /**
   * Sets form values while enforcing visibility rules
   */
  private setFormValuesWithVisibility(data: any): void {
    Object.keys(data).forEach(category => {
      const visibilityCheck = this.getVisibilityCheckForCategory(category);
      const isCategoryVisible = visibilityCheck();

      // Set main category values
      const mainInApp = isCategoryVisible ? data[category].InApp : false;
      const mainPush = isCategoryVisible ? data[category].Push : false;
      
      this.setFormValueWithVisibility(category, null, "main", mainInApp && mainPush);
      this.setFormValueWithVisibility(category, null, "inApp", mainInApp);
      this.setFormValueWithVisibility(category, null, "push", mainPush);
      
      this.indeterminateStates[`${category}_main`] = mainInApp !== mainPush;

      // Set child category values
      if (data[category].Child) {
        Object.keys(data[category].Child).forEach(child => {
          const isChildVisible = visibilityCheck(child);
          
          const childInApp = isChildVisible ? data[category].Child![child].InApp : false;
          const childPush = isChildVisible ? data[category].Child![child].Push : false;
          
          this.setFormValueWithVisibility(category, child, "main", childInApp && childPush);
          this.setFormValueWithVisibility(category, child, "inApp", childInApp);
          this.setFormValueWithVisibility(category, child, "push", childPush);
          
          this.indeterminateStates[`${category}_${child}_main`] = childInApp !== childPush;
        });
      }

      this.updateMainStateFromChildState(category);
    });

    // Update notification settings form
    this.notificationSettingsForm.patchValue({
      push: this.notificationSettingsInitData[0].push_on,
      in_app: this.notificationSettingsInitData[0].inapp_on,
    });
  }

  /**
   * Sets up form value change subscriptions
   */
  private setupFormSubscriptions(): void {
    // Listen to 'push' checkbox value changes
    this.notificationSettingsForm.get("push")?.valueChanges.subscribe((pushValue: boolean) => {
      this.loading = true;
      if (pushValue) {
        this.notificationSettingsForm.patchValue({ in_app: true }, { emitEvent: false });
      }
      this.generalSettingsChangesHandling();
    });

    // Listen to 'in_app' checkbox value changes
    this.notificationSettingsForm.get("in_app")?.valueChanges.subscribe((inAppValue: boolean) => {
      this.loading = true;
      if (!inAppValue) {
        this.notificationSettingsForm.patchValue({ push: false }, { emitEvent: false });
      }
      this.generalSettingsChangesHandling();
    });
  }
}