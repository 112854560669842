import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UsefulService {
  uri = "/api/";

  constructor(private http: HttpClient) {}

  getAccounts(): any {
    return this.http.get(`${this.uri}accounts`);
  }
}
