import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {  Subject } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class DepencesService {
  uri = environment.uriG + "";

  uri2 = environment.uriG + "/api/employees";

  addTypeDep: Subject<any>;

  delTypeDep: Subject<any>;

  constructor(private http: HttpClient) {
    this.addTypeDep = new Subject();
    this.delTypeDep = new Subject();
  }

  addTypeDepense(type) :void {
    this.addTypeDep.next(type);
  }

  delTypeDepense(type) :void {
    this.delTypeDep.next(type);
  }

  typeDepadded():any {
    return this.addTypeDep.asObservable();
  }

  typeDepdeleted():any {
    return this.delTypeDep.asObservable();
  }

  getDepenses():any {
    return this.http.get<any>(`${this.uri}/api/finances/depenses`);
  }

  getcurrent():any {
    return this.http.get<any>(`${this.uri2}/current`);
  }

  getTypes() :any {
    return this.http.get<any>(`${this.uri}/api/finances/types`);
  }


  getSpeTypes(famille) :any {
    return this.http.get<any>(`${this.uri}/api/finances/types/${famille}`);
  }

  addType(type) :any {
    return this.http.post<any>(`${this.uri}/api/finances/types`, type);
  }

  deleteType(id) : any {
    return this.http.delete<any>(
      `${this.uri}/api/finances/type/delete/${id}`,
    );
  }

  addDepense(depense) :any {
    return this.http.post<any>(
      `${this.uri}/api/finances/depenses`,
      depense,
    );
  }

  deleteDepense(id) :any {
    return this.http.delete<any>(
      `${this.uri}/api/finances/depenses/delete/${id}`,
    );
  }

  updateDepense(data, id) : any {
    return this.http.put<any>(
      `${this.uri}/api/finances/depenses/update/${id}`,
      data,
    );
  }

  getAllVehicules():any {
    return this.http.get<any>(`${this.uri}/api/vehicles/`);
  }

  getAllRessources():any {
    return this.http.get<any>(`${this.uri}/api/vehicles/ressources`);
  }

  getAllCands():any {
    return this.http.get<any>(`${this.uri}/api/candidats/min`);
  }

  depensePerFamily():any {
    return this.http.get<any>(`${this.uri}/api/finances/depensesperfamily`);
  }
}
