import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RecuAgencePaymentComponent } from "./recu-agence-payment.component";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { RouterModule } from "@angular/router";



@NgModule({
  declarations: [
    RecuAgencePaymentComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
  ],
  exports:[
    RecuAgencePaymentComponent,
  ],
})
export class RecuAgencePaymentModule { }
