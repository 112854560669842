<div id="event-dialog" class="event-form-deep">
    <div *ngIf="_data.available" class="dialog-content-wrapper container-event-form">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <!-- [disabled]= eventForm.disabled 
                    || (!newFormat && this._data.condEx) 
                    || (this._data.fromCand && this._data.condEx) 
                    || (this._data.action == 'edit' && this._data.condEx) -->
                <div fxLayout="row wrap" fxLayoutGap="4px">
                    <span class="title dialog-title">{{ this.title }} {{(this._data.cond_type == 'recyclage') ?
                        'Recyclage' : '' }}</span>
                    <ng-container *ngIf="this._data.condLess && this._data.cond_type != 'recyclage'">
                        <button *accessControl="{ permissions: calendarPermissions[permissionName]}"
                            [disabled]="disable_cond_type" class="cond_type_btn" mat-stroked-button
                            [matMenuTriggerFor]="cond_type">
                            {{this._data.cond_type ? this._data.cond_type : "Conduite" }}
                        </button>
                    </ng-container>

                </div>
                <mat-menu *accessControl="{ permissions: calendarPermissions[permissionName]}" #cond_type="matMenu">
                    <button *ngIf="this._data.cond_type != 'circuit'" mat-menu-item
                        (click)="this._data.cond_type = 'circuit';setDirty();">
                        Circuit
                    </button>
                    <button *ngIf="this._data.cond_type != 'parc'" mat-menu-item
                        (click)="this._data.cond_type = 'parc';setDirty();">
                        Parc
                    </button>
                    <button *ngIf="this._data.cond_type" mat-menu-item
                        (click)="this._data.cond_type = null;setDirty();">
                        Conduite
                    </button>
                </mat-menu>

                <!-- <span class="title dialog-title">{{ this.title }}</span> -->
                <div fxLayout="row">
                    <ng-container *ngIf="tel1 && this._data.categorie != 'busy'">
                        <a style="color: white; border: 1px solid white; border-radius: 50%;" href="tel:{{tel1}}"
                            (click)="$event.stopPropagation()"
                            *accessControl="{ permissions: calendarPermissions[permissionName]}">
                            <button *accessControl="{ permissions: calendarPermissions[permissionName]}"
                                mat-icon-button>
                                <mat-icon>phone</mat-icon>
                            </button>
                        </a>
                    </ng-container>
                    <button mat-icon-button (click)="closeThis()" aria-label="Close dialog">
                        <mat-icon class="fail" style="background: inherit !important">close</mat-icon>
                    </button>
                </div>
            </mat-toolbar-row>
        </mat-toolbar>
        <div class="p-24 m-0" style="overflow-y: auto; overflow-x: visible" fusePerfectScrollbar>
            <form name="eventForm" autocomplete="off" [formGroup]="eventForm" class="event-form w-100-p"
                fxLayout="column">

                <!-- ?CANDIDAT AND AGENT XS -->
                <div fxLayout="row" fxLayout.xs="column" fxHide.gt-xs>

                    <!-- ? CANDIDAT AND LINK TO CANDIDAT -->
                    <div *ngIf="_data.categorie != 'busy'" class="w-100-p" fxLayout="row"
                        fxLayoutAlign="space-between center">
                        <mat-form-field class="w-100-p paddingR disabled-dark lezem" floatLabel="always"
                            appearance="outline">
                            <mat-label>Candidat
                                <span *ngIf="!eventForm.controls['candidatId'].value">
                                    {{(filteredBanks | async)?.length}}
                                </span>
                            </mat-label>
                            <mat-select [disabled]="SessionArray.length > 0"
                                *accessControl="{ permissions: calendarPermissions[permissionName]}" class="w-100-p"
                                formControlName="candidatId" (selectionChange)="
                                setExCondType($event.value);
                                setAgentResp($event);
                                setTitle(['candidat', $event.value]);
                                openRenFra($event.value)
                            " #singleSelect [syncControl]="candidatId">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                        placeholderLabel="rechercher ..."
                                        noEntriesFoundLabel="Pas de candidats !"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let cand of filteredBanks | async" [value]="cand.id">
                                    {{ cand.username }} {{ cand.tel1 ? '(' + cand.tel1 + ')' : '' }}
                                </mat-option>
                            </mat-select>
                            <mat-error> Champ requis </mat-error>
                        </mat-form-field>
                        <mat-icon class="mb-16" style="cursor: pointer;color:#029be5"
                            (click)='goToCandidate()'>exit_to_app</mat-icon>
                    </div>
                    <!-- ? /CANDIDAT AND LINK TO CANDIDAT -->


                    <!-- ? AGENT -->
                    <mat-form-field *ngIf="
                            this._data.agents &&
                            this._data.agents.length > 1 &&
                            (_data.condEx || _data.condLess || _data.categorie == 'busy')
                        " class="w-100-p dark-disabled lezem" floatLabel="always" appearance="outline">
                        <mat-label>Agent</mat-label>
                        <mat-select formControlName="agentResp"
                            *accessControl="{ permissions: calendarPermissions[permissionName]}" (selectionChange)="
                                setTitle(['agents', $event]); setCar()
                            " [syncControl]="agentResp">
                            <mat-option *ngFor="let agent of _data.agents" [value]="agent.id">
                                {{ agent.username }}
                            </mat-option>
                        </mat-select>
                        <mat-error> Champ requis </mat-error>
                    </mat-form-field>
                    <!-- ? /AGENT -->

                </div>

                <!-- ?CANDIDAT AND AGENT GT-XS -->
                <div fxLayout="row" fxHide.xs>

                    <!-- ? CANDIDAT AND LINK TO CANDIDAT -->
                    <div *ngIf="_data.categorie != 'busy'" class="div-large w-100-p paddingL paddingR" fxLayout="column"
                        fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <div class="w-100-p row" fxLayoutAlign="space-between center">
                            <label class="input-label obligatoire">Candidat
                                <span *ngIf="!eventForm.controls['candidatId'].value">
                                    {{(filteredBanks | async)?.length}}
                                </span>
                            </label>
                            <mat-icon style="cursor: pointer;color:#029be5"
                                (click)='goToCandidate()'>exit_to_app</mat-icon>
                        </div>
                        <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                            <mat-select [disabled]="SessionArray.length > 0"
                                *accessControl="{ permissions: calendarPermissions[permissionName]}" class="w-100-p"
                                formControlName="candidatId" (selectionChange)="
                                    setExCondType($event.value);
                                    setAgentResp($event);
                                    setTitle(['candidat', $event.value]);
                                    openRenFra($event.value)
                                " #singleSelect [syncControl]="candidatId">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                        placeholderLabel="rechercher ..."
                                        noEntriesFoundLabel="Pas de candidats !"></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let cand of filteredBanks | async" [value]="cand.id">
                                    {{ cand.username }} {{ cand.tel1 ? '(' + cand.tel1 + ')' : '' }}
                                </mat-option>
                            </mat-select>
                            <mat-error> Champ requis </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- ? /CANDIDAT AND LINK TO CANDIDAT -->

                    <!-- ? AGENT -->
                    <div class="div-large w-100-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs *ngIf="
                            this._data.agents &&
                            this._data.agents.length > 1 &&
                            (_data.condEx || _data.condLess || _data.categorie == 'busy')
                        ">
                        <label class="input-label obligatoire">Agent</label>
                        <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                            <mat-select formControlName="agentResp"
                                *accessControl="{ permissions: calendarPermissions[permissionName]}" (selectionChange)="
                                    setTitle(['agents', $event]); setCar()
                                " [syncControl]="agentResp">
                                <mat-option *ngFor="let agent of _data.agents" [value]="agent.id">
                                    {{ agent.username }}
                                </mat-option>
                            </mat-select>
                            <mat-error> Champ requis </mat-error>
                        </mat-form-field>

                    </div>
                    <!-- ? /AGENT -->

                </div>

                <!-- ?Vehicule GT-XS -->
                <div class="div-large w-100-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                    fxLayoutGap="4px" fxHide.xs *ngIf="
                        _data.categorie != 'busy' &&
                        this._data.vehicules &&
                        (this._data.vehicules.length > 1 || this._data.cond_type == 'recyclage') &&
                        (this._data.condLess || this._data.condEx)
                    ">
                    <label class="input-label obligatoire">Véhicule(العربة)</label>
                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                        <mat-select *accessControl="{ permissions: calendarPermissions[permissionName]}"
                            formControlName="vehiculeId" [syncControl]="vehiculeId">
                            <mat-option *ngIf="this._data.cond_type == 'recyclage'" [value]="'client_vehicle'">
                                Véhicule client
                            </mat-option>
                            <mat-option *ngFor="let vehicle of _data.vehicules" [value]="vehicle.id">
                                {{ vehicle.nom }}
                            </mat-option>
                        </mat-select>
                        <mat-error> Champ requis </mat-error>
                    </mat-form-field>
                </div>
                <!-- ?/Vehicule GT-XS -->

                <!-- ?Vehicule XS -->
                <mat-form-field fxHide.gt-xs *ngIf="
                        _data.categorie != 'busy' &&
                        this._data.vehicules &&
                        (this._data.vehicules.length > 1 || this._data.cond_type == 'recyclage') &&
                        (this._data.condLess || this._data.condEx)
                    " class="w-100-p lezem" floatLabel="always" appearance="outline">
                    <mat-label>Véhicule(العربة)</mat-label>
                    <mat-select *accessControl="{ permissions: calendarPermissions[permissionName]}"
                        formControlName="vehiculeId" [syncControl]="vehiculeId">
                        <mat-option *ngIf="this._data.cond_type == 'recyclage'" [value]="'client_vehicle'">
                            Véhicule client
                        </mat-option>
                        <mat-option *ngFor="let vehicle of _data.vehicules" [value]="vehicle.id" required>
                            {{ vehicle.nom }}
                        </mat-option>
                    </mat-select>
                    <mat-error> Champ requis </mat-error>
                </mat-form-field>
                <!-- ?Vehicule XS -->

                <!-- ?DATE XS-->
                <div fxLayout="column">

                    <!-- ?JOUR BUSY START-->
                    <mat-form-field floatLabel="always" fxHide.gt-xs *ngIf="this._data.categorie =='busy'"
                        class="w-100-p lezem" floatLabel="always" appearance="outline">
                        <mat-label style="text-align: start">Jour {{ fullDay ? 'début' : ''}}
                            {{ fullDay ? '(يوم البداية)' : '(اليوم)'}}
                        </mat-label>
                        <input autocomplete="off" matInput readonly required placeholder="Date début" name="start"
                            formControlName="debut" [syncControl]="debut" [matDatepicker]="pickerJDebut"
                            (dateChange)="dateTimeChanged()" (click)="pickerJDebut.open()"
                            *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                        <mat-error> Champ requis</mat-error>
                        <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" matSuffix class="suffix" [for]="pickerJDebut"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #pickerJDebut></mat-datepicker>
                        <!-- <div (click)="$event.stopPropagation()">
                            <mat-calendar
                                #calendar
                                (selectedChange)="select($event, calendar)"
                                [dateClass]="isSelected"
                            >
                            </mat-calendar>
                        </div> -->
                    </mat-form-field>
                    <!-- ?/JOUR BUSY START-->

                    <!-- ?JOUR BUSY END-->
                    <mat-form-field floatLabel="always" fxHide.gt-xs *ngIf="this._data.categorie =='busy' && fullDay"
                        class="w-100-p lezem" floatLabel="always" appearance="outline">
                        <mat-label style="text-align: start">Jour fin(يوم النّهاية)</mat-label>
                        <input autocomplete="off" matInput readonly required placeholder="Date début" name="start"
                            formControlName="fin" [min]="this.eventForm?.get('debut')?.value"
                            [matDatepicker]="endDatePicker" (click)="endDatePicker.open()" [syncControl]="fin"
                            *accessControl="{ permissions: calendarPermissions[permissionName]}" />

                        <mat-error> Champ requis </mat-error>
                        <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName]}" matSuffix class="suffix" [for]="endDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #endDatePicker></mat-datepicker>

                        <!-- <div (click)="$event.stopPropagation()">
                            <mat-calendar
                                #calendar
                                (selectedChange)="select($event, calendar)"
                                [dateClass]="isSelected"
                            >
                            </mat-calendar>
                        </div> -->
                    </mat-form-field>
                    <!-- ?/JOUR BUSY END-->

                    <!-- ?jour view xs  for lesson-->
                    <mat-form-field fxHide.gt-xs *ngIf="this._data.categorie != 'busy' && (this._data.categorie == 'conduiteLess' ||
                                this._data.categorie == 'codeLess') &&
                            this._data.action == 'new'
                        " class="w-100-p lezem" floatLabel="always" appearance="outline">
                        <mat-label style="text-align: start">Jour (اليوم)</mat-label>
                        <input autocomplete="off" matInput readonly required placeholder="Date début" name="start"
                            formControlName="debut" [syncControl]="debut" [matDatepicker]="picker"
                            (dateChange)="dateTimeChanged()" (click)="picker.open()"
                            *accessControl="{ permissions: calendarPermissions[permissionName]}" />
                        <mat-error> Champ requis </mat-error>
                        <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName]}" matSuffix class="suffix" [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>

                    </mat-form-field>

                    <!-- ?jour  view xs for exam -->
                    <mat-form-field floatLabel="always" fxHide.gt-xs *ngIf="
                        this._data.categorie != 'busy' && (this._data.categorie === 'conduiteEx' ||
                            this._data.categorie == 'codeEx' ||
                            this._data.action == 'edit')
                        " class="w-100-p lezem" floatLabel="always" appearance="outline">
                        <mat-label>Jour(اليوم)</mat-label>
                        <input autocomplete="off" matInput [matDatepicker]="startDatePicker" readonly
                            placeholder="Date début" name="start" formControlName="debut" [syncControl]="debut"
                            [min]="minDate" [max]="maxDate" (dateChange)="dateTimeChanged()"
                            (click)="startDatePicker.open()"
                            *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                        <mat-error> Champ requis </mat-error>
                        <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" matSuffix class="suffix" [for]="startDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #startDatePicker></mat-datepicker>

                    </mat-form-field>

                    <div *ngIf="!fullDay" fxLayout="row" fxLayoutGap="4px">
                        <!-- ?DEPART XS -->
                        <mat-form-field class="w-100-p lezem padd" fxHide.gt-xs floatLabel="always"
                            appearance="outline" >
                            <mat-label>Heure début (ساعة البداية)</mat-label>
                            <div fxLayout="row" class="heureOuverture" fxLayoutAlign="space-between center" >
                                <input class="mw" floatLabel="always" autocomplete="off" readonly matInput required
                                    formControlName="tempsDebut" [syncControl]="tempsDebut" [max]="maxdebut"
                                    [min]="mindebut" [ngxMatTimepicker]="pickerDepart" [format]="24"
                                    *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                                <div>
                                    <ngx-mat-timepicker (timeSet)="dateTimeChanged()" [minutesGap]="5" #pickerDepart>
                                    </ngx-mat-timepicker>
                                    <ngx-mat-timepicker-toggle [for]="pickerDepart">
                                    </ngx-mat-timepicker-toggle>
                                </div>
                            </div>
                        </mat-form-field>
                        <!-- ?/DEPART XS -->

                        <!-- ?Fin XS -->
                        <mat-form-field *ngIf="_data.categorie == 'busy' && !fullDay" class="w-100-p lezem padd"
                            fxHide.gt-xs floatLabel="always" appearance="outline">
                            <mat-label>Heure fin (ساعة النّهاية)</mat-label>
                            <div fxLayout="row" class="heureOuverture" fxLayoutAlign="space-between center">
                                <input class="mw" floatLabel="always" autocomplete="off" readonly matInput required
                                    formControlName="tempsFin" [syncControl]="tempsFin" [ngxMatTimepicker]="pickerFin"
                                    *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}"
                                    [format]="24" />
                                <div>
                                    <ngx-mat-timepicker (timeSet)="dateTimeChanged()" [minutesGap]="5" #pickerFin>
                                    </ngx-mat-timepicker>
                                    <ngx-mat-timepicker-toggle [for]="pickerFin">
                                    </ngx-mat-timepicker-toggle>
                                </div>
                            </div>
                        </mat-form-field>
                        <!-- ?/Fin XS -->

                        <!-- ?Duree XS -->
                        <mat-form-field
                            *ngIf="!this._data.codeLess && !this._data.condLess && _data.categorie != 'busy'"
                            class="w-100-p lezem padd" fxHide.gt-xs floatLabel="always" appearance="outline">
                            <mat-label>Durée en min (المدّة)</mat-label>
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <input class="mw" floatLabel="always" matInput pattern="^[0-9]{2,2}$" type="number"
                                    style="height: 33px" formControlName="one_session_duration_in_minutes"
                                    [syncControl]="one_session_duration_in_minutes"
                                    *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                            </div>
                            <mat-error> Champ requis. entre 30 et 60min </mat-error>
                        </mat-form-field>

                        <!-- ?Duree XS -->
                    </div>


                </div>

                <!-- ?DUREE XS-->
                <div fxLayout="row" floatLabel="always" fxLayout.xs="column" fxHide.gt-xs
                    *ngIf="this._data.codeLess || this._data.condLess">
                    <div class="w-100-p" ngClass.xs="w-0-p" ngClass.sm="w-100-p paddingL paddingR"></div>

                    <mat-form-field floatLabel="always" appearance="outline" class="w-100-p lezem padd duree">
                        <mat-label>Nbr Séances(عدد الحصص)</mat-label>
                        <mat-select floatLabel="always" aria-placeholder="Nbr Séances" formControlName="duration"
                            [syncControl]="duration" style="height: 33px" fxLayout="row" fxLayoutAlign="start center"
                            *accessControl="{ permissions: calendarPermissions[permissionName]}" required>
                            <mat-option value="1"> 1 séance</mat-option>
                            <mat-option value="2"> 2 séances</mat-option>
                            <mat-option value="1.5"> 1.5 séance</mat-option>
                            <mat-option value="0.5"> 0.5 séance</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- ?/DUREE XS -->


                <!-- ?DATE GT-XD -->
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <!-- ?JOUR BUSY START-->
                    <div class="div-large w-50-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs *ngIf="
                            this._data.categorie =='busy'
                        ">
                        <label class="input-label obligatoire">Jour {{ fullDay ? 'début' : ''}}<br />
                            {{ fullDay ? '(يوم البداية)' : '(اليوم)'}}
                        </label>
                        <mat-form-field floatLabel="always" class="w-100-p" floatLabel="always" appearance="outline">
                            <input autocomplete="off" matInput [matDatepicker]="startDatePicker" readonly
                                placeholder="Date début" name="start" formControlName="debut" [syncControl]="debut"
                                (dateChange)="dateTimeChanged()" (click)="startDatePicker.open()"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                            <mat-error> Champ requis </mat-error>
                            <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" class="suffix" matSuffix
                                [for]="startDatePicker"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-datepicker #startDatePicker></mat-datepicker>

                    </div>
                    <!-- ?/JOUR BUSY START-->

                    <!-- ?JOUR BUSY END-->
                    <div class="div-large w-50-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" class="w-50-p" fxHide.xs *ngIf="
                            this._data.categorie =='busy' && fullDay
                        ">
                        <label class="input-label obligatoire">Jour fin
                            <br>
                            (يوم النّهاية)
                        </label>
                        <mat-form-field floatLabel="always" class="w-100-p" floatLabel="always" appearance="outline">
                            <input autocomplete="off" matInput [matDatepicker]="endDatePicker" readonly
                                placeholder="Date début" name="start" formControlName="fin" [syncControl]="fin"
                                [min]="this.eventForm?.get('debut')?.value" (click)="endDatePicker.open()"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                            <mat-error> Champ requis </mat-error>
                            <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" class="suffix" matSuffix
                                [for]="endDatePicker"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>

                        </mat-form-field>
                    </div>
                    <!-- ?/JOUR BUSY END-->

                    <!-- ?JOUR GT-XS Exam || edit-->
                    <div class="div-large w-33-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs *ngIf="
                            this._data.categorie != 'busy' && (this._data.categorie === 'conduiteEx' ||
                            this._data.categorie == 'codeEx' ||
                            this._data.action == 'edit')
                            
                        ">
                        <label class="input-label obligatoire">Jour<br />(اليوم)</label>
                        <mat-form-field floatLabel="always" class="w-100-p" floatLabel="always" appearance="outline">
                            <input autocomplete="off" matInput [matDatepicker]="startDatePickerGtxs" readonly
                                placeholder="Date début" name="start" formControlName="debut" [syncControl]="debut"
                                [min]="minDate" [max]="maxDate" (dateChange)="dateTimeChanged()"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}"
                                (click)="startDatePickerGtxs.open()" />
                            <mat-error> Champ requis </mat-error>
                            <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" class="suffix" matSuffix
                                [for]="startDatePickerGtxs"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-datepicker #startDatePickerGtxs></mat-datepicker>

                    </div>
                    <!-- ?/JOUR GT-XS Exam -->

                    <!-- ?JOUR GT-XS Less -->
                    <div class="div-large w-33-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs *ngIf="
                        this._data.categorie != 'busy' && (this._data.categorie == 'conduiteLess' ||
                                this._data.categorie == 'codeLess') &&
                            this._data.action == 'new'
                        ">
                        <label class="input-label obligatoire">Jour<br />(اليوم)</label>
                        <mat-form-field class="w-100-p filed-wdth" floatLabel="always" floatLabel="always"
                            appearance="outline" fxLayoutAlign="space-between center">
                            <input autocomplete="off" matInput readonly placeholder="Date début" name="start"
                                formControlName="debut" [syncControl]="debut" (dateChange)="dateTimeChanged()"
                                [matDatepicker]="picker1" (click)="picker1.open()"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                            <mat-error> Champ requis </mat-error>
                            <mat-datepicker-toggle *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" class="suffix" matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                            <!-- <div (click)="$event.stopPropagation()">
                                <mat-calendar
                                    #calendar
                                    (selectedChange)="select($event, calendar)"
                                    [dateClass]="isSelected"
                                >
                                </mat-calendar>
                            </div> -->
                        </mat-form-field>
                    </div>
                    <!-- ?JOUR GT-XS Exam -->

                    <!-- ?DEP GT-XS -->
                    <div *ngIf="!fullDay" class="div-large w-33-p paddingL paddingR" fxLayout="row"
                        fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs fxFlex>
                        <div>
                            <label class="input-label obligatoire">Début <span> <br />(ساعة البداية) </span></label>
                            <mat-form-field class="w-100-p filed-wdth padd mt-4" floatLabel="always"
                                appearance="outline"  >
                                <div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: -4px;">
                                        <input class="mw" floatLabel="always" autocomplete="off" readonly matInput
                                            formControlName="tempsDebut" [syncControl]="tempsDebut" [max]="maxdebut"
                                            [min]="mindebut" [ngxMatTimepicker]="picker2" [format]="24"
                                            *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                                        <div>
                                            <ngx-mat-timepicker (timeSet)="dateTimeChanged()" [minutesGap]="5" #picker2>
                                            </ngx-mat-timepicker>
                                            <ngx-mat-timepicker-toggle [for]="picker2">
                                            </ngx-mat-timepicker-toggle>
                                        </div>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                        <div *ngIf="_data.categorie == 'busy'">
                            <label class="input-label obligatoire">Fin <span> <br />(ساعة النّهاية) </span></label>
                            <mat-form-field class="w-100-p filed-wdth padd mt-4" floatLabel="always"
                                appearance="outline">
                                <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: -4px;">
                                    <input class="mw" floatLabel="always" autocomplete="off" readonly matInput
                                        placeholder="Temps" formControlName="tempsFin" [syncControl]="tempsFin"
                                        [ngxMatTimepicker]="picker3" [format]="24"
                                        [min]="getMinValEndDate(this.eventForm?.get('tempsDebut')?.value)"
                                        *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" />
                                    <div>
                                        <ngx-mat-timepicker (timeSet)="dateTimeChanged()" [minutesGap]="5" #picker3>
                                        </ngx-mat-timepicker>
                                        <ngx-mat-timepicker-toggle [for]="picker3">
                                        </ngx-mat-timepicker-toggle>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- ?DEP GT-XS LESS -->


                    <!-- ?DUREE GT-XS -->
                    <div *ngIf="this._data.codeLess || this._data.condLess" class="div-large paddingL paddingR w-33-p"
                        fxFlex fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label obligatoire"><span>Nbr Séances<br />(عدد الحصص)</span></label>
                        <mat-form-field floatLabel="always" appearance="outline" class="w-100-p lezem padd duree">
                            <mat-select floatLabel="always" placeholder="Dureé" formControlName="duration"
                                [syncControl]="duration" style="height: 33px" fxLayout="row"
                                fxLayoutAlign="start center"
                                *accessControl="{ permissions: calendarPermissions[permissionName]}">
                                <mat-option value="1"> 1 séance</mat-option>
                                <mat-option value="2"> 2 séance</mat-option>
                                <mat-option value="1.5"> 1.5 séance</mat-option>
                                <mat-option value="0.5"> 0.5 séance</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- ?DUREE GT-XS -->

                    <!-- ?DUREE GT-XS -->
                    <div *ngIf="!this._data.codeLess && !this._data.condLess && _data.categorie != 'busy'"
                        class="div-large paddingL paddingR w-33-p" fxFlex fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label mb-4 obligatoire"><span>Durée en min<br />(المدة)</span></label>

                        <mat-form-field floatLabel="always" appearance="outline" class="w-100-p lezem padd duree">
                            <input matInput style="height: 33px" floatLabel="always" placeholder="Dureé" type="number"
                                pattern="^[0-9]{2,2}$" formControlName="one_session_duration_in_minutes"
                                [syncControl]="one_session_duration_in_minutes" (keypress)="numberOnly($event)"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}">
                            <mat-error> Champ requis. entre 30 et 60min </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- ?DUREE GT-XS -->

                </div>

                <!-- ?Compte -->
                <div *ngIf="_data.categorie == 'busy'" class="w-100-p mb-24" style="margin-top: -20px;"
                    [ngClass.gt-sm]="'px-20'" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px">
                    <mat-checkbox *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}"
                        [(ngModel)]="fullDay"
                        (keyup.enter)="fullDay = !fullDay;fullDayAction(fullDay);$event.stopPropagation()"
                        [ngModelOptions]="{standalone: true}" (change)="
                                fullDayAction(
                                    $event.checked
                                )
                            " class="example-margin">
                        Journée entière
                    </mat-checkbox>
                </div>

                <div fxLayout="column" class="paddingL paddingR mb-24" *ngIf="
                        (
                            (this._data.categorie == 'conduiteLess' && eventForm.controls['vehiculeId']?.value && eventForm.controls['agentResp']?.value) 
                            ||
                            this._data.categorie == 'codeLess'
                        ) &&
                        eventForm.controls['candidatId'].value &&
                        this._data.action !== 'edit'
                    ">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="space-between start">
                        <a (click)="addSession()" class="ajout-session" style="cursor: pointer">+Ajouter (إضافة)</a>
                        <span *ngIf="SessionError" class="red">{{
                            SessionError
                            }}</span>
                    </div>
                    <ng-container *ngFor="let item of SessionArray; let i = index">
                        <div fxLayout="row" fxLayoutAlign="start center" class="session">
                            <div fxLayout="row" fxLayout.xs="column">
                                <span>{{
                                    item.date.toDate() | date: "EEE dd MMMM" : "" : "fr-FR"
                                    }}
                                    ,
                                </span>
                                <span>{{ item.debut.toDate() | date: "H.mm" }} à
                                    {{
                                    item.fin.toDate() | date: "H.mm"
                                    }}</span>
                                <span>
                                    {{item.agentName}}
                                </span>
                            </div>
                            <div fxFlex></div>
                            <div fxLayout="row">
                                <ng-container *ngIf="item.confirmed">
                                    <button fxLayout="row"
                                        *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}"
                                        fxLayoutAlign="center center" mat-icon-button
                                        (click)="item.absent = !item.absent" #tooltip="matTooltip" [matTooltip]="
                                                !item.absent
                                                    ? 'mettre absent'
                                                    : 'mettre present'
                                            " matTooltipPosition="above" matTooltipHideDelay="0">
                                        <!-- <mat-icon
                                        svgIcon="how_to_reg"
                                            [ngClass]="{
                                                green: !item.absent,
                                                red: item.absent
                                            }"
                                            #tooltip="matTooltip"
                                            [matTooltip]="
                                                !item.absent
                                                    ? 'mettre absent'
                                                    : 'mettre present'
                                            "
                                            matTooltipPosition="above"
                                            matTooltipHideDelay="0"
                                            ></mat-icon
                                        > -->
                                        <svg [ngClass]="{
                                                'icon-green': !item.absent,
                                                'icon-red': item.absent
                                            }" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                                            <path
                                                d="m15.55 20.4-3.45-3.45 1.4-1.4 2.05 2.05 5.05-5.05 1.4 1.4ZM10 12q-1.65 0-2.825-1.175Q6 9.65 6 8q0-1.65 1.175-2.825Q8.35 4 10 4q1.65 0 2.825 1.175Q14 6.35 14 8q0 1.65-1.175 2.825Q11.65 12 10 12Zm2.85 1.3L9.2 16.95 12.25 20H2v-2.8q0-.825.425-1.55.425-.725 1.175-1.1 1.275-.65 2.875-1.1Q8.075 13 10 13q.75 0 1.463.075.712.075 1.387.225Z" />
                                        </svg>

                                    </button>
                                </ng-container>
                                <button fxLayout="row" fxLayoutAlign="center center" mat-icon-button
                                    (click)="item.confirmed = !item.confirmed"
                                    *accessControl="{ permissions: calendarPermissions[permissionName],code_only_access: this.sub_type == 'code_only'}">
                                    <mat-icon [ngClass]="{ green: item.confirmed }" #tooltip="matTooltip" [matTooltip]="
                                            item.confirmed
                                                ? 'annuler'
                                                : 'confirmer'
                                        " matTooltipPosition="above" matTooltipHideDelay="0">check_circle</mat-icon>
                                </button>
                                <button fxLayout="row" fxLayoutAlign="center center" mat-icon-button
                                    (click)="deleteSession(i)"
                                    *accessControl="{ permissions: calendarPermissions[permissionName]}">
                                    <mat-icon #tooltip="matTooltip" matTooltip="supprimer" matTooltipPosition="above"
                                        matTooltipHideDelay="0">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div fxLayout="row" fxHide.xs *ngIf="this.sub_type !== 'code_only'">
                    <div class="div-large w-50-p paddingL paddingR" [ngClass]="{ 'w-100-p': openRenouv == true }"
                        fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" fxHide.xs *ngIf="openRenouv">
                        <label class="input-label obligatoire">
                            Renouvellement <br />
                            (تجديد)</label>

                        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                            <input type="number" min="0" autocomplete="off" (change)="onRenouvellement()" matInput
                                formControlName="renouvellement" [syncControl]="renouvellement" name="montant"
                                *accessControl="{ permissions: calendarPermissions[permissionName]}" />
                        </mat-form-field>
                    </div>
                    <div *ngIf="openFrai" class="div-large w-50-p paddingL paddingR"
                        [ngClass]="{ 'w-100-p': openRenouv == false }" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label obligatoire">
                            Frai d'examen <br />(معلوم الإمتحان)</label>

                        <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                            <input type="number" min="0" autocomplete="off" (change)="onFrai()" matInput
                                formControlName="frai" [syncControl]="frai" name="montant"
                                *accessControl="{ permissions: calendarPermissions[permissionName]}" />
                        </mat-form-field>
                    </div>
                </div>

                <div fxLayout="row" fxHide.xs *ngIf="
                        (_data.condEx || _data.codeEx) &&
                        _data.centers.length > 0
                    ">
                    <div class="div-large w-100-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                        fxLayoutGap="4px" fxHide.xs>
                        <label class="input-label">Centre</label>
                        <mat-form-field class="w-100-p lezem" floatLabel="always" appearance="outline">
                            <ng-container *ngIf="candidatSelected">
                                <mat-select formControlName="centreExamen" [syncControl]="centreExamen"
                                    *accessControl="{ permissions: calendarPermissions[permissionName] , code_only_access: this.sub_type == 'code_only'}">
                                    <mat-option *ngFor="let center of filtred_center" [value]="center.id">
                                        {{ center.nomDeCenter }}
                                    </mat-option>
                                </mat-select>
                            </ng-container>

                            <ng-container *ngIf="!candidatSelected">
                                <mat-select formControlName="centreExamen"
                                    *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}">
                                    <mat-option disabled>
                                        Veuillez sélectionner un candidat
                                    </mat-option>
                                </mat-select>
                            </ng-container>


                            <mat-error> Champ requis </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxHide.gt-xs
                    *ngIf="(_data.condEx || _data.codeEx) && _data.centers.length > 0">
                    <mat-form-field class="w-100-p disabled-dark lezem" floatLabel="always" appearance="outline">
                        <mat-label>Centre</mat-label>
                        <ng-container *ngIf="candidatSelected">
                            <mat-select [disabled]="SessionArray.length > 0" class="w-100-p"
                                formControlName="centreExamen" [syncControl]="centreExamen"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}">
                                <mat-option *ngFor="let center of filtred_center" [value]="center.id">
                                    {{ center.nomDeCenter }}
                                </mat-option>
                            </mat-select>
                        </ng-container>
                        <ng-container *ngIf="!candidatSelected">
                            <mat-select formControlName="centreExamen"
                                *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}">
                                <mat-option disabled>
                                    Veuillez sélectionner un candidat
                                </mat-option>
                            </mat-select>
                        </ng-container>
                        <mat-error> Champ requis </mat-error>
                    </mat-form-field>
                </div>

                <div class="div-large w-100-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                    fxLayoutGap="4px" fxHide.xs>
                    <label *ngIf="_data.categorie != 'busy'" class="input-label obligatoire">Titre (إسم الحصة)</label>
                    <label *ngIf="_data.categorie == 'busy'" class="input-label obligatoire">Titre (العنوان)</label>

                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                        <input autocomplete="off" matInput name="title" formControlName="titre" [syncControl]="titre"
                            placeholder="Titre" *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}"
                            required />
                        <mat-error> Champ requis </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field class="w-100-p  lezem" fxHide.gt-xs floatLabel="always" appearance="outline">
                    <mat-label>Titre (إسم الحصة)</mat-label>
                    <input autocomplete="off" matInput name="title" formControlName="titre" [syncControl]="titre"
                        placeholder="Titre" *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}"
                        required />
                    <mat-error> Champ requis </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="openRenouv && this.sub_type != 'code_only'" fxHide.gt-xs class="w-100-p lezem" floatLabel="always"
                    appearance="outline">
                    <mat-label> Renouvellement (تجديد)</mat-label>
                    <input type="number" min="0" autocomplete="off" (change)="onRenouvellement()" matInput
                        formControlName="renouvellement" [syncControl]="renouvellement"
                        *accessControl="{ permissions: calendarPermissions[permissionName]}" name="montant" />
                </mat-form-field>

                <mat-form-field fxHide.gt-xs *ngIf="openFrai" class="w-100-p lezem" floatLabel="always"
                    appearance="outline">
                    <mat-label> Frai d'examen (معلوم الإمتحان)</mat-label>
                    <input type="number" min="0" autocomplete="off" (change)="onFrai()" matInput formControlName="frai"
                        [syncControl]="frai" name="montant"
                        *accessControl="{ permissions: calendarPermissions[permissionName]}" required />
                </mat-form-field>
                <!-- <mat-form-field *ngIf="(this._data.codeEx && this.hasFrai) || this._data.condEx"
                class="w-100-p paddingL paddingR lezem" floatLabel="always" appearance="outline">
                <mat-label> Renouvellement (تجديد)</mat-label>
                <input autocomplete="off" (change)="onFrai()" matInput formControlName="renouvellement" name="montant" />
            </mat-form-field> -->

                <div class="div-large w-100-p paddingL paddingR" fxLayout="column" fxLayoutAlign="start start"
                    fxLayoutGap="4px" fxHide.xs *ngIf="this._data.condLess || this._data.condEx">
                    <label class="input-label">Rencontre à (مكان اللقاء)</label>

                    <mat-form-field class="w-100-p" floatLabel="always" appearance="outline">
                        <input autocomplete="off" matInput name="location" formControlName="location"
                            [syncControl]="location"
                            *accessControl="{ permissions: calendarPermissions[permissionName]}"
                            placeholder="Rencontre à" />
                    </mat-form-field>
                </div>
                <mat-form-field fxHide.gt-xs class="w-100-p" floatLabel="always" appearance="outline"
                    *ngIf="this._data.condLess || this._data.condEx">
                    <mat-label>Rencontre à (مكان اللقاء)</mat-label>
                    <input autocomplete="off" matInput name="location" formControlName="location"
                        [syncControl]="location" *accessControl="{ permissions: calendarPermissions[permissionName]}"
                        placeholder="Rencontre à" />
                </mat-form-field>
                <div class="div-large w-100-p paddingL paddingR remaqueBV" 
                *ngIf="this.sub_type !== 'code_only'" 
                fxLayout="column" fxLayoutAlign="start start"
                    fxLayoutGap="4px" fxHide.xs>
                    <label class="input-label">Remarque (ملاحظة)</label>
                        <textarea matInput formControlName="comment" [syncControl]="comment" placeholder="remarque"
                            mat-maxlength="250" *accessControl="{ permissions: calendarPermissions[permissionName]}"
                            max-rows="4">
                        </textarea>
                </div>
                <mat-form-field *ngIf="this.sub_type !== 'code_only'" fxHide.gt-xs class="w-100-p remarque-field" floatLabel="always">
                    <mat-label>Remarque (ملاحظة)</mat-label>
                    <textarea matInput formControlName="comment" [syncControl]="comment" placeholder="remarque" class="remarqueSV" 
                        mat-maxlength="250" *accessControl="{ permissions: calendarPermissions[permissionName]}"
                        max-rows="4">
                    </textarea>
                </mat-form-field>
            </form>
        </div>
        <div class="w-100-p">
            <div class="m-0" fxLayout="column" fxLayoutAlign="start start ">
                <div *ngIf="multiErrors" fxLayout="column">
                    <div *ngFor="let error of errorsArray" style="
                            background-color: rgb(211, 107, 211);
                            padding: 24px;
                            border-radius: 5px;
                        " class="py-16 ml-16 mr-16 register mb-8" fxFlex="1 0 auto" fxLayout="column"
                        fxLayout.gt-xs="row">
                        <span class="text" *ngIf="error.conflit.length > 1">{{ error.conflit }} ne sont pas disponibles
                            le {{ error.debut }}.
                        </span>
                        <span class="text" *ngIf="error.conflit.length == 1">{{ error.conflit }} n'est pas disponible le
                            {{ error.debut }}.
                        </span>
                    </div>
                </div>
                <div *ngIf="errors" style="
                        background-color: rgb(211, 107, 211);
                        padding: 24px;
                        border-radius: 5px;
                    " class="py-16 ml-16 mr-16 register" fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row">
                    <span class="text">{{ conflit }}</span>
                </div>
            </div>

            <!-- !confirmer -->
            <div class="confirmation-container m-0 py-4 px-16" *ngIf="
                    (this._data.codeLess || this._data.condLess) &&
                    SessionArray.length === 0
                    && 
                    allow_delete
                ">

                <div *ngIf="!editingDuration && this._data.categorie!='busy'">
                    <button  mat-button *accessControl="{ permissions: calendarPermissions[permissionName]}"
                        (click)="editingDuration = true" class="btn session-duration">
                        Durée bloquée: {{this.eventForm?.get('one_session_duration_in_minutes')?.value *
                        this.eventForm?.get('duration')?.value }} minutes
                    </button>
                </div>
                <div *ngIf="editingDuration && this._data.categorie!='busy'" class="w-100-p pl-16 mt-12" fxLayout="row"
                    fxLayoutAlign="start center" fxLayoutGap="10px">
                    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center">
                        <mat-icon [matTooltip]="'c\'est différent de nembre de séance'"> info </mat-icon>
                        <span>
                            Durée bloquée sur calendrier pour 1 séance
                        </span>
                    </div>
                    <div class="left-auto" style="width: fit-content; display: flex;justify-content: center; align-items: center;">

                        <button   [matMenuTriggerFor]="menuDuration" class="btn session-duration" style="box-sizing: border-box;position: relative;-webkit-user-select: none;user-select: none;cursor: pointer;outline: none;direction: ltr;/* border: none; *//* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */display: flex;white-space: nowrap;text-decoration: none;text-align: center;margin: 0;min-width: 64px;line-height: 36px;padding: 0 16px;border-radius: 31px;overflow: visible;">
                            {{this.eventForm?.get('one_session_duration_in_minutes')?.value}} minutes
                            <mat-icon style="font-size: 24px;margin-top: 5px;padding-left: 10px;"> keyboard_arrow_down </mat-icon>

                        </button>
                        <mat-menu #menuDuration="matMenu">
                            <ng-container  *ngIf="this.eventForm?.get('one_session_duration_in_minutes')?.value != 45">
                                <button mat-menu-item   (click)="setSessionDuration(45)">
                                    <span>45 minutes</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="this.eventForm?.get('one_session_duration_in_minutes')?.value != 50">
                                <button mat-menu-item   (click)="setSessionDuration(50)">
                                    <span>50 minutes</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="this.eventForm?.get('one_session_duration_in_minutes')?.value != 55">
                                <button mat-menu-item   (click)="setSessionDuration(55)">
                                    <span>55 minutes</span>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="this.eventForm?.get('one_session_duration_in_minutes')?.value != 60">
                                <button mat-menu-item   (click)="setSessionDuration(60)">
                                    <span>60 minutes</span>
                                </button>
                            </ng-container>
                        </mat-menu>
                        <button  (click)="openScheduleSettingDialog()" style="cursor: pointer;">
                            <mat-icon class="mx-12">settings</mat-icon>
                        </button>
                        <button  (click)="editingDuration = false" style="cursor: pointer;">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="!editingDuration && !eventForm.controls['completed'].value">
                    <button mat-button [matMenuTriggerFor]="menu"
                        *accessControl="{ permissions: calendarPermissions[permissionsList['confirm']]}"
                        class="btn not-confirmed">
                        <span style="padding-left: 8px;">Non Confirmé</span>
                                                

                        <mat-icon> keyboard_arrow_down </mat-icon>

                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="toggleCompleted()">
                            <mat-icon style="color: green; width: 28px;">check_circle</mat-icon>
                            <span >Confirmé</span>
                        </button>
                    </mat-menu>
                </div>
                <div *ngIf="
                        !editingDuration &&
                        eventForm.controls['completed'].value &&
                        (this._data.codeLess || this._data.condLess)
                    ">
                    <ng-container *ngIf="eventForm.controls['completed'].value">
                        <button mat-button
                            *accessControl="{ permissions: calendarPermissions[permissionsList['deconfirm']]}"
                            [matMenuTriggerFor]="menu1" class="btn present">
                            Confirmé
                            <mat-icon> keyboard_arrow_down </mat-icon>
                        </button>
                    </ng-container>
                    <mat-menu #menu1="matMenu">
                        <button mat-menu-item (click)="toggleCompleted()">
                                                        <span style="padding-left: 8px;">Non Confirmé</span>
                            <mat-icon style="color: grey">check_circle</mat-icon>
                        </button>
                    </mat-menu>
                </div>
                <div *ngIf="
                        !editingDuration &&
                        eventForm.controls['completed'].value &&
                        !eventForm.controls['absent'].value
                    ">
                    <button class="btn present" mat-button
                        *accessControl="{ permissions: calendarPermissions[permissionName]}"
                        [matMenuTriggerFor]="menu2">
                        Present
                        <mat-icon> keyboard_arrow_down </mat-icon>
                    </button>
                    <mat-menu #menu2="matMenu">
                        <button *accessControl="{ permissions: calendarPermissions[permissionName]}" mat-menu-item
                            (click)="togglePresent()">
                            <mat-icon style="color: red">check_circle</mat-icon>
                            <span>Absent</span>
                        </button>
                    </mat-menu>
                </div>

                <div *ngIf="
                        !editingDuration &&
                        eventForm.controls['completed'].value &&
                        eventForm.controls['absent'].value
                    ">
                    <button class="btn absent" mat-button
                        *accessControl="{ permissions: calendarPermissions[permissionName]}"
                        [matMenuTriggerFor]="menu3">
                        Absent
                        <mat-icon> keyboard_arrow_down </mat-icon>
                    </button>
                    <mat-menu #menu3="matMenu">
                        <button mat-menu-item (click)="togglePresent()">
                            <mat-icon style="color: green">check_circle</mat-icon>
                            <span >Présent</span>
                        </button>
                    </mat-menu>
                </div>
            </div>

            <!-- !confirmer -->
            <div class="m-0 py-4 px-16" fxLayout="row" fxLayoutAlign="end center" *ngIf="
                    (_data.codeEx || _data.condEx) &&
                    this._data.action === 'edit'
                    &&
                    allow_delete
                ">
                <ng-container *ngIf="eventForm.controls['completed'].value">
                    <span *ngIf="_data.event.success">Succes &nbsp;</span>
                    <span *ngIf="!_data.event.success">Echec &nbsp;</span>
                    <span *ngIf="_data.codeEx">{{ _data.event.resultat }} &nbsp;</span>
                    <span *ngIf="_data.event.echecParc">Parc &nbsp;</span>
                    <span *ngIf="_data.event.echecCircuit">
                        Circulation &nbsp;</span>

                    <!--  -->
                    <ng-container *ngIf="deletable">
                        <button class="btn not-confirmed" mat-button
                            *accessControl="{ permissions: [calendarPermissions[permissionName], calendarPermissions[permissionsList['delete-result']]]}"
                            (click)="deleteResult()"><!-- IF CODE EXAM THE CANDIDATE DOESNT HAVE AN EXAM DATE -->
                            Supprimer résultat
                            <mat-icon> delete </mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!eventForm.controls['completed'].value">
                    <button class="btn not-confirmed" mat-button
                        *accessControl="{ permissions: [calendarPermissions[permissionName], calendarPermissions[permissionsList['add-result']]]}"
                        (click)="addResult()">
                        Entrer résultat
                    </button>
                </ng-container>
            </div>

            <div class="m-0 pt-4 pb-8 px-16" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px"
                *ngIf="this._data.action !== 'edit'">
                <button (click)=closeThis() mat-stroked-button>
                    Annuler
                </button>
                <button mat-raised-button (click)="addEventt()"
                    *accessControl="{ permissions: calendarPermissions[permissionName] ,code_only_access: this.sub_type == 'code_only'}" class="save-button mat-accent"
                    [disabled]="eventForm.invalid || addingEvent" aria-label="SAVE">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <svg *ngIf="addingEvent" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" style="
                                margin: auto;
                                background: rgba(0, 0, 0, 0);
                                display: block;
                            " width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="75" cy="50" fill="#68b3d8" r="4.81593">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.9166666666666666s"></animate>
                            </circle>
                            <circle cx="71.65063509461098" cy="62.5" fill="#68b3d8" r="4.51741">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.8333333333333334s"></animate>
                            </circle>
                            <circle cx="62.5" cy="71.65063509461096" fill="#68b3d8" r="3.85074">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.75s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s"></animate>
                            </circle>
                            <circle cx="50" cy="75" fill="#68b3d8" r="3.18407">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.6666666666666666s"></animate>
                            </circle>
                            <circle cx="37.50000000000001" cy="71.65063509461098" fill="#68b3d8" r="3">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.5833333333333334s"></animate>
                            </circle>
                            <circle cx="28.34936490538903" cy="62.5" fill="#68b3d8" r="3">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.5s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s"></animate>
                            </circle>
                            <circle cx="25" cy="50" fill="#68b3d8" r="3">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.4166666666666667s"></animate>
                            </circle>
                            <circle cx="28.34936490538903" cy="37.50000000000001" fill="#68b3d8" r="3">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.3333333333333333s"></animate>
                            </circle>
                            <circle cx="37.499999999999986" cy="28.349364905389038" fill="#68b3d8" r="3">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.25s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s"></animate>
                            </circle>
                            <circle cx="49.99999999999999" cy="25" fill="#68b3d8" r="3">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.16666666666666666s"></animate>
                            </circle>
                            <circle cx="62.5" cy="28.349364905389034" fill="#68b3d8" r="3.48259">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                    begin="-0.08333333333333333s"></animate>
                            </circle>
                            <circle cx="71.65063509461096" cy="37.499999999999986" fill="#68b3d8" r="4.14926">
                                <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                    repeatCount="indefinite" begin="0s"></animate>
                                <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                    repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s"></animate>
                            </circle>
                        </svg>
                        <span fxFlex>Enregistrer</span>
                    </div>
                </button>
            </div>

            <div class="m-0 pt-4 pb-8 px-20" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="
                    (_data.categorie == 'busy' || (!eventForm.controls['completed'].value ||
                        (_data.event &&
                            eventForm.controls['completed'] !=
                                _data.event.completed))) &&
                    this._data.action === 'edit'
                ">
                <div style="display: flex;">
                    <ng-container *ngIf="(
                        _data.canEdit &&
                        (_data.codeLess ||
                            _data.condLess ||
                            (_data.categorie == 'busy') ||
                            deletable)
                            && 
                            allow_delete
                    )">
                        <button *accessControl="{ permissions: calendarPermissions[permissionsList['delete']]}"
                            mat-icon-button (click)="
                                    _matDialogRef.close(['delete', this._data.event.id])
                                " aria-label="SAVE">
                            <mat-icon style="color: red">delete_outline</mat-icon>
                        </button>
                    </ng-container>
                    <span style="margin: 0px 14px; text-align: left; max-width: 330px;">
                        {{this.deniedAction()}}
                    </span>
                </div>

                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="4px">
                    <button (click)="closeThis()" mat-stroked-button>
                        Annuler
                    </button>
                    <ng-container *ngIf="this._data.action === 'edit' && _data.canEdit">
                        <button *accessControl="{ permissions: calendarPermissions[permissionName], code_only_access: this.sub_type == 'code_only'}" mat-raised-button
                            (click)="saveEventt()" class="save-button mat-accent" [disabled]="
                            eventForm.invalid || eventForm.pristine || savingEvent
                        " aria-label="SAVE">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <svg *ngIf="savingEvent" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" style="
                                    margin: auto;
                                    background: rgba(0, 0, 0, 0);
                                    display: block;
                                " width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="75" cy="50" fill="#68b3d8" r="4.81593">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.9166666666666666s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.9166666666666666s"></animate>
                                    </circle>
                                    <circle cx="71.65063509461098" cy="62.5" fill="#68b3d8" r="4.51741">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.8333333333333334s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.8333333333333334s"></animate>
                                    </circle>
                                    <circle cx="62.5" cy="71.65063509461096" fill="#68b3d8" r="3.85074">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.75s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.75s">
                                        </animate>
                                    </circle>
                                    <circle cx="50" cy="75" fill="#68b3d8" r="3.18407">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.6666666666666666s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.6666666666666666s"></animate>
                                    </circle>
                                    <circle cx="37.50000000000001" cy="71.65063509461098" fill="#68b3d8" r="3">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.5833333333333334s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.5833333333333334s"></animate>
                                    </circle>
                                    <circle cx="28.34936490538903" cy="62.5" fill="#68b3d8" r="3">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.5s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.5s">
                                        </animate>
                                    </circle>
                                    <circle cx="25" cy="50" fill="#68b3d8" r="3">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.4166666666666667s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.4166666666666667s"></animate>
                                    </circle>
                                    <circle cx="28.34936490538903" cy="37.50000000000001" fill="#68b3d8" r="3">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.3333333333333333s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.3333333333333333s"></animate>
                                    </circle>
                                    <circle cx="37.499999999999986" cy="28.349364905389038" fill="#68b3d8" r="3">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.25s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="-0.25s">
                                        </animate>
                                    </circle>
                                    <circle cx="49.99999999999999" cy="25" fill="#68b3d8" r="3">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.16666666666666666s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.16666666666666666s"></animate>
                                    </circle>
                                    <circle cx="62.5" cy="28.349364905389034" fill="#68b3d8" r="3.48259">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="-0.08333333333333333s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                            begin="-0.08333333333333333s"></animate>
                                    </circle>
                                    <circle cx="71.65063509461096" cy="37.499999999999986" fill="#68b3d8" r="4.14926">
                                        <animate attributeName="r" values="3;3;5;3;3" times="0;0.1;0.2;0.3;1" dur="1s"
                                            repeatCount="indefinite" begin="0s"></animate>
                                        <animate attributeName="fill" values="#68b3d8;#68b3d8;#039be5;#68b3d8;#68b3d8"
                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s" begin="0s">
                                        </animate>
                                    </circle>
                                </svg>
                                <span fxFlex>Modifier</span>
                            </div>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!_data.available">
        <div class="dialog-content-wrapper">
            <mat-toolbar class="mat-accent m-0">
                <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="title dialog-title"></span>
                    <button mat-icon-button (click)="closeThis()" aria-label="Close dialog">
                        <mat-icon class="fail">close</mat-icon>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>
            <div fxLayoutAlign="center center">
                <h2 ngClass.xs="pl-8 pr-8" ngClass.sm="pl-8 pr-8" style="
                        padding: 24px;
                        white-space: pre-wrap;
                        padding-top: 2%;
                        padding-bottom: 2%;
                    ">
                    Pas de candidat disponible pour un(e) {{ this.title }}.
                    <br />Veuillez verifier la disponibilité de vos candidats
                </h2>
            </div>
        </div>
    </div>
</div>