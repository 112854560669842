import { Component, OnInit } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { LayoutService } from 'app/layout/layout.service';
import { take } from 'rxjs';

@Component({
  selector: 'top-nav-small',
  imports: [SearchComponent,MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatBadgeModule],
  templateUrl: './top-nav-small.component.html',
  styleUrls: ['./top-nav-small.component.scss']
})
export class TopNavSmallComponent implements OnInit {
  notificationCount: number = 0;
  selectedTab: number = 0;
  isSearchVisible: boolean = false;

  constructor(
        private _fuseSidebarService: FuseSidebarService,
        private layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    // Initialize notification count
    this.notificationCount = 3; // Example value
  }

  onSearchClick(): void {
    this.isSearchVisible = !this.isSearchVisible;
  }

  onSearchClose(): void {
    this.isSearchVisible = !this.isSearchVisible;
  }

  onFilterClick(): void {
    // Implement filter logic
  }

  onTabChange(index: number): void {
    this.selectedTab = index;
  }

  /**
       * Toggle sidebar open
       *
       * @param key
       */
    toggleSidebarOpen(key): void {
      if (!this._fuseSidebarService.getSidebar(key).opened) {
        this.layoutService.MarkQuestionsAsReaded().subscribe(() => {
        });
      }
      this._fuseSidebarService.getSidebar(key).toggleOpen();
      // susbscribe to toggleOpene quickPanel for close if from the intern of the panel 
      this.layoutService.quickPanelToggleOpen.pipe(take(1)).subscribe((res) => {
        if (res[0]) {
          this._fuseSidebarService.getSidebar(key).close();
        }
      });
    }
}
