import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Observable, of, switchMap } from "rxjs";
import { AuthentificationService } from "./authentification.service";

@Injectable({
  providedIn: "root",
})
export class NoauthGuard  {
  constructor(
    private service: AuthentificationService,
    private router: Router,
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.service.check()
      .pipe(
        switchMap((authenticated)=>{
          if (!authenticated) return of(true);
          else {
            this.router.navigate(["/dashboards/analytics"]);
            return of(false);
          }
        }));
  }
  
}
