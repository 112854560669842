import { GlobalService } from "./../global.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable, of, Subject } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  uri = environment.uriG;

  scrollTo$ : Subject<any> = new Subject();

  quickPanelToggleOpen$ : Subject<any> = new Subject();

  scrollToTopSource = new Subject<void>();

  scrollToTop$ = this.scrollToTopSource.asObservable();

  closeNotif : boolean = false;

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService,
    private deviceService: DeviceDetectorService,
  ) {}


  /**
     * Fetches unopened exam center notifications for the current user.
     * 
     * @function fetchUnopenedExamCenterNotifications
     * @returns {Observable<any>} An observable that emits the list of unopened exam center notifications.
     */
  fetchUnopenedExamCenterNotifications(): Observable<any> {
    return this.http.get<any>(`${this.uri}/api/notifications/exams-center-notification`);
  }

  /**
   * 
   * @function fetchUnopenedAppUpdateNotifications
   * @returns {Observable<any>} An observable that emits the list of unopened app update notifications.
   */
  fetchUnopenedAppUpdateNotifications(): Observable<any> {
    return this.http.get<any>(`${this.uri}/api/notifications/app-update`);
  }
    
  /**
     * Fetches the count of all undelivered or unread notifications for the logged-in user.
     * 
     * @function fetchUndeliveredAndUnreadNotificationCount
     * @returns {Observable<any>} An observable that emits the count of undelivered or unread notifications.
     */
  fetchUndeliveredAndUnreadNotificationCount(): Observable<any> {
    return this.http.get<any>(`${this.uri}/api/notifications/count`);
  }
    
  getNotifications(type: string, lastId?: string): any {
    return this.http.get<any>(`${this.uri}/api/notifications`, {
      params: { type, ...(lastId ? { lastId } : {}) },
    });
  }

  getCandidateIdForEventNotification(contentId: number, contentTable?: string): Observable<any> {
    return this.http.get<any>(`${this.uri}/api/notifications/candidate`, {
      params: { contentId: contentId.toString(), contentTable: contentTable },
    });
  }

  get scrollTo(): any {
    return this.scrollTo$.asObservable();
  }
    
  set scrollTo(offset:any) {
    this.scrollTo$.next(offset);
  }


  get quickPanelToggleOpen(): any {
    return this.quickPanelToggleOpen$.asObservable();
  }

  set quickPanelToggleOpen(show:any) {
    this.quickPanelToggleOpen$.next(show);
  }

    
  MarkQuestionsAsReaded(): any {
    return this.http.put<any>(
      `${this.uri}/api/notifications/questions`,
      {},
    );
  }

  notificationDelivered(): any {
    if (this._globalService.getUser().role === "superadmin") {
      return of([]);
    } else {
      return this.http.put<any>(
        `${this.uri}/api/notifications/delivered`,
        {},
      );
    }
  }

  notificationOpenedByType(type): any {
    return this.http.put<any>(
      `${this.uri}/api/notifications/opened/by-type/${type}`,
      {},
    );
        
  }

  notificationOpened(id): any {
    return this.http.put<any>(
      `${this.uri}/api/notifications/opened`,
      { ids:id },
    );
  }

  getCurrent(): any {
    if (this._globalService.getUser().role === "superadmin") {
      return of(this._globalService.getUser());
    } else {
      return this.http.get<any>(`${this.uri}/api/employees/current`);
    }
  }

  getTutorial(): any {
    return this.http.get<any>(`${this.uri}/api/agences/tutorialState`);
  }

  updateTutorial(video): any {
    return this.http.put<any>(
      `${this.uri}/api/agences/tutorialDone/${video}`,
      {},
    );
  }

  supprimerTutorial(): any {
    return this.http.delete<any>(
      `${this.uri}/api/agences/supprimerTutorial`,
    );
  }

  detectdevice(): any {
    return this.deviceService.getDeviceInfo().os;
  }

  triggerScrollToTop(): void {
    this.scrollToTopSource.next();
  }

  closeNotificationBar(): boolean {
    this.quickPanelToggleOpen$.next([true]);
    return true;
  }

  openNotificationBar(): boolean {
    this.quickPanelToggleOpen$.next([false]);
    return true;
  }

  getUserProfileImage(NotificationId): any {
    return this.http.get(`${this.uri}/api/notifications/user-image`, {
      params: { NotificationId: NotificationId },
      responseType: "arraybuffer"
    }); 
  }
}
