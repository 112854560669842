<mat-toolbar class="p-0 mat-elevation-z1 tphi">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
            <div
                class="toolbar-separator"
                *ngIf="!hiddenNavbar && !rightNavbar"
                fxHide.gt-md
            ></div>
            <div
                fxLayout="row"
                fxLayoutAlign="start center"
                *ngIf="horizontalNavbar"
            ></div>
            <button
                class="home"
                mat-icon-button
                (click)="refresh()">
                <mat-icon class="accent-200-fg home">refresh</mat-icon>
            </button>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-recette']}"
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="short-cut-button payment-btn mr-8"
                    (click)="addPayment()"
                    data-cy="add-candidat-payment"
                >
                    <mat-icon>add </mat-icon>
                    <span> خلاص مترشح </span>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-depense']}"
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="short-cut-button reccete-btn mr-8"
                    (click)="addDepense()"
                >
                    <mat-icon>add </mat-icon>
                    <span>مصروف </span>
                </button>
            </ng-container>
            <ng-container>
                <button
                    *accessControl="{ permissions: candidatePermissions['add-candidat']}"
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="short-cut-button candidat-btn mr-8"
                    (click)="addCandidat()"
                >
                    <mat-icon>add </mat-icon>
                    <span> مترشح </span>
                </button>
            </ng-container>
            <ng-container  *ngIf="user && sub_type!='code_only'">
                <button
                    fxHide.sm
                    fxHide.xs
                    mat-button
                    class="short-cut-button seance-btn"
                    (click)="choose()"
                    *accessControl="{ 
                        permissions: [
                            calendarPermissions['add-occuped-event'],
                            calendarPermissions['add-code-session'],
                            calendarPermissions['add-code-exam'],
                            calendarPermissions['add-conduit-session'],
                            calendarPermissions['add-conduit-exam'],
                        ],
                        oneIsTrue: true}"
                >
                <span>حصة </span>
                <mat-icon>add </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-recette']}"
                    fxHide.gt-sm
                    mat-mini-fab
                    fxLayoutAlign="center center"
                    class="pay-sm-btn mr-8"
                    (click)="addPayment()"
                >
                    <mat-icon>arrow_downward </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    *accessControl="{ permissions: comptabilityPermissions['add-depense']}"
                    fxHide.gt-sm
                    fxLayoutAlign="center center"
                    mat-mini-fab
                    class="rec-sm-btn mr-8"
                    (click)="addDepense()"
                >
                    <mat-icon>arrow_upward </mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button 
                    fxHide.gt-sm
                    *accessControl="{ permissions: candidatePermissions['add-candidat']}"
                    mat-mini-fab
                    fxLayoutAlign="center center"
                    class="cand-sm-btn mr-8"
                    (click)="addCandidat()"
                >
                    <mat-icon>person_add </mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="user && sub_type!='code_only'">
                <button
                    fxHide.gt-sm
                    *accessControl="{ 
                        permissions: [
                            calendarPermissions['add-occuped-event'],
                            calendarPermissions['add-code-session'],
                            calendarPermissions['add-code-exam'],
                            calendarPermissions['add-conduit-session'],
                            calendarPermissions['add-conduit-exam'],
                        ],
                        oneIsTrue: true}"
                    mat-mini-fab
                    fxLayoutAlign="center center"
                    class="sea-sm-btn"
                    (click)="choose()"
                >
                    <mat-icon>event_available </mat-icon>
                </button>
            </ng-container>
        </div>

        <div class="invite-box">
            <button mat-flat-button class="invite-btn" [routerLink]="'/referral'">
                <mat-icon class="giftcard-icon">card_giftcard</mat-icon>
                <!-- Inviter et gagner -->
            </button>
        </div>

        <div
            class="tphi"
            fxFlex="0 1 auto"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <button
                [matMenuTriggerFor]="userMenu"
                class="user-button"
            >
                <img 
                    class="piclass"
                    src="{{ urlImage }}"
                    alt="ton image"
                />
            </button>

            <button mat-icon-button class="settings-btn icon-button" [matMenuTriggerFor]="settingsMenu">
                <mat-icon class="icon">settings</mat-icon>
            </button>

            <button mat-icon-button class="help-btn icon-button" (click)="goToHelp()">
                <mat-icon class="icon">help</mat-icon>
            </button>
            <button mat-icon-button class="help-btn icon-button" (click)="toggleCurrentTheme()">
                <mat-icon *ngIf="currentTheme == 'dark'" class="icon">light_mode</mat-icon>
                <mat-icon *ngIf="currentTheme == 'light'" class="icon">dark_mode</mat-icon>
            </button>

            
            <button
                mat-icon-button
                class="icon-button"
                (click)="toggleSidebarOpen('quickPanel'); opened(); scrollUp()"
            >
                <mat-icon
                    class="icon"
                    [matBadgeHidden]="badgeHidden"
                    matBadge="{{ notNumber }}"
                >
                    notifications_none</mat-icon
                >
            </button>
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>

<mat-menu #userMenu="matMenu" xPosition="before">
    <button mat-menu-item [routerLink]="'/administration/account'">
        <mat-icon>account_circle</mat-icon>
        <span>Mon compte</span>
    </button>

    <button mat-menu-item class="" (click)="authService.logOut()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
    </button>
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before">
    <ng-container *ngFor="let item of settingsItems">
        <button 
            *ngIf="item.show"
            mat-menu-item 
            [routerLink]="item.route ? item.route : null"
            (click)="item.callback ? item.callback() : null"
        >
            <mat-icon>{{ item.icon }}</mat-icon>
            <span>{{ item.label }}</span>
        </button>
    </ng-container>

</mat-menu>
