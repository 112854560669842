import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-planning-print",
  templateUrl: "./planning-print.component.html",
  styleUrls: ["./planning-print.component.scss"],
  standalone: false,
})
export class PlanningPrintComponent implements OnInit {
  @Input("data") data;

  ngOnInit(): void {}

  getType(value): any {
    if (value === "codeLess") return "seance code";
    else if (value === "conduiteLess") return "seance conduite";
    else if (value === "codeEx") return "examen code";
    else return "examen conduite";
  }
}
