<div class="top-app-bar-container">
    <div class="header">
        <div class="leading-icon">
            <button (click)="toggleSidebarOpen('navbar')" mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <div class="headline">
            <h1 class="title-large">Dashboard</h1>
        </div>
        <div class="trailing-icons">
            <button mat-icon-button (click)="onSearchClick()">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button (click)="onFilterClick()">
                <mat-icon>filter_list</mat-icon>
            </button>
            <button mat-icon-button [matBadge]="notificationCount" matBadgeColor="accent">
                <mat-icon>notifications</mat-icon>
            </button>
        </div>
    </div>
    <!-- <div class="tabs">
        <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedIndexChange)="onTabChange($event)">
            <mat-tab label="View all"></mat-tab>
            <mat-tab label="Mode code"></mat-tab>
        </mat-tab-group>
    </div> -->
</div>
<!-- <aep-search 
    [visible]="isSearchVisible"
    (visibleChange)="onSearchClose()">
</aep-search> -->