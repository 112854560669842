import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-image-popup",
  templateUrl: "./image-popup.component.html",
  styleUrls: ["./image-popup.component.scss"],
  standalone: false,
})
export class ImagePopupComponent implements OnInit {
  qrCode:string ="";

  constructor(
    public dialogRef: MatDialogRef<ImagePopupComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.qrCode=this.data?.qrCode;
    console.log('---', this.data)
  }

  close(): void {
    this.dialogRef.close();
  }
}
