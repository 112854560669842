<div id="event-choice" class="container" >
    <div class="close-container"> 
        
          <p style="text-align: center; font-size: 16px">
            <strong> 
                Choisir le type d'évenement 
            </strong>
          </p>
          <button (click)="matDialogRef.close()"  mat-mini-fab color="white" aria-label="Close dialog">
              <mat-icon>close</mat-icon>
          </button>
    </div>

    <div class="event-options-container">
    
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-occuped-event']}" mat-button mat-raised-button style='background-color: black;'
                (click)="matDialogRef.close('busy')">
                <mat-icon style="color: white;margin-bottom: 2px;">event_busy
                </mat-icon>
                Occupé
            </button>
           
    
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-code-session']}" mat-button mat-raised-button style='background-color: #00AA81;'
                (click)="matDialogRef.close('codeLess')">
                Séance Code
            </button>
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-code-exam']}" mat-button style='background-color: #e04668;' mat-raised-button color="succes"
                (click)="matDialogRef.close('codeEx')">
                Examen Code
            </button>
    
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-conduit-session']}" mat-button mat-raised-button style='background-color: #6939B2;' cdkFocusInitial
            (click)="matDialogRef.close('conduiteLess')"
            >
                Séance Conduite
            </button>
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-conduit-session']}" mat-button mat-raised-button style='background-color: #1F6378;'
            (click)="matDialogRef.close('conduiteRecyclage')"
            >
                Séance Recyclage
            </button>
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-conduit-exam']}" mat-button mat-raised-button style='background-color: #000F89;'
            (click)="matDialogRef.close('conduiteEx')"
            >
                Examen Conduite
            </button>
    
            <button class="event-button" *accessControl="{ permissions: calendarPermissions['add-conduit-exam']}" mat-button mat-raised-button style='background-color: #2D9CDB;'
            (click)="matDialogRef.close('conduiteExParc')"
            >
                Examen Manoeuvre
            </button>
    
    </div>

    
    <!-- <mat-menu #menu="matMenu">
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteLessCircuit')"
        >Circuit</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteLessParc')"
        >Parc</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteLess')"
        >Non détérminé</button>
      </mat-menu>
      <mat-menu #menu1="matMenu">
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteExCircuit')"
        >Circuit</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteExParc')"
        >Parc</button>
        <button mat-menu-item
        (click)="matDialogRef.close('conduiteEx')"
        >Non détérminé</button>
      </mat-menu> -->

</div>