
<div class="container">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row
            fxFlex
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <span class="title dialog-title">Impression planning</span>
            <button
                mat-icon-button
                (click)="onCancel()"
                aria-label="Close dialog"
            >
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
<div fxLayout="column" class="p-24 mb-12 width" >
    <div fxLayout="row" fxLayoutGap="10px" class="picker">
        <mat-form-field appearance="outline">
            <mat-label>debut</mat-label>
            <input
                matInput
                [matDatepicker]="begin"
                [(ngModel)]="period.begin"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="begin"
            ></mat-datepicker-toggle>
            <mat-datepicker #begin></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>fin</mat-label>
            <input matInput [matDatepicker]="end" [(ngModel)]="period.end" />
            <mat-datepicker-toggle
                matSuffix
                [for]="end"
            ></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
        </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
        <mat-label>type</mat-label>
        <mat-select [(ngModel)]="type.selected" multiple>
            <ng-container *ngFor="let item of type.types">
                <mat-option class="oneone" [value]="item.value">{{ item.name }}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field  appearance="outline">
        <mat-label>moniteur</mat-label>
        <mat-select [(ngModel)]="moniteur.selected">
            <ng-container *ngFor="let item of moniteur.moniteurs">
                <mat-option class="oneone" [value]="item">{{ item.username }}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>candidat</mat-label>
        <mat-select [(ngModel)]="candidat.selected">
            <ng-container *ngFor="let item of candidat.candidats">
                <mat-option class="oneone" [value]="item"
                    >{{ item.nom }} {{ item.prenom }}</mat-option
                >
            </ng-container>
        </mat-select>
    </mat-form-field>
</div>
<mat-divider></mat-divider>

<div  fxLayout="row" fxLayoutAlign="center center" class="p-24">
    <button  mat-flat-button (click)="onNoClick()">Annuler</button>
    <button  mat-flat-button (click)="onYesClick()" class="mat-accent" >Imprimer</button>
</div>
</div>
