import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import {
  Router,
  NavigationEnd,
} from "@angular/router";


import {
  filter,
  map,
  mergeMap,
} from "rxjs/operators";


import { PAGE_TITLES } from "./page-titles";
import { dialogComponentsMap } from "./dialogs-map";

@Injectable({
  providedIn: "root",
})
export class SetTitleService {
  private originalTitle: string | null = null;

  constructor(
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog, 
  ) {}


  init():any {
    this.listenToDialogOpenings();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.url), // Get the current URL path
      map(url => {
        // Split the URL into segments
        const segments = url.split("/").filter(segment => segment.length > 0); 
        // Generate possible paths to check for titles, including the full path and progressively shorter paths
        const possiblePaths = [];

        for (let i = segments.length; i >= 0; i--) {
          const path = segments.slice(0, i).join("/");
          if (i !== segments.length)
            possiblePaths.push(path.endsWith("/") ? path : path + "/");
          possiblePaths.push(path);
        }
                
        return possiblePaths;
      }),
      mergeMap(paths => {
        // Find the first path with a title
        const title1 = paths
          .map(path => PAGE_TITLES[path] || null)
          .find(title => title !== null) || PAGE_TITLES.default; // Use default title if none found
        return [title1];
      }),
    ).subscribe(title1 => {
      if (title1) {
        this.titleService.setTitle(title1);
      }
    });
  }


  setTitle():void {
    const url = this.router.url;
    const segments = url.split("/").filter(segment => segment.length > 0);
    const possiblePaths = [];

    for (let i = segments.length; i >= 0; i--) {
      const path = segments.slice(0, i).join("/");
      if (i !== segments.length)
        possiblePaths.push(path.endsWith("/") ? path : path + "/");
      possiblePaths.push(path);
    }

    const title2 = possiblePaths
      .map(path => PAGE_TITLES[path] || null)
      .find(title => title !== null) || PAGE_TITLES.default;

    if (title2) {
      this.titleService.setTitle(title2);
    }
  }


  listenToDialogOpenings(): void {
    this.dialog.afterOpened.subscribe((dialogRef: MatDialogRef<any>) => {
        
      const dialogComponent = Object.keys(dialogComponentsMap).find(key => dialogComponentsMap[key]
        .component === dialogRef.componentInstance.constructor);
  
      if (dialogComponent) {
        // Save the current title before changing it
        this.originalTitle = document.title;
  
        // Set the new title based on the dialog's title property
        const newTitle = dialogComponentsMap[dialogComponent].title || this.originalTitle;
        this.titleService.setTitle(newTitle);
            
    
        dialogRef.afterClosed().subscribe(() => {
          setTimeout(() => {
            this.setTitle();
          }, 300);
        });
      }
    });
  }
}