import { Subject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class PrintService {
  public _PrintPlanning: Subject<any>;

  public _printPaiement: Subject<any>;

  public _print: Subject<any>;

  public _printAgencyInvoice: Subject<any>;

  public _printAgencyRecu: Subject<any>;

  constructor() {
    this._print = new Subject<any>();
    this._printPaiement = new Subject<any>();
    this._PrintPlanning = new Subject<any>();
    this._printAgencyInvoice = new Subject<any>();
    this._printAgencyRecu = new Subject<any>();

  }

  printPaiement(data: any): void {
    this._printPaiement.next(data);
  }

  print(data: any): void {
    this._print.next(data);
  }

  printPlanning(data: any): void {
    this._PrintPlanning.next(data);
  }

  printAgencyInvoice(data:any): void {
    this._printAgencyInvoice.next(data);
  }

  printAgencyRecu(data:any): void {
    this._printAgencyRecu.next(data);
  }
}
