<div class="container">
    <div
        class="dialog-content-wrapper"
    >
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title" ngClass.xs="titlefontxs">{{ data?.isGeneral ? "Contactez-nous" :"Réclamation"}}</span>
                <button class="close-button" mat-icon-button (click)="onCancel()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <ng-container>
            <div 
            style="overflow-y: auto;"
            class="width p-16"
            fusePerfectScrollbar>
                <form [formGroup]="reclamationForm" (ngSubmit)="submitForm()">
                    <div>
                        <p class="description-parag" *ngIf="data?.isGeneral">Vous pouvez écrire ce que vous voulez en français ou en arabe. 
                            Notre équipe vous répondra dans les plus brefs délais. Nous travaillons dur pour vous offrir 
                            le meilleur service possible et comptons sur vos retours pour nous améliorer. 😊
                        </p>
                        <p><b>{{ data?.isGeneral ? 'Je veux' : 'Détails de la réclamation'}} :</b></p>
                        <p *ngIf="!data?.isGeneral">Expliquez pourquoi ce blocage est incorrecte :</p>
                        <mat-select formControlName="reason" class="select-box" *ngIf="data?.isGeneral">
                            <mat-option *ngFor="let reason of reasons" [value]="reason">
                                {{ reason }}
                            </mat-option>
                        </mat-select>
                        <textarea
                            placeholder="Saisissez votre message ici..."
                            formControlName="message">
                        </textarea>
                    </div>
                </form>
            </div>
        </ng-container>
        <mat-divider></mat-divider>
        <div class="m-0 p-16" fxLayout="column" dir="rtl" fxLayoutAlign="start none">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
                <div mat-dialog-actions class="flex" dir="rtl" fxLayoutAlign="start center">
        
                    <button
                        mat-flat-button
                        type="submit"
                        (click)="submitForm()"
                        [ngClass]="{
                        'submit-button-active': !reclamationForm.invalid && !isLoading,
                        'submit-button-disabled': reclamationForm.invalid || isLoading
                        }"
                        [disabled]="reclamationForm.invalid || isLoading">
                        <ng-container *ngIf="!isLoading">
                            Envoyer
                        </ng-container>
                        <ng-container *ngIf="isLoading">
                            <div class="mat-spinner-container">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </div>
                        </ng-container>
                    </button>
                    <button mat-stroked-button class="cancel-button" (click)="onCancel()" [disabled]="reclamationForm.invalid || isLoading">Annuler</button>
                </div>
            </div>
        </div>
    </div>
</div>
  