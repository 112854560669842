import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PermissionConfig } from "app/core/permission/permissions.config";
@Component({
  selector: "app-ressource-form",
  templateUrl: "./ressource-form.component.html",
  styleUrls: ["./ressource-form.component.scss"],
  standalone: false,
})
export class RessourceFormComponent implements OnInit {
  RessourceForm: UntypedFormGroup;

  ressources = [];

  check: boolean;

  add: boolean;

  typesNotSelected = true;

  checked: boolean;

  administrationPermissions = PermissionConfig.administration;
    
  constructor(
    public fb: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<RessourceFormComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) { }

  ngOnInit():void {
    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };
    history.pushState(modalState, null);

    this.ressources = this._data.data;
    // console.log(this._data);
    if (this._data.row === undefined) {
      this.add = true;
      this.initForm();
    } else {
      this.initFormEdit();
      this.RessourceForm.get("label").setValue(this._data.row.label);
      if (this._data.row.ressourceid !== null) {
        this.RessourceForm.get("ressourceid").setValue(
          this._data.row.ressourceid,
        );
        this.check = true;
        this.checked = true;
        this.add = false;
      }
      this.RessourceForm.get("id").setValue(this._data.row.id);
    }
  }

  ngOnDestroy():void {
    if (window.history.state.modal) {
      history.go(-1);
    }
  }

  hi():void {
    if (this.check === undefined) {
      this.check = true;
    } else if (this.check === true) {
      this.check = false;
    } else if (this.check === false) {
      this.check = true;
    }
  }

  initForm():void {
    this.RessourceForm = this.fb.group({
      label: [null],
      ressourceid: [null], //!set disabled
    });
  }

  initFormEdit():void {
    this.RessourceForm = this.fb.group({
      label: [null],
      ressourceid: [null], //!set disabled
      id: [null],
    });
  }

  save():void {
    if (this.check === false) {
      this.RessourceForm.controls.ressourceid.setValue(null);
      this._matDialogRef.close(this.RessourceForm.getRawValue());
    } else this._matDialogRef.close(this.RessourceForm.getRawValue());

  }
}
