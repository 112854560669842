import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";

@NgModule({
  declarations: [
    FuseConfirmDialogComponent,
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    CommonModule,

  ],

})
export class FuseConfirmDialogModule {
}
