
// this dict define the title for each route
// for all the paths not should not started with / 
// if path ended with / it mean it could be any thing after this / 
// exmaple candidats/:id we only put candidats/ and then we identify this route 
// if there is any other route started with /candidats then an other path and this path has an other title
// we should only put it in the dict and this will not confuse the other paths example candidats/
// :id has Candidats details as a title
// and candidats/new  has Ajouter un candidate as title 
// the order has no effect here


export const PAGE_TITLES = {
  "auth/servererror": "Erreur serveur | autoecoleplus.tn",
  "auth/unauthorized": "Non autorisé | autoecoleplus.tn",
  "auth/notfound": "Non trouvé | autoecoleplus.tn",
  "auth/agenceconfirmation": "Confirmation d'agence | autoecoleplus.tn",
  "auth/blocked": "Paiement en Attente | autoecoleplus.tn",
  "auth/register": "Bienvenue à autoecoleplus.tn",
  "auth/login": "Connexion | autoecoleplus.tn",
  "nointernet": "Pas d'internet | autoecoleplus.tn",
  "help/knowledge": "Aide | autoecoleplus.tn",
  "list-exams": "Centres d'examen | autoecoleplus.tn",
  "administration/payment/invoice": "Détails Facture | autoecoleplus.tn",
  "administration/payment/fixed": "Recharge Solde | autoecoleplus.tn",
  "administration/payment/subscription": "Paiement Abonnement | autoecoleplus.tn",
  "administration/payment-choice": "Choix d'abonnement | autoecoleplus.tn",
  "administration/contract": "Facturation | autoecoleplus.tn",
  "ressources/locaux": "Ressources et locaux | autoecoleplus.tn",
  "administration/equipe/new": "Ajouter un nouvel employé | autoecoleplus.tn",
  "administration/equipe": "Équipe | autoecoleplus.tn",
  "ressources/vehicules": "Véhicules | autoecoleplus.tn",
  "administration/compte": "Détails Compte | autoecoleplus.tn",
  "administration/agence": "Mon École | autoecoleplus.tn",
  "finance/revenus": "Revenus | autoecoleplus.tn",
  "finance/depenses": "Dépenses | autoecoleplus.tn",
  "finance/recettes": "Recettes | autoecoleplus.tn",
  "reservations-test": "Réservation Test | autoecoleplus.tn",
  "reservations": "Réservation | autoecoleplus.tn",
  "calendar": "Calendrier | autoecoleplus.tn",
  "code/learning": "Séries passées | autoecoleplus.tn",
  "code/questions": "Questions | autoecoleplus.tn",
  "online-services": "Services en ligne | autoecoleplus.tn",
  "candidats/addlist": "Ajout Candidats Multiples | autoecoleplus.tn",
  "candidats/new": "Ajout Candidat | autoecoleplus.tn",
  "candidats/": "Détails Candidat | autoecoleplus.tn",
  "candidats": "Candidats | autoecoleplus.tn",
  "dashboards/analytics": "Accueil | autoecoleplus.tn",
  "referral": "Parrainage | autoecoleplus.tn",
  "welcome": "Welcome | autoecoleplus.tn",
  "default": "Application de gestion auto-école Tunisie | autoecoleplus.tn",
};