import { Component, OnInit, Inject } from "@angular/core";
import * as bcrypt from "bcryptjs";

import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { UntypedFormGroup, UntypedFormBuilder, ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";
import { CandidatService } from "../../candidats/candidat/candidat.service";
@Component({
  selector: "app-changermotpasse",
  templateUrl: "./changermotpasse.component.html",
  styleUrls: ["./changermotpasse.component.scss"],
  standalone: false,
})
export class ChangermotpasseComponent implements OnInit {
  password_icon: "visibility" | "visibility_off" = "visibility";

  password_type: "password" | "text" = "password";

  newpassword: any = "";

  checked = 0;

  type: any;

  ChangerUsernameForm: UntypedFormGroup;

  changePasswordForm: UntypedFormGroup;

  error: any;

  constructor(
    public dRef: MatDialogRef<ChangermotpasseComponent>,
    public fb: UntypedFormBuilder,
    public service: CandidatService,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onCancel(): void {
    this.data.confirm = 0;
    this.dRef.close();
  }

  initChangerUsernameForm(): void {
    this.ChangerUsernameForm = this.fb.group({
      username: [null],
    });
  }

  initChangePasswordForm(): void {
    this.changePasswordForm = this.fb.group({
      ancienpassword: ["", this.checkPasswords],
      newpassword: [null],
      confirmpassword: [null],
      disconnect_devices: [true],
    });
  }

  markTouched(event: any): void {
    if (event.target.value) {
      this.changePasswordForm.controls.newpassword.markAsTouched();
    }
  }

  check(event: any): void {
    if (event.target.value && this.changePasswordForm) {
      if (
        this.changePasswordForm.controls.confirmpassword.value === this.changePasswordForm.controls.newpassword.value &&
                this.changePasswordForm.controls.newpassword.value !== null &&
                this.changePasswordForm.controls.confirmpassword.value !== null &&
                bcrypt.compareSync(this.changePasswordForm.controls.ancienpassword.value, this.data.password)
      ) {
        if (
          this.changePasswordForm.controls.confirmpassword.value.length !== 0 &&
                    this.changePasswordForm.controls.newpassword.value.length !== 0
        ) {
          this.checked = 1;
        } else {
          this.checked = 0;
        }
      } else {
        this.checked = 0;
      }
    }
  }

  ngOnInit(): void {
    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal : true,
      desc : "fake state for our modal",
    };
    history.pushState(modalState, null);

    this.type = this.data.type;
    console.log("this.type", this.type);
    if (this.type === "telLogin") {
      this.initChangerUsernameForm();
    } else {
      this.initChangePasswordForm();
    }
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) {
      history.go(-1);
    }
  }

  checkPasswords: ValidatorFn = (control: AbstractControl):  ValidationErrors | null => { 
    const ancienpassword = control.value;
    if (this.data.password) return bcrypt.compareSync(ancienpassword, this.data.password) ? null : { notSame: true };
    else return { notSame:true };
    // return pass === confirmPass ? null : { notSame: true }
  };

  changeUserName(): void {
    this.service.checkUsername(this.ChangerUsernameForm.controls.username.value).subscribe(res => {
      if (res.length === 0 || this.ChangerUsernameForm.controls.username.value === this.data.oldUsername) {
        this.service.changeUserUsername(
          this.data.id, 
          this.ChangerUsernameForm.controls.username.value,
        )
          .subscribe(() => {
            this.dRef.close("succes");
          });
      } else {
        this.error = true;
      }
    });
  }

  password_icon_click(): void {
    if (this.password_icon === "visibility") {
      this.password_icon = "visibility_off";
      this.password_type = "text";
    } else {
      this.password_icon = "visibility";
      this.password_type = "password";
    }
  }
}
