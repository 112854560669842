import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PaymentCDialogComponent } from "./payment-cdialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { FuseSharedModule } from "@fuse/shared.module";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { DirectivesModule } from "app/helper/directives/directives.module";

@NgModule({
  declarations: [PaymentCDialogComponent],
  imports: [
    DirectivesModule,
    MatDividerModule,
    MatToolbarModule,
    CommonModule,
    MatMenuModule,
    FuseSharedModule,
    MatSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    NgxMatSelectSearchModule,
    MatInputModule,
  ],
})
export class PaymentCdialogModule {}
