import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReservationDialogComponent } from "./reservation-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FuseConfirmDialogModule } from "@fuse/components";
import { FuseSharedModule } from "@fuse/shared.module";
//import { NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";



@NgModule({
  declarations: [ReservationDialogComponent],
  imports: [
    FlexLayoutModule,
    MatDividerModule,
    MatCheckboxModule,
    MatMenuModule,
    NgxMatTimepickerModule,
    CommonModule,
    FuseSharedModule,
    // NgbModule,
    // NgbDatepickerModule,
    FuseConfirmDialogModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMatSelectSearchModule,
  ],
  exports: [ReservationDialogComponent],
})
export class ReservationDialogModule { }
