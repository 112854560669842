import { Injectable } from '@angular/core';
import { BehaviorSubject , fromEvent } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

export type ViewportSize = 'small' | 'medium' | 'large';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  private readonly BREAKPOINT_MEDIUM = 768;
  private readonly BREAKPOINT_LARGE = 1200;
  


  private viewportSize = new BehaviorSubject<ViewportSize>(this.getCurrentViewportSize());
  public viewportSize$ = this.viewportSize.asObservable();
  
  private innerWidth = new BehaviorSubject<number>(window.innerWidth);
  public innerWidth$ = this.innerWidth.asObservable();
  
  constructor() {
    this.setupResizeListener();
  }
  
  private setupResizeListener(): void {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        startWith(null)
      )
      .subscribe(() => {
        const width = window.innerWidth;
        this.innerWidth.next(width);
        this.viewportSize.next(this.getCurrentViewportSize());
      });
  }
  
  private getCurrentViewportSize(): ViewportSize {

    const width = typeof window !== 'undefined' ? window.innerWidth : 1024;

    
    if (width < this.BREAKPOINT_MEDIUM) {
      return 'small';
    } else if (width < this.BREAKPOINT_LARGE) {
      return 'medium';
    } else {
      return 'large';
    }
  }
  
  public get currentSize(): ViewportSize {
    return this.viewportSize.value;
  }
  
  public get currentWidth(): number {
    return this.innerWidth.value;
  }
}