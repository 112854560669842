import { CandidatService } from "./../../../candidats/candidat/candidat.service";
import { Subject, take, takeUntil } from "rxjs";
import { TypesPopupComponent } from "./../types-popup/types-popup.component";
import { depenseFamilles } from "./../../../../../global";
import { DepencesService } from "./../depences.service";
import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { GlobalService } from "app/global.service";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SuccesSnakBarComponent } from "app/main/components/succes-snak-bar/succes-snak-bar.component";
import { ErreurService } from "app/main/authentification/erreur.service";
import { ReloadDialogService } from "@fuse/services/reload-dialog.service";
import { PermissionConfig } from "app/core/permission/permissions.config";

@Component({
  selector: "app-depence-form",
  templateUrl: "./depence-form.component.html",
  styleUrls: ["./depence-form.component.scss"],
  standalone: false,
})
export class DepenceFormComponent implements OnInit, OnDestroy {
  editfamille: any;

  depenseForm: UntypedFormGroup;

  vehicles = [];

  candidats = [];

  ressources = [];

  agents = [];

  types = [];

  showVehicle = false;

  showCandidat = false;

  showAgents = false;

  showResources = false;

  action: any;

  NotModified: any;

  role: any;

  today = new Date();

  confirmDialogRef: any;

  _unsubscribeAll: Subject<any>;

  paymentPermissions = PermissionConfig.comptability;


  familles = depenseFamilles;

  typesNotSelected = true;

  addingDepense = false;

  constructor(
    private _reloadDialogService: ReloadDialogService,
    private errService: ErreurService,
    private snackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private dialog: MatDialog,
    public service: DepencesService,
    public candidatService: CandidatService,
    public fb: UntypedFormBuilder,
    private globalService: GlobalService,
    public _matDialogRef: MatDialogRef<DepenceFormComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) {
    _matDialogRef.disableClose = true;
  }

  ngOnInit() :void {

    //?push fake state to navigate to when button back is clicked
    const modalState = {
      modal: true,
      desc: "fake state for our modal",
    };
    history.pushState(modalState, null);

    moment.locale("fr");
    this._unsubscribeAll = new Subject<any>();
    this.role = this.globalService.getUser().role;
    if (this.role === "moniteur") {
      this.familles = [];
      depenseFamilles.forEach((element) => {
        this.familles.push(element);
      });
      this.familles.splice(2, 1);
    }
    this.action = this._data.action;
    this.agents = this._data.agents;
    if (this._data.action === "new") {
      this.service.getAllVehicules().subscribe((res) => {
        this.vehicles = res;
      });
      this.service.getAllRessources().subscribe((res) => {
        this.ressources = res;
      });
      this.candidatService.getCandidats().subscribe((res: any) => {
        this.candidats = res;
      });
      this.initForm();
    } else {
      this.ressources = this._data.ressources;
      this.vehicles = this._data.vehicles;
      this.candidats = this._data.candidates;
      this.editfamille = JSON.parse(JSON.stringify(this._data.row));
      if (this._data.row.famille === "Véhicules") {
        this.editfamille.famille = 0;
        this.showVehicle = true;
      } else if (this._data.row.famille === "Candidats") {
        this.editfamille.famille = 1;
        this.showCandidat = true;
      } else if (this._data.row.famille === "Personnelles") {
        this.editfamille.famille = 2;
        this.showAgents = true;
      } else if (this._data.row.famille === "Locaux") {
        this.editfamille.famille = 3;
        this.showResources = true;
      } else if (this._data.row.famille === "Autre") {
        this.editfamille.famille = 4;
      }
      this.initeditForm();
      if (this._data.row.ressource === "Supprimer") {
        if (this._data.row.famille === "Locaux") {
          this.ressources = [{ id: 1, label: "Ressource supprimé" }];
          this.depenseForm.controls.ressourceId.setValue(1);
          this.NotModified = true;
          this.depenseForm.disable();
        }
        if (this._data.row.famille === "Véhicules") {
          this.vehicles = [{ id: 1, nom: "Vehicule supprimé" }];
          this.depenseForm.controls.vehiculeId.setValue(1);
          this.NotModified = true;
          this.depenseForm.disable();
        }
        if (this._data.row.famille === "Personnelles") {
          this.agents = [{ id: 1, username: "Agent supprimé" }];
          this.depenseForm.controls.effectPar.setValue(1);
          this.NotModified = true;
          this.depenseForm.disable();
        }
        if (this._data.row.famille === "Candidats") {
          this.candidats = [{ id: 1, nom: "Candidat supprimé" }];
          this.depenseForm.controls.candidatId.setValue(1);
          this.NotModified = true;
          this.depenseForm.disable();
        }
      }

      if (this._data.row.effectParUserName === "Agent supprimé") {
        this.NotModified = true;
        this.depenseForm.disable();
      }
      this.depenseForm.get("type").enable();
      this.service
        .getSpeTypes(this.editfamille.famille)
        .subscribe((res) => {
          (res as []).forEach((element) => {
            this.types.push(element);
          });
        });
    }

    this.service
      .typeDepadded()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((type) => {
        const value = parseInt(type.famille, 10);
        this.depenseForm.controls.famille.setValue(value);
        this.familleChange(value, type.label);
      });

    this.service
      .typeDepdeleted()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.depenseForm.controls.famille.setValue(null);
        this.depenseForm.controls.type.setValue(null);
        this.showVehicle = false;
        this.showCandidat = false;
        this.showAgents = false;
        this.showResources = false;
      });
  }

  ngOnDestroy():any {
    if (window.history.state.modal) {
      history.go(-1);
    }

    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  initForm():void {
    this.depenseForm = this.fb.group({
      montant: [null, [Validators.required, Validators.min(0)]], //, Validators.pattern(/^[0-9]+(\.[0-9]{3})?$/)]],
      famille: [null, [Validators.required]],
      effectPar: [null],
      type: [{ value: null, disabled: true }],
      ressourceId: [null],
      vehiculeId: [null],
      candidatId: [null],
      date: [new Date()], //!set disabled
      comment: [null],

    });
  }

  initeditForm():void {

    this.types = this.familles[this.editfamille.famille].defaultTypes;
    this.depenseForm = this.fb.group({
      montant: [
        this._data.row.montant,
        [Validators.required, Validators.min(1)],
      ],
      famille: [this.editfamille.famille],
      effectPar: [this._data.row.idAgent],
      type: [this._data.row.type],
      ressourceId: [this._data.row.ressourceId],
      vehiculeId: [this._data.row.vehiculeId],
      candidatId: [this._data.row.candidatId],
      date: [moment(this._data.row.date)], //!set disabled
      id: [this._data.row.id],
      comment: [this._data.row.comment],
    });
  }

  dinedAction():any {
    const permissionName = {
      "add": "ajouter",
      "edit": "modifier",
      "delete": "supprimer",
    };
    const accessList = this.globalService.getPermission();
    const paymentPermissions = accessList.filter(perm => perm.name === "depense" && perm.access_granted === false);
    if (paymentPermissions.length === 0) return "";
    let actionString = "Vous n'etes pas autorisé à";
    const lastPermission = paymentPermissions[paymentPermissions.length - 1];
    for (const perm of paymentPermissions) {
      actionString += (perm === lastPermission) ? " et" : "";
      actionString += ` ${permissionName[perm.action_name]}`;
    }
    return actionString + " les dépenses";
  }

  logui(val) :void {
    console.log("val", val);
  }

  validateAmount(event) :void {
    console.log("EV + key", event.target.value + event.key);
    console.log("EV", event.target.value);
    console.log("EV", event);
    console.log("this.depenseForm.controls", this.depenseForm.controls.montant.value + event.key);
    console.log("this.depenseForm.controls", this.depenseForm.controls.montant.value);
  }

  familleChange(value, type?) :void {
    this.depenseForm.get("type").enable();
    this.service.getSpeTypes(value).subscribe((res) => {
      this.types = [];

      this.familles
        .find((element) => value === element.val)
        .defaultTypes.forEach((element) => {
          this.types.push({
            label: element.label,
          });
        });
      (res as any[]).forEach((element) => {
        this.types.push({
          label: element.label,
        });
      });
      if (type !== undefined) {
        const v = this.types.find((x) => x.label === type);
        if (v) this.depenseForm.controls.type.setValue(v.label);
      }
    });

    this.depenseForm.controls.type.setValue(null);
    this.depenseForm.controls.type.markAsUntouched();

    this.changeForm(value);
  }

  changeForm(value) :void {
    if (value === 1) {
      this.showAgents = false;
      this.showResources = false;
      this.showVehicle = false;
      this.showCandidat = true;
      this.depenseForm.controls.candidatId.setValidators([
      ]);
      this.depenseForm.controls.candidatId.markAsUntouched();

      this.depenseForm.controls.ressourceId.setValidators([]);
      this.depenseForm.controls.vehiculeId.setValidators([]);
      this.depenseForm.controls.effectPar.setValidators([]);

      this.depenseForm.controls.effectPar.setValue(null);
      this.depenseForm.controls.ressourceId.setValue(null);
      this.depenseForm.controls.vehiculeId.setValue(null);
      this.depenseForm.controls.candidatId.setValue(null);

      if (this.candidats.length === 1) {
        this.depenseForm
          .get("candidatId")
          .setValue(this.candidats[0].id);
      }

    } else if (value === 0) {
      this.showVehicle = true;
      this.showAgents = false;
      this.showResources = false;
      this.showCandidat = false;
      this.depenseForm.controls.vehiculeId.setValidators([
        Validators.required,
      ]);
      this.depenseForm.controls.vehiculeId.markAsUntouched();

      this.depenseForm.controls.effectPar.clearValidators();
      this.depenseForm.controls.candidatId.clearValidators();
      this.depenseForm.controls.ressourceId.clearValidators();

      this.depenseForm.controls.effectPar.setValue(null);
      this.depenseForm.controls.ressourceId.setValue(null);
      this.depenseForm.controls.vehiculeId.setValue(null);
      this.depenseForm.controls.candidatId.setValue(null);
      if (this.vehicles.length === 1) {
        this.depenseForm.controls.vehiculeId.setValue(
          this.vehicles[0].id,
        );
      }
    } else if (value === 2) {
      this.showAgents = true;
      this.showResources = false;
      this.showVehicle = false;
      this.showCandidat = false;
      this.depenseForm.controls.effectPar.setValidators([
        Validators.required,
      ]);
      this.depenseForm.controls.effectPar.markAsUntouched();

      this.depenseForm.controls.candidatId.clearValidators();
      this.depenseForm.controls.ressourceId.clearValidators();
      this.depenseForm.controls.vehiculeId.clearValidators();

      this.depenseForm.controls.effectPar.setValue(null);
      this.depenseForm.controls.ressourceId.setValue(null);
      this.depenseForm.controls.vehiculeId.setValue(null);
      this.depenseForm.controls.candidatId.setValue(null);
      if (this.agents.length === 1) {
        this.depenseForm.controls.effectPar.setValue(
          this.agents[0].id,
        );
      }
    } else if (value === 3) {
      this.showVehicle = false;
      this.showAgents = false;
      this.showResources = true;
      this.showCandidat = false;
      this.depenseForm.controls.ressourceId.setValidators([
        Validators.required,
      ]);
      this.depenseForm.controls.ressourceId.markAsUntouched();

      this.depenseForm.controls.vehiculeId.clearValidators();
      this.depenseForm.controls.candidatId.clearValidators();
      this.depenseForm.controls.effectPar.clearValidators();

      this.depenseForm.controls.effectPar.setValue(null);
      this.depenseForm.controls.ressourceId.setValue(null);
      this.depenseForm.controls.vehiculeId.setValue(null);
      this.depenseForm.controls.candidatId.setValue(null);
      if (this.ressources.length === 1) {
        this.depenseForm.controls.ressourceId.setValue(
          this.ressources[0].id,
        );
      }
    } else {
      this.showVehicle = false;
      this.showAgents = false;
      this.showResources = false;
      this.showCandidat = false;
      this.depenseForm.controls.ressourceId.clearValidators();
      this.depenseForm.controls.vehiculeId.clearValidators();
      this.depenseForm.controls.candidatId.clearValidators();
      this.depenseForm.controls.effectPar.clearValidators();

      this.depenseForm.controls.ressourceId.markAsUntouched();
      this.depenseForm.controls.vehiculeId.markAsUntouched();
      this.depenseForm.controls.candidatId.markAsUntouched();
      this.depenseForm.controls.effectPar.markAsUntouched();

      this.depenseForm.controls.effectPar.setValue(null);
      this.depenseForm.controls.ressourceId.setValue(null);
      this.depenseForm.controls.vehiculeId.setValue(null);
      this.depenseForm.controls.candidatId.setValue(null);
    }
  }

  onFamilleChange():void {
    this.depenseForm.get("famille").valueChanges.subscribe(() => {
      this.depenseForm.get("effectPar").disable();
    });
  }


  addType() :void {
    const dialogRef = this.dialog.open(TypesPopupComponent, {
      panelClass: ["common-dialog-style"],
      width: "300px",
      data: {},
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
    });
  }

  onDelete(id):void {
    this.confirmDialogRef = this._matDialog.open(
      FuseConfirmDialogComponent,
      {
        disableClose: false,
      },
    );

    this.confirmDialogRef.componentInstance.confirmMessage =
            "Voulez-vous supprimer?";

    this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe(
      (result) => {
        if (result) {
          this.service.deleteDepense(id).subscribe({
            next: () => {
              this._reloadDialogService.pushChanges("depense");
              this.snackBar.openFromComponent(
                SuccesSnakBarComponent,
                {
                  duration: 3000,
                },
              );

              this._matDialogRef.close();
              //! add a snack bar
            },
            error: (err) => {
              this.errService.popError(
                err,
                "Un probléme s'est produit lors de suppression. \n Essayer de nouveau.",
              );
            },
        });
        }
        this.confirmDialogRef = null;
      }
    );
  }

}
