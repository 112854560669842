import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CsvDownloadPopupComponent } from "./csv-download-popup.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";



@NgModule({
  declarations: [CsvDownloadPopupComponent],
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    FormsModule,
    FlexLayoutModule,
    CommonModule,
  ],
  exports: [CsvDownloadPopupComponent],
})
export class CsvDownloadPopupModule { }
