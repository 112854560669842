import { IPermissionConfig } from "./permission.types";

export const PermissionConfig: IPermissionConfig = {
  // Components name
  "candidate": {
    // action-resource combo
    "add-candidat": {
      resourceName: "candidat",
      actionName: "add",
    },
    "archiver-candidat": {
      resourceName: "candidat",
      actionName: "archive",
    },
    "suspendre-candidat": {
      resourceName: "candidat",
      actionName: "suspend",
    },
    "delete-candidat": {
      resourceName: "candidat",
      actionName: "delete",
    },
    "candidate-payment-summary": {
      resourceName: "candidate-payment-summary",
      actionName: "view",
    },
    "candidate-total": {
      resourceName: "student-total",
      actionName: "view",
    },
    "candidate-payment-total": {
      resourceName: "candidate-payment-total",
      actionName: "view",
    },
    "candidate-payment-remaining": {
      resourceName: "candidate-payment-remaining",
      actionName: "view",
    },
    "contract": {
      resourceName: "contract",
      actionName: "view",
    },
    "view-contract-code": {
      resourceName: "contract-code",
      actionName: "view",
    },
    "edit-contract-code": {
      resourceName: "contract-code",
      actionName: "edit",
    },
    "view-contract-conduit": {
      resourceName: "contract-conduit",
      actionName: "view",
    },
    "edit-contract-conduit": {
      resourceName: "contract-conduit",
      actionName: "edit",
    },
    "view-frai-remise": {
      resourceName: "remise-frais",
      actionName: "view",
    },
    "edit-frai-remise": {
      resourceName: "remise-frais",
      actionName: "edit",
    },
    "delete-frai-remise": {
      resourceName: "remise-frais",
      actionName: "delete",
    },
    "history": {
      resourceName: "history",
      actionName: "view",
    },
    "paiement-history": {
      resourceName: "paiement",
      actionName: "view",
    },
    "code-history": {
      resourceName: "code-history",
      actionName: "view",
    },
    "conduit-history": {
      resourceName: "conduit-history",
      actionName: "view",
    },
    "exam-history": {
      resourceName: "exam-history",
      actionName: "view",
    },
    "view-notes": {
      resourceName: "notes",
      actionName: "view",
    },
    "add-notes": {
      resourceName: "notes",
      actionName: "add",
    },
    "delete-notes": {
      resourceName: "notes",
      actionName: "delete",
    },
    "view-general-info": {
      resourceName: "general-info",
      actionName: "view",
    },
    "edit-general-info": {
      resourceName: "general-info",
      actionName: "edit",
    },
    "view-candidate-account": {
      resourceName: "candidate-account",
      actionName: "view",
    },
    "add-candidate-account": {
      resourceName: "candidate-account",
      actionName: "add",
    },
    "edit-candidate-account": {
      resourceName: "candidate-account",
      actionName: "edit",
    },
    "delete-candidate-account": {
      resourceName: "candidate-account",
      actionName: "delete",
    },
  },
  "comptability": {
    "view-recette": {
      resourceName: "recette",
      actionName: "view",
    },
    "add-recette" : {
      resourceName: "recette",
      actionName: "add",
    },
    "edit-recette" : {
      resourceName: "recette",
      actionName: "edit",
    },
    "delete-recette" : {
      resourceName: "recette",
      actionName: "delete",
    },
    "total-recette": {
      resourceName: "recette",
      actionName: "view-total",
    },
    "add-depense" : {
      resourceName: "depense",
      actionName: "add",
    },
    "edit-depense" : {
      resourceName: "depense",
      actionName: "edit",
    },
    "delete-depense" : {
      resourceName: "depense",
      actionName: "delete",
    },
    "total-depense": {
      resourceName: "depense",
      actionName: "view-total",
    },
    "view-revenu": {
      resourceName: "revenu",
      actionName: "view",
    },
  },
  "online-service": {
    "view-web-page": {
      resourceName: "web-page",
      actionName: "view",
    },
    "view-solde": {
      resourceName: "solde",
      actionName: "view",
    },
    "view-offers": {
      resourceName: "offers",
      actionName: "view",
    },
  },
  "calendar": {
    "access-event": {
      resourceName: "calendar",
      actionName: "access",
    },
    "add-occuped-event": {
      resourceName: "occupied-event",
      actionName: "add",
    },
    "edit-occuped-event": {
      resourceName: "occupied-event",
      actionName: "edit",
    },
    "delete-occuped-event": {
      resourceName: "occupied-event",
      actionName: "delete",
    },
    "view-code-session": {
      resourceName: "code-session",
      actionName: "view",
    },
    "add-code-session": {
      resourceName: "code-session",
      actionName: "add",
    },
    "edit-code-session": {
      resourceName: "code-session",
      actionName: "edit",
    },
    "delete-code-session": {
      resourceName: "code-session",
      actionName: "delete",
    },
    "confirm-code-session": {
      resourceName: "code-session",
      actionName: "confirm",
    },
    "deconfirm-code-session": {
      resourceName: "code-session",
      actionName: "deconfirm",
    },
    "delete-confirm-code-session": {
      resourceName: "code-session",
      actionName: "delete-confirm",
    },
    "view-conduit-session": {
      resourceName: "conduit-session",
      actionName: "view",
    },
    "add-conduit-session": {
      resourceName: "conduit-session",
      actionName: "add",
    },
    "edit-conduit-session": {
      resourceName: "conduit-session",
      actionName: "edit",
    },
    "delete-conduit-session": {
      resourceName: "conduit-session",
      actionName: "delete",
    },
    "confirm-conduit-session": {
      resourceName: "conduit-session",
      actionName: "confirm",
    },
    "deconfirm-conduit-session": {
      resourceName: "conduit-session",
      actionName: "deconfirm",
    },
    "delete-confirm-conduit-session": {
      resourceName: "conduit-session",
      actionName: "delete-confirm",
    },
    "view-conduit-exam": {
      resourceName: "conduit-exam",
      actionName: "view",
    },
    "add-conduit-exam": {
      resourceName: "conduit-exam",
      actionName: "add",
    },
    "edit-conduit-exam": {
      resourceName: "conduit-exam",
      actionName: "edit",
    },
    "delete-conduit-exam": {
      resourceName: "conduit-exam",
      actionName: "delete",
    },
    "add-result-conduit-exam": {
      resourceName: "conduit-exam",
      actionName: "add-result",
    },
    "delete-result-conduit-exam": {
      resourceName: "conduit-exam",
      actionName: "delete-result",
    },
    "view-code-exam": {
      resourceName: "code-exam",
      actionName: "view",
    },
    "add-code-exam": {
      resourceName: "code-exam",
      actionName: "add",
    },
    "edit-code-exam": {
      resourceName: "code-exam",
      actionName: "edit",
    },
    "delete-code-exam": {
      resourceName: "code-exam",
      actionName: "delete",
    },
    "add-result-code-exam": {
      resourceName: "code-exam",
      actionName: "add-result",
    },
    "delete-result-code-exam": {
      resourceName: "code-exam",
      actionName: "delete-result",
    },
  },
  "administration": {
    "view-school": {
      resourceName: "school",
      actionName: "view",
    },
    "view-agency": {
      resourceName: "agency",
      actionName: "view",
    },
    "edit-agency": {
      resourceName: "agency",
      actionName: "edit",
    },
    "view-tarif": {
      resourceName: "tarif",
      actionName: "view",
    },
    "edit-tarif": {
      resourceName: "tarif",
      actionName: "edit",
    },
    "view-horaire": {
      resourceName: "horaire",
      actionName: "view",
    },
    "edit-horaire": {
      resourceName: "horaire",
      actionName: "edit",
    },
    "view-account": {
      resourceName: "account",
      actionName: "view",
    },
    "view-stats-account": {
      resourceName: "account",
      actionName: "view-stats",
    },
    "edit-details-account": {
      resourceName: "account",
      actionName: "edit-details",
    },
    "edit-password-account": {
      resourceName: "account",
      actionName: "edit-password",
    },
    "view-vehicule": {
      resourceName: "vehicule",
      actionName: "view",
    },
    "add-vehicule": {
      resourceName: "vehicule",
      actionName: "add",
    },
    "delete-vehicule": {
      resourceName: "vehicule",
      actionName: "delete",
    },
    "view-stats-vehicule": {
      resourceName: "vehicule",
      actionName: "view-stats",
    },
    "edit-details-vehicule": {
      resourceName: "vehicule",
      actionName: "edit-details",
    },
    "view-details-vehicule": {
      resourceName: "vehicule",
      actionName: "view-details",
    },
    "edit-vehicule-mileage": {
      resourceName: "vehicule",
      actionName: "edit-mileage",
    },
    "view-vehicule-mileage": {
      resourceName: "vehicule",
      actionName: "view-mileage",
    },
    "view-team": {
      resourceName: "team",
      actionName: "view",
    },
    "view-team-stats": {
      resourceName: "team",
      actionName: "view-stats",
    },
    "add-team": {
      resourceName: "team",
      actionName: "add",
    },
    "edit-team": {
      resourceName: "team",
      actionName: "edit",
    },
    "archive-team": {
      resourceName: "team",
      actionName: "archive",
    },
    "suspend-team": {
      resourceName: "team",
      actionName: "suspend",
    },
    "view-resource": {
      resourceName: "resource",
      actionName: "view",
    },
    "add-resource": {
      resourceName: "resource",
      actionName: "add",
    },
    "edit-resource": {
      resourceName: "resource",
      actionName: "edit",
    },
    "delete-resource": {
      resourceName: "resource",
      actionName: "delete",
    },
    "view-facturation": {
      resourceName: "facturation",
      actionName: "view",
    },
    "view-details-facturation": {
      resourceName: "facturation-details",
      actionName: "view",
    },
    "view-history-facturation": {
      resourceName: "facturation-history",
      actionName: "view",
    },
    "view-solde-facturation": {
      resourceName: "facturation-solde",
      actionName: "view",
    },
    "view-solde-cashout-facturation": {
      resourceName: "facturation-solde-cashout",
      actionName: "view",
    },

  },
  "exams-center": {
    "view-exam-center": {
      resourceName: "exams-center",
      actionName: "access",
    },
  },
  "reservation": {
    "view-reservation": {
      resourceName: "reservation",
      actionName: "access",
    },
  },
};