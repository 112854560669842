import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-print",
  templateUrl: "./print.component.html",
  styleUrls: ["./print.component.scss"],
  standalone: false,
})
export class PrintComponent implements OnInit {
  @Input("data") data;


  ngOnInit(): void {
  }

  getDateNow(): any {
    const date = new Date();
    return (
      date.getDate() +
            " " +
            this.month[date.getMonth()] +
            " " +
            date.getFullYear() +
            " " +
            date.toTimeString().split(" ")[0]
    );
  }

  getDate(date): any {
    const d = new Date(date);
    return d.getDate() + " " + this.month[d.getMonth()] + " " + d.getFullYear();
  }

  getHours(date): any {
    const d = new Date(date);
    return this.pad(d.getHours(), 2) + ":" + this.pad(d.getMinutes(), 2);
  }

  getResult(success): any {
    if (success) {
      return "succès";
    } else {
      return "ajourné(e)";
    }
  }

  getDetail(item): any {
    if (item.type === "codeEx") {
      return item.resultat;
    } else {
      if (item.success) {
        return "N/A";
      } else {
        if (item.echecParc) return "parc";
        else return "circuit";
      }
    }
  }

  getTotalDuration(table: []): any {
    let t = 0;
    table.forEach((item: any) => {
      t += item.duration;
    });
    return t;
  }

  getExamType(type): any {
    if (type === "codeEx") {
      return "code";
    } else {
      return "conduite";
    }
  }

  pad(num, size): any {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getPaimenetTotal(): any {
    let t = 0;
    (this.data.paiement as []).forEach((element: any) => {
      t += element.montant;
    });
    return t;
  }

  month = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "aout",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ];
}
