import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { AuthentificationService } from 'app/main/authentification/authentification.service';
import { RealTimeService } from 'app/real-time.service';
import { SessionTrackerService } from 'app/session-tracker.service';
import { error } from 'console';

// export interface UpdateFeature {
//   title: string;
//   description: string;
//   icon?: string;
// }

// export interface UpdateDialogData {
//   title: string;
//   version: string;
//   mainFeature: {
//     title: string;
//     image?: string;
//   };
//   intro: string;
//   features: UpdateFeature[];
// }

@Component({
  selector: 'app-update-dialog',
  templateUrl: './app-update-dialog.component.html',
  styleUrls: ['./app-update-dialog.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule
  ]
})
export class AppUpdateDialogComponent implements OnInit {
  
  sessionTime: number = 0; // to keep track of the session time in seconds
  timer: any;

  language = 'fr';
  textDirection  = 'ltr';
  selectedLanguage = "Français";
  closeText = {
    fr: 'Ok',
    ar: 'حسنا'
  };
  remindText = {
    fr: 'Rappeler plus tard',
    ar: 'تذكير لاحقا'
  };
  languageOptions = {
    fr: 'Français',
    ar: 'العربية'
  };
  divHeight = 0;

  @ViewChild('mainDiv') mainDiv!: ElementRef;
  @ViewChild('div1') div1!: ElementRef;
  @ViewChild('div2') div2!: ElementRef;
  @ViewChild('div3') div3!: ElementRef;
  @ViewChild('div4') div4!: ElementRef;
  @ViewChild('features_list_div') features_list_div!: ElementRef;

  @HostListener('window:resize')
  onResize() {
    this.updateHeight();
  }

  private updateHeight() {
    if (window.innerWidth <= 599) {
      const div1Height = this.div1?.nativeElement.offsetHeight || 0;
      const div2Height = this.div2?.nativeElement.offsetHeight || 0;
      const div3Height = this.div3?.nativeElement.offsetHeight || 0;
      const div4Height = this.div4?.nativeElement.offsetHeight || 0;
      this.divHeight = window.innerHeight - div1Height - div2Height - div3Height - 32 - div4Height - 32;
    } else {
      this.divHeight = null;
    }
  }

  constructor(
    private _authenticationService: AuthentificationService,
    private _sessionTrackerService: SessionTrackerService,
    private _realTimeService: RealTimeService,
    public dialogRef: MatDialogRef<AppUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) { }

  changeLanguage(lang: string): void {
    this.language = lang;
    this.textDirection = lang === 'ar' ? 'rtl' : 'ltr';
    this.selectedLanguage = this.languageOptions[lang];
    
    // manually update height, 
    // to fix the issue of height not updating when changing language
    setTimeout(() => {
      this.updateHeight();
    });
  }

  ngOnInit(): void {

    console.log(this.data);
    
    // Start the timer when the component is initialized
    this.startTimer();
  }
  
  ngAfterViewInit() {
    this.updateHeight();
  }

  onClose(): void {
    // Close the dialog and set notification as opened / delivered
    this._realTimeService.notificationOpenedAndDelivered(this.data.notificationId).subscribe(() => {
    }, (error) => {
      console.error("error setting app update notification as opened / delivered");
    });
    // Remove the last time we showed the reminder about update in local storage
    // because the user no longer wants to see that app update reminder
    localStorage.removeItem('lastRemindMeAppUpdate');
    this.dialogRef.close();
  }

  remindLater(): void {
    // Close the dialog and set notification as delivered
    this._realTimeService.notificationDelivered(this.data.notificationId).subscribe(() => {
    }, (error) => {
      console.error("error setting app update notification as delivered");
    });

    // Save the last time we showed the reminder about update in local storage
    // used to show the reminder again after 24 hours
    localStorage.setItem('lastRemindMeAppUpdate', Date.now().toString());

    this.dialogRef.close();
  }

  startTimer() {
    // Set an interval that updates the session time every second
    this.timer = setInterval(() => {
      this.sessionTime += 1;
    }, 1000); // updates every 1 second
  }

  ngOnDestroy() {


    // send the session time to the server
    this._sessionTrackerService.postUserTracking({ 
      "feature_id": this.data.id,
      "feature_table": "app_update",
      "action": "close",
      "reading_time": this.sessionTime
     }).subscribe(() => {}, (error) => {
      // subscribe to the error response,
      // to avoid showing a popup saying "something went wrong"
      // (manually handling the error)
      console.error("error", error);
    });

    // Clean up the timer when the component is destroyed
    if (this.timer) {
      clearInterval(this.timer);
    }

    // call signInUsingToken, to trigger the service worker
    // if there is a change in the app
    this._authenticationService.signInUsingToken();

  }
}