<div class="container">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Facture</span>
            <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div fxLayout="column" class="content w-100-p">
        <mat-checkbox  class="w-100-p"[(ngModel)]="result.service">Facture</mat-checkbox>
        <mat-checkbox class="w-100-p" [(ngModel)]="result.code">Historique séances code</mat-checkbox>
        <mat-checkbox class="w-100-p"[(ngModel)]="result.conduit">Historique séances conduite</mat-checkbox>
        <mat-checkbox class="w-100-p"[(ngModel)]="result.paiement">Historique paiement</mat-checkbox>
        <mat-checkbox class="w-100-p"[(ngModel)]="result.exam">Historique examen</mat-checkbox>
        <mat-checkbox class="w-100-p"[(ngModel)]="result.fraisAdditionnel">Frais Additionnel</mat-checkbox>
        <mat-checkbox class="w-100-p"[(ngModel)]="result.remise">Remise</mat-checkbox>
    </div>
<mat-divider></mat-divider>
<div  fxLayout="row" dir='rtl' class="mt-16 mb-16 p-0 " fxLayoutAlign="center center">
    <button mat-flat-button class="primary" [ngClass]="{'disabled': (!result.service && !result.code && !result.conduit && !result.paiement && !result.exam) }" (click)="onYesClickPrint()" *ngIf="this.data.type=='impression'" 
        [disabled]="(!result.service && !result.code && !result.conduit && !result.paiement && !result.exam)"  cdkFocusInitial>
        Imprimer
    </button>
    <button mat-flat-button style="background-color: #118ee9; color: white;" (click)="onYesClickExport()" *ngIf="this.data.type =='exportation'" cdkFocusInitial>Telecharger</button>
    <button mat-stroked-button class="buuton" (click)="onNoClick()">Annuler</button>
</div>
</div>
