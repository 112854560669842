import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ErrordialogComponent } from "../components/errordialog/errordialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { take } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ErreurService {
  status = "ONLINE";

  isConnected = true;

  public dialogRef: MatDialogRef<ErrordialogComponent> = null;

  link :string;

  constructor(
    private _matDialog: MatDialog,
    private router: Router,

  ) {
    this.link = environment.production ? location.origin : "localhost:3000";
  }

  closeErr(): void {
    this._matDialog.closeAll();
    this.dialogRef = null;
  }

  notConnected(error): void {
    console.error(error);
    if (error instanceof HttpErrorResponse) {
      // Server error happened
      if (!navigator.onLine) {
        // No Internet connection
        throw new Error;

      }
    }
  }

  redirectError(err, where?): void {
    if ((err.status === 0 && err.error instanceof ProgressEvent)) {
      this.router.navigate(["/nointernet"])
        .then((value)=>{if (!value) this.popError(new Error(), "Vérifier votre connexion internet puis réessayez !");});

    } else if (err.status === 504 && err.url.indexOf("real-time") === -1 && err.url.indexOf(this.link) !== -1) {
      this._matDialog.closeAll();
      this.router.navigate(["/nointernet"])
        .then((value)=>{if (!value) this.popError(new Error(), "Vérifier votre connexion internet puis réessayez !");});
    }
    if (where) {
      switch (where) {
        case 404:
          this.router.navigate(["/notfound"]);
          break;
        case 500:
          this.router.navigate(["/auth/servererror"]);
          break;

        default:
          this.popError(err);
      }
    } else {
      switch (err.status) {
        case 404:
          this.router.navigate(["/notfound"]);
          break;
        case 500:
          this.router.navigate(["/auth/servererror"]);
          break;

        default:
          this.popError(err);
      }
    }
  }

  popError(err, msg?, title = null): any {
    
    if (err.status === 403) return; // this forbidden for unauthorized from permission

    if (msg && msg.includes("Accès autorisé. Votre solde est de")) title = "Notice";
    if (msg === "" || !msg) {
      switch (err.status) {
        case 404:
          msg = "Les données ne sont pas trouvés";
          break;
        case 500:
          msg = "Erreur serveur";
          break;
        case 401:
          msg = "Action non autorizée";
          break;
        default:
          msg =
                        "Un erreur s'est produit. Nous sommes en train de le réparer.";
      }
    }
    if (err.status === 0 && err.error instanceof ProgressEvent) {
      this._matDialog.closeAll();
      return this.router.navigate(["/nointernet"]);
    } else if (err.status === 504 && err.url.indexOf("real-time") === -1 && err.url.indexOf(this.link) !== -1) {
      this._matDialog.closeAll();

      this.router.navigate(["/nointernet"])
        .then((value)=>{if (!value) this.popError(new Error(), "Vérifier votre connexion internet puis réessayez !");});
    } else if (err instanceof HttpErrorResponse) {
      // Server error happened
      if (!this.isConnected) {

        // No Internet connection
        this.router.navigate(["/nointernet"]);
        this._matDialog.closeAll();

      } else {
        if (this.dialogRef === null && err.status !== 504) {
          this.dialogRef = this._matDialog.open(ErrordialogComponent, {
            panelClass: ["common-dialog-style"],
            data:{
              error:err.error,
              title:title,
            },
          });

          this.dialogRef.componentInstance.confirmMessage = msg;

          this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
            this.dialogRef = null;
          });
        }
      }
    } else if (this.dialogRef === null && err?.status !== 504) {
      this.dialogRef = this._matDialog.open(ErrordialogComponent, {
        panelClass: ["common-dialog-style"],
        data:{
          error:err.error,
          title:title,
          confirmMessage : msg || "Une erreur s'est produite",
        },
      });

      this.dialogRef.componentInstance.confirmMessage = msg;

      this.dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
        this.dialogRef = null;
      });
    }


  }
    
}
