import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SessionTrackerService {


  uri = environment.uriG + "";

  constructor(private http: HttpClient) { }


  postUserTracking(tracking) :any {
    return this.http.post<any>(`${this.uri}/api/user-track/`, tracking);
  }

}
