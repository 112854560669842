import { environment } from "environments/environment";
import { RealTimeService } from "./real-time.service";
import {
  trigger,
  state,
  transition,
  animate,
  style,
} from "@angular/animations";

import { SwPush, SwUpdate } from "@angular/service-worker";
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { fromEvent, merge, Subject, takeUntil, filter, take, tap, throttleTime  } from "rxjs";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { navigationCodeOnly } from "app/navigation/navigation_code_only";
import { SharedNavigationService } from "@fuse/services/shared-navigation.service";
import { AuthentificationService } from "./main/authentification/authentification.service";
import { GlobalService } from "./global.service";
import { Router, NavigationEnd } from "@angular/router";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { CommunicationPopupService } from "./communication-popup.service";
import { Title } from "@angular/platform-browser";
import { SetTitleService } from "./set-title.service";
import { ViewportService } from "./core/services/viewport.service";



/* export const navigationVide: FuseNavigation[] = [
    {
        id: "applications",
        title: "",
        type: "group",
        icon: "apps",
        children: [],
    },
]; */
@Component({
  selector: "app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("FadeInOut", [
      state("void", style({ opacity: 0, height: "0px", width: "0px" })),
      state("*", style({ opacity: 1, height: "*", width: "*" })),
      transition(":enter", animate("600ms ease-out")),
      transition(":leave", animate("600ms ease-in")),
    ]),
  ],
  standalone: false,
})
export class AppComponent implements OnInit, OnDestroy {

  innerWidth: number;

  fuseConfig: any;

  navigation = []; //navigationVide;

  navCodeOnly = Object.freeze(navigationCodeOnly);

  role = "moniteur";

  previous_role: string = null;

  previous_sub_type: string = null;

  status = "ONLINE";

  isLoggedIn: boolean = false;

  inAuthPages: boolean = false;

  currentTheme: string = 'light';

  // Private
  private _unsubscribeAll: Subject<any>;


  readonly VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;

  /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {Platform} _platform
     */
  constructor(
    // private _notifyServie: NotifySocketService,
    private _viewPortService : ViewportService,
    
    private router: Router,
    @Inject(DOCUMENT) private document: any,
    private _authentificationService: AuthentificationService,
    private _sharedService: SharedNavigationService,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private globalService: GlobalService,
    private _platform: Platform,
    private swUpdate: SwUpdate,
    private cdr: ChangeDetectorRef,

    //! Service worker push
    private swPush: SwPush,
    private _realTimeService: RealTimeService,
    private titleService: Title,

    // private connectionService: ConnectionService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    // private helpService: HelpService,

    //integration of communication-popup service;
    private _communicationPopupService: CommunicationPopupService,
    private setTitleService: SetTitleService,

  ) {
    // Initialize with the current value
    this.innerWidth = this._viewPortService.currentWidth;
    // Set the private defaults


    let otherOpen = false;
    let timeout = null;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    function message_broadcast(message) :any {
      localStorage.setItem("message", JSON.stringify({ message: message, id: new Date().toString() + Math.random() }));
    }
   
    // eslint-disable-next-line @typescript-eslint/naming-convention
    function message_receive(ev) :any {
      if (ev.key === "message") {
        const message = JSON.parse(ev.newValue).message;
        if (message === "new-open") {
          otherOpen = true;
          //! TO STOP THE SESSION DESTROY
          if (timeout) {
            clearTimeout(timeout);
          }
          //! HOLD PING
          _realTimeService.holdPing();
        }
      }
    }

    if (environment.production) {
      window.addEventListener("storage", message_receive);

      message_broadcast("new-open");

      const blur$ = fromEvent(window, "blur");
      const over$ = fromEvent(window, "mouseover");
      const touch$ = fromEvent(window, "touchstart");
      const focus$ = fromEvent(window, "focus");


      merge(focus$, over$.pipe(throttleTime(1000)), touch$.pipe(throttleTime(1000)))
        .pipe(
          tap(() => {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            //so we dont keep sending the message when we are in this tab
            if (otherOpen) {
              message_broadcast("new-open");
              otherOpen = false;
            }

            _realTimeService.resumePinging();
          }),
        ).subscribe();

      //! Stop pinging if the use is no longer focused on the app
      blur$.pipe(tap(() => {
        timeout = setTimeout(() => { _realTimeService.stopPing(); }, 20000);
      }))
        .subscribe();
    }

  }




  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
     * On init
     */

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();
    this.currentTheme = this.globalService.getTheme();
    console.log('this.currentTheme', this.currentTheme );
    this.globalService.currentTheme.subscribe(()=> {
      this.currentTheme = this.globalService.getTheme();
      console.log('this.currentTheme after changed', this.currentTheme );

    })
    this.viewPortListener()

    this.setTitleService.init();

    this.isLoggedIn = this._authentificationService.loggedIn();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Check if the route starts with '/auth/'
        this.inAuthPages = event.urlAfterRedirects.startsWith("/auth/") ||
         event.urlAfterRedirects.startsWith("/registration") || event.urlAfterRedirects.startsWith("/welcome");
      });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(filter(v => v.type === "VERSION_READY"))
      //   this.swUpdate.available
        .subscribe(() => {
          console.log("VERSION UPDATE DETECTED");
          window.location.reload();
        });
    }
    let uri = "";
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        take(1),
      )
      .subscribe((navEnd: NavigationEnd) => {
        uri = navEnd.urlAfterRedirects;
        this._fuseNavigationService.register("main", this.navigation);

        //register the quickpanel
        //this._fuseSidebarService.register("quickPanel",this.navigation)

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation("main");
        // Get default navigation


        if (!navigator.onLine) {

          this.router.navigate(["/nointernet"]);
        } else if (uri !== "/nointernet") {

          if (
            this._authentificationService.loggedIn() &&
                        this.globalService.getUser()
          ) {
            this.globalService.loadAgency();
            const user = this.globalService.getUser();
            const nav = this.globalService.getNavigation();
            this.setNavigation(user, nav);

          } else if (
            uri.slice(1, 5) === "auth" ///confirmation"
          ) {
            this.router.navigate([uri]);
          } else {
            this._authentificationService.logOut();
          }

          // Add is-mobile class to the body if the platform is mobile
          if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add("is-mobile");
          }

          this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
              this.fuseConfig = config;

              if (this.fuseConfig.layout.width === "boxed") {
                this.document.body.classList.add("boxed");
              } else {
                this.document.body.classList.remove("boxed");
              }
            });

          this.subToUserChange();

        }
      });
    this._communicationPopupService.showLocationPopup();
  }


  subToUserChange() :any {
    this.globalService.currentUser.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next:(user)=>{
        this.isLoggedIn = user ? true : false;
        if ((this.previous_role !== user?.role) || (this.previous_sub_type !== user?.sub_type)) {
          this.globalService.currentNavigation.pipe(takeUntil(this._unsubscribeAll)).subscribe({
            next:(nav)=>{
              this.setNavigation(user, nav);
            } });

        }
        this.previous_role = user?.role;
        this.previous_sub_type = user?.sub_type;
      },
    });
  }

  setNavigation(user, navigation?) :any {
    if (user) {
      this.navigation = navigation;

      this.role = user?.role;

      this._fuseNavigationService.unregister("main");

      // Register the navigation to the service
      this._fuseNavigationService.register(
        "main",
        this.navigation,
      );
      // Set the main navigation as our current navigation
      this._fuseNavigationService.setCurrentNavigation(
        "main",
      );
    }

  }

  ngAfterViewInit() :void {
  }

  viewPortListener(){
    this._viewPortService.innerWidth$?.pipe(takeUntil(this._unsubscribeAll)).subscribe(innerWidth => {
      this.innerWidth = innerWidth;
    });
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    this.isLoggedIn = this._authentificationService.loggedIn();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Toggle sidebar open
     *
     * @param key
     */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
