<div id="update-notification-dialog" class="update-notification-dialog">
    <div class="dialog-content-wrapper container-notification" [dir]="textDirection">
        <!-- Dialog header -->
        <div #div1 fxLayout="column" fxLayoutAlign="center space-between" class="dialog-header">
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="8px">
                <span class="notification-title">
                    🎉 {{ data.headline[language] }}
                </span>
                <button class="abutton" mat-icon-button (click)="onClose()" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <span class="version-tag">
                {{ data.version[language] }}
            </span>
        </div>

        <!-- Feature highlight banner -->
        <div #div2 class="feature-banner" style="background-image: url('assets/images/appUpdateBanner/{{ data.banner }}'); 
        background-size: cover; 
        background-position: center; 
        color: white; 
        border-radius: 5px;">
            <div class="banner-content p-16">
                <h2>{{ data.mainFeature.title[language] }}</h2>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                    <div>
                        <!-- <mat-icon>chat</mat-icon> Clicktip -->
                    </div>
                    <div class="banner-image">
                        <!-- You would replace this with an actual image tag pointing to your feature screenshot -->
                        <div class="feature-screenshot">

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update content -->
        <div class="update-content px-24" fxLayout="column wrap">
            <p #div4 class="update-intro">
                {{ data.intro[language] }}
            </p>

            <div #features_list_div class="update-features"  [style.height.px]="divHeight">
                <div class="feature-item" *ngFor="let feature of data.features">
                    <!-- <h3 class="feature-title" style="margin-right: 8px !important"></h3> -->
                    <p class="feature-description"><b>{{ feature.title[language] }} </b>{{ feature.description[language] }}</p>
                </div>
            </div>
        </div>

        <!-- Dialog actions -->
        <div #div3 class="dialog-actions p-16" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="language-selector">
                <mat-select [(ngModel)]="selectedLanguage">
                    <mat-option (click)="changeLanguage('fr')" value="Français">Français</mat-option>
                    <mat-option (click)="changeLanguage('ar')" value="العربية">العربية</mat-option>
                </mat-select>

            </div>
            
            <div fxLayout="row" fxLayoutGap="8px">
                <button mat-stroked-button (click)="remindLater()" *ngIf="!data.hideRemindLater">
                    {{ remindText[language] }}
                </button>
                <button class="mat-accent" mat-raised-button (click)="onClose()">
                    {{ closeText[language] }}
                </button>
            </div>
        </div>
    </div>
</div>