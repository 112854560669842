import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private API_BASE_URL = environment.uriG;

  public location: any = null;

  public _currentLocation = new ReplaySubject<any>(1);
  


  constructor(
    private http: HttpClient,

  ) {}

    
  // Cette méthode remplace checkIfAgenceHasLocation
  getAgencyLocation(): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    return this.http.get(
      `${this.API_BASE_URL}/api/agency-profile/location`,
      { "headers": headers },
    );
  }

  setAgenceLocation(data): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    const requestBody = {
      locationObj: {
        address_ar: data.address_ar,
        address_fr: data.address_fr,
        lat: data.lat,
        lng: data.lng,
      },
    };
            
    return this.http.post(`${this.API_BASE_URL}/api/agency-profile/location`, requestBody, { "headers": headers });
  }

  saveAgencyLocation(data): Observable<any> {
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    const requestBody = {
      locationObj: {
        address_ar: data.address_ar,
        address_fr: data.address_fr,
        lat: data.lat,
        lng: data.lng,
      },
    };
    return this.http.post(`${this.API_BASE_URL}/api/agency-profile/location`, requestBody, { "headers": headers });
  }

  setCurrentLocation(location): void {
    this.location = location;
    this._currentLocation.next(location);
  }

  get currentLocation(): any {
    return this._currentLocation.asObservable();
  }

}