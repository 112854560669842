import { RealTimeService } from "./../../real-time.service";

import { JwtHelperService } from "@auth0/angular-jwt";

import { Observable, of, map, switchMap, catchError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { GlobalService } from "app/global.service";
import { TopNotificationService } from "app/layout/components/top-notification/top-notification.service";
import { Location } from "@angular/common";



const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class AuthentificationService {
  private uri = environment.uriG + "/api/";

  private _authenticated: boolean = false;

  private _lastVerified = new Date();


  constructor(
    private http: HttpClient,
    private router: Router,
    private socketService: RealTimeService,
    private _globalService: GlobalService,
    private _topNotService: TopNotificationService,
    private location: Location,

  ) {}

  addDemande(demande): any {
    return this.http.post<any>(`${this.uri}demande`, demande);
  }

  logIn(userInfo): any {
    return this.http.post<any>(`${this.uri}accounts/login`, userInfo);
  }


  loggedIn(): any {
    return !!localStorage.getItem("token");
  }

  public logOut(): any {
    if (/^\/registration(\/[^/]+)?$/.test(this.location.path())) {
      return;
    }

    const currPathIsAuth = this.location.path().indexOf("auth");
    const currPathIsBlocked = this.location.path().indexOf("auth/blocked");
    const url = ((currPathIsAuth === -1) || (currPathIsBlocked !== -1))  ? "/auth/login" : this.location.path(); 
        
    this._authenticated = false;
    this._lastVerified = new Date();
    this.socketService.disconnectNotification();
    this._globalService.setCurrentUser(null);
    localStorage.removeItem("token");

    this.router.navigate([url.split("?")[0]]);//,fbclid ? {queryParams:{fbclid:fbclid}} : {});
  }

  //! confirmation de mail
  confirmAgent(token): any {
    return this.http.get<any>(
      `${this.uri}accounts/confirmationagent/${token}`,
    );
  }

  //! validation changement dans la base
  validateAgent(id, compte): any {
    return this.http.put<any>(
      `${this.uri}accounts/confirmationagent/${id}`,
      compte,
    );
  }

  validateAgency(token): any {
    return this.http.get<any>(
      `${this.uri}accounts/confirmationagency/${token}`,
    );
  }

  set token(token: string) {
    localStorage.setItem("token", token);
  }

  get token(): string {
    return localStorage.getItem("token") ?? null;
  }

  getToken(): any {
    return localStorage.getItem("token");
  }

  getRole(): any {
    return this.http.get<any>(`${this.uri}dashboard/role`).pipe(
      map((elem) => elem.role),
    );
  }

  getUser(): any {
    return this.http.get<any>(`${this.uri}dashboard/role`);
  }


  noCheck(): void {
    this._lastVerified = new Date();
    this._authenticated = true;
  }

  /**
     * Check the authentication status
     */
  check(): Observable<boolean> {
    console.log("check");
    let check = false;
    const diff = new Date().getTime() - this._lastVerified.getTime();
    if (diff > 30000) check = true;
    console.log("diff", diff);

    console.log("check", check);

    //Check if the user is logged in 
    //! -NOT IN CASE OF RELOADING-
    if ( this._authenticated && !check ) {
      return of(true);
    }
 
    // Check the access token availability
    if ( !this.token ) {
      return of(false);
    }
 
    // Check the access token expire date
    if ( helper.isTokenExpired(this.token) ) {
      return of(false);
    }
 
    // If the access token exists and it didn't expire, sign in using it
    return this.signInUsingToken();
  }

  /**
     * Sign in using the access token
     */
  signInUsingToken(): Observable<any> {
    // Check the access token availability
    if ( !this.token) {
      this.logOut();
      return of(false);
    }
    console.log("SIGN IN USING NOT");
    // Renew token
    return  this.http.post(this.uri + "accounts/refresh-access-token", {
      token: this.token,
    }).pipe(
      catchError((error) =>{
        if ((error.status === 0 && error.error instanceof ProgressEvent)
                        || (error.status === 504)) {
          return of("no-internet");
        }
        this.logOut();
        return of(false);
      },
      ),
      switchMap((response: any) => {
        console.log("token response", response);
        if (response === "no-internet") {
          return of("no-internet");
        }

        if (!response || !response.user || !response.token) {
          return of(false);
        }

                

        this._lastVerified = new Date();

        // Store the access token in the local storage
        this.token = response.token;
                
                
                
        // Store the user on the user service
        console.log("response.user:", response.user);
        this._globalService.setCurrentUser(response.user);
        this._globalService.setCurrentNavigation(response.navigation);
        this._globalService.setCurrentPermission(response.permission);
                
                
        //! MEANS FIRST TIME WE VERIFIED => WE SEND
        if (!this._authenticated) {
          this.setTopNotification();
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return true
        return of(true);
      }),
    );
  }

  setTopNotification(): void {
    console.log("SET TOP NOT", this._globalService.getUser());
    if (!this._globalService.getUser()?.status) return;
    console.log("stil in", this._globalService.getUser());
        
    setTimeout(() => {
      this._topNotService.generateConfig(this._globalService.getUser());
    }, 5000);
  }

  setTopNotificationOld(): void {
        

    if (!this._globalService.getUser()?.status) return;

    this._topNotService.generateConfig(this._globalService.getUser());
    setTimeout(() => {
      switch (this._globalService.getUser().status) {
        case "limited":
        case "warning":
        case "grace":
          this._topNotService.config = {
            show: true,
            message: "Votre accès est limité a cause de non paiement !",
            action: "Facturation",
          };
    
          break;
          break;
        case "blocked":
        case "blocked_inactive":
        case "suspended":
        case "incomplete_expired":
          this._topNotService.config = {
            show: false,
            message: "Blocked",
            action: "Facturation",
          };
    
          break;
        case "trialing":
        case "settled":
        case "incomplete":
          this._topNotService.config = {
            show: false,
            message: null,
            action: null,
          };
    
          break;
        default:
          break;
      }   
    }, 5000);
        
  }
}
