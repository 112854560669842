import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { take } from "rxjs";

@Component({
  selector: "app-allow-notification-dialog",
  templateUrl: "./allow-notification-dialog.component.html",
  styleUrls: ["./allow-notification-dialog.component.scss"],
  standalone: false,
})
export class AllowNotificationDialogComponent implements OnInit {
  

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AllowNotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { 
  }

  deviceType = "Phone";

  browser = "Chrome";

  confirmDialogRef = null;

  ngOnInit(): void {
    this.detectDeviceAndBrowser();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  neverNotifyAgain(): void {

    this.confirmDialogRef = this.dialog.open(
      FuseConfirmDialogComponent,
      {
        panelClass: ["common-dialog-style"],
        disableClose: false,
      },
    );
    this.confirmDialogRef.componentInstance.confirmMessage =
      "Voulez-vous vraiment désactiver les notifications définitivement ?";
    this.confirmDialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
      if (result) {
        localStorage.setItem("notificationPreference", "dismissed");
      }
    });
    this.dialogRef.close();
  }


  allowNotifications(): void {
    // Request notification permission
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
      // You can trigger a notification here
      } else {
        console.log("Notification permission denied.");
      }
    });
    this.dialogRef.close();
  }

  detectDeviceAndBrowser(): void {
    const userAgent = navigator.userAgent.toLowerCase();

    // Device detection in a single variable
    if (/android|webos|iphone|ipod|blackberry|windows phone/i.test(userAgent)) {
      this.deviceType = "Phone";
    } else if (/ipad|tablet|kindle|playbook/i.test(userAgent)) {
      // this.deviceType = 'Tablet';
      // treat tablets as desktop
      this.deviceType = "Desktop";
    } else {
      this.deviceType = "Desktop";
    }

    // Browser detection in a single variable
    if (userAgent.includes("chrome") && !userAgent.includes("edg") && !userAgent.includes("opr")) {
      this.browser = "Chrome";
    } else if (userAgent.includes("safari") && !userAgent.includes("chrome") && !userAgent.includes("android")) {
      this.browser = "Safari";
    } else if (userAgent.includes("firefox")) {
      this.browser = "Firefox";
    } else if (userAgent.includes("opr") || userAgent.includes("opera")) {
      this.browser = "Opera";
    } else if (userAgent.includes("edg")) {
      this.browser = "Edge";
    } else {
      this.browser = "Unknown";
    }
  }

}
